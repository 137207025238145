import React, { useEffect, useState } from 'react'
import Moment from 'react-moment';
import AuthService from '../../../api/services/HomeService';
import LoaderHelper from '../../../Components/Loading/LoaderHelper';
import { alertErrorMessage, alertSuccessMessage, alertWarningMessage } from '../../../Components/CustomAlertMessage';
import { ApiConfig } from '../../../api/apiConfig/ApiConfig';
import { $ } from 'react-jquery-plugin';

const PendingRequest = () => {
    const [panCard, setpanCard] = useState();
    const [panCard2, setpanCard2] = useState();
    const [userId, setuserId] = useState("");
    const [panNumber, setpanNumber] = useState("");
    const [nameOnPan, setnameOnPan] = useState("");
    const [panDob, setpanDob] = useState("");
    const [panImage, setpanImage] = useState();
    const [panImage2, setpanImage2] = useState();
    const [panState, setpanState] = useState("");
    const [panStatus, setpanStatus] = useState("");
    const [panID, setpanID] = useState();
    const [frontImage, setFrontImage] = useState();
    const [backImage, setBackImage] = useState();
    const [aadharDetails, setAadharDetails] = useState({});

    console.log(aadharDetails, 'aadharDetails');
    useEffect(() => {
        listPan()
    }, []);

    const getUserDetails = async (userId) => {
        LoaderHelper.loaderStatus(true);
        await AuthService.UserDetails('Adahr', userId).then((result) => {
            LoaderHelper.loaderStatus(false);
            if (result?.success) {
                try {
                    $("#showimage").modal("show");
                    if (result?.data?.task_id) {
                        setFrontImage()
                        setAadharDetails(result?.data)
                    } else {
                        setFrontImage(result?.data?.adhar_front_image)
                        setBackImage(result?.data?.adhar_back_image)
                    }
                } catch {
                    alertErrorMessage(result.message);
                }
            } else {
                alertErrorMessage(result.message);
            }
        });
    };

    const listPan = async () => {
        LoaderHelper.loaderStatus(true);
        await AuthService.listPan(2).then((result) => {
            if (result?.success) {
                LoaderHelper.loaderStatus(false);
                try {
                    setpanCard(result?.data)
                    setpanCard2(result?.data)
                } catch {
                    alertErrorMessage(result.message);
                }
            } else {
                LoaderHelper.loaderStatus(false);
                alertErrorMessage(result.message);
            }
        });
    };

    const ViewPANdetails = async (id) => {
        setuserId(id);
        setpanNumber('')
        setnameOnPan('')
        setpanState('')
        setpanDob('')
        setpanStatus('')
        setpanImage()
        setpanImage2()
        LoaderHelper.loaderStatus(true)
        await AuthService.ViewPANdetails(id).then((result) => {
            if (result?.success) {
                LoaderHelper.loaderStatus(false)
                try {
                    setpanNumber(result?.data?.[0]?.PanNumber)
                    setnameOnPan(result?.data?.[0]?.NameOnPan)
                    setpanState(result?.data?.[0]?.State)
                    setpanDob(result?.data?.[0]?.dob)
                    setpanStatus(result?.data?.[0]?.panverified)
                    setpanImage(result?.data?.[0]?.Panimagepath)
                    setpanID(result?.data?.[0]?._id)
                } catch {
                    LoaderHelper.loaderStatus(false)
                    alertErrorMessage(result.message);
                }
            } else {
                alertErrorMessage(result.message);
            }
        });
    };
    const handlePanInput = (e) => {
        switch (e.target.name) {
            case "panNumber":
                setpanNumber(e.target.value);
                break;
            case "panState":
                setpanState(e.target.value);
                break;
            case "panImage":
                setpanImage(e.target.files[0]);
                setpanImage2(URL.createObjectURL(e.target.files[0]));
                break;
            case "panDob":
                setpanDob(e.target.value);
                break;
            case "panName":
                setnameOnPan(e.target.value);
                break;

            default:
                break;
        }
    };

    const editPANdtl = async (panNumber, nameOnPan, panDob, panImage, panState) => {
        var formData = new FormData();
        formData.append("_id", panID);
        formData.append("UserId", userId);
        formData.append("PanNumber", panNumber);
        formData.append("NameOnPan", nameOnPan);
        formData.append("dob", panDob);
        formData.append("Panimagepath", panImage);
        formData.append("State", panState);
        await AuthService.editPANdtl(formData).then((result) => {
            if (result?.status) {
                try {
                    alertSuccessMessage('Pan Details Updated Successfully!!');
                    ViewPANdetails(userId)
                } catch {
                    alertErrorMessage(result?.message);
                }
            } else {
                alertErrorMessage(result?.message);
            }
        });
    };

    const dltPandetails = async (id, userId) => {
        await AuthService.dltPandetails(id, userId).then((result) => {
            if (result?.success) {
                try {
                    alertSuccessMessage("Pan Deleted Successfully!!");
                    listPan()

                } catch {
                    alertErrorMessage(result.message);
                }
            } else {
                alertErrorMessage(result.message);
            }
        });
    };

    const verifyPan = async (id, status, userid) => {
        await AuthService.verifyPan(id, status, userid).then((result) => {
            if (result?.success) {
                try {
                    alertSuccessMessage("Pan Status Updated Successfully!!");
                    listPan()

                } catch {
                    alertErrorMessage(result.message);
                }
            } else {
                alertErrorMessage(result.message);
            }
        });
    };
    const filter = (e) => {
        let filterd;
        if (e.target.checked) {
            filterd = panCard2?.filter((data) => {
                return data?.pan_verified == 0
            })
            setpanCard(filterd)
        } else {
            filterd = panCard2?.filter((data) => {
                return data
            })
            setpanCard(filterd)
        }
    };

    return (
        <>
            <div id="layoutSidenav_content">
                <main>
                    <header class="page-header page-header-dark bg-gradient-primary-to-secondary pb-10">
                        <div class="container-xl px-4">
                            <div class="page-header-content pt-4">
                                <div class="row align-items-center justify-content-between">
                                    <div class="col-auto mt-4">
                                        <h1 class="page-header-title">
                                            <div class="page-header-icon"><i class="ri-group-line"></i></div>
                                            Pending Pancard Request
                                        </h1>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </header>
                    <div class="container-xl px-4 mt-n10">

                        <div class="card mb-4">

                            <div class="card-body">
                                <div class=" filter_bar mb-4 mb-md-5">
                                    <form method="get" accept-charset="utf-8" novalidate="novalidate" class="form-inline search_form" action="/admin/users/referraldata">
                                        <div class="row gx-2 align-items-center">

                                            <div class="form-group mb-0 col-sm-6 col-md-3">
                                                <div class=" form-check ms-2  " >
                                                    <input class="form-check-input" type="checkbox" id="unverified" onChange={filter} />
                                                    <label class="form-check-label" for="unverified">
                                                        Show Unverified Users
                                                    </label>
                                                </div>
                                            </div>

                                        </div>
                                    </form>
                                </div>


                                <table id="datatablesSimple">
                                    <thead>
                                        <tr>
                                            <th>#</th>
                                            <th>Name</th>
                                            <th>Pan Number</th>
                                            <th>Phone Number</th>
                                            <th>Registration Date</th>
                                            <th>View Aadhar</th>
                                            <th>Status</th>
                                            <th>Actions</th>
                                        </tr>
                                    </thead>
                                    <tfoot>
                                        <tr>
                                            <th>#</th>
                                            <th>Name</th>
                                            <th>Pan Number</th>
                                            <th>Phone Number</th>
                                            <th>Registration Date</th>
                                            <th>View Aadhar</th>
                                            <th>Status</th>
                                            <th>Actions</th>
                                        </tr>
                                    </tfoot>
                                    <tbody>
                                        {panCard ? panCard?.map((data, index) => {
                                            return (
                                                <tr key={index}>
                                                    <td>{index + 1}</td>
                                                    <td>{data?.NameOnPan}</td>
                                                    <td>{data?.pan_number}</td>
                                                    <td>{data?.data?.mobile_number}</td>
                                                    <td> <Moment date={data?.createdAt} format="YYYY/MM/DD" /> </td>

                                                    <td>
                                                        <div class="table_action_group">
                                                            <button type="button" class="btn badge bg-success text-white rounded-pill"
                                                                onClick={() => {
                                                                    getUserDetails(data?.UserId);
                                                                }}>View Aadhar</button>


                                                        </div>
                                                    </td>

                                                    <td>{data?.pan_verified == 2 ? <>
                                                        <button type="btn" class="btn badge bg-success text-white rounded-pill me-2"
                                                            onClick={() => { verifyPan(data?._id, '1', data?.UserId) }}
                                                        >Approve</button>

                                                        <button type="button" class="mt-2    btn badge bg-danger text-white rounded-pill"
                                                            onClick={() => { verifyPan(data?.data?._id, '0', data?.data?.UserId) }}
                                                        > Not Approve</button></> : data?.pan_verified == 1 ? <p className='text-success text-center'>Approved</p> : data?.pan_verified == 0 ? <p className='text-danger text-center'>Not Approved</p> : ''

                                                    }</td>


                                                    <td>
                                                        <div class="table_action_group">

                                                            <button type="button" class="btn badge bg-danger text-white rounded-pill"
                                                                onClick={() => {
                                                                    dltPandetails(data?._id, data?.UserId);
                                                                }}>Delete</button>

                                                        </div>
                                                    </td>
                                                </tr>
                                            )
                                        }) : ''}

                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </main>
            </div>

            {/* Edit Pan Details */}

            <div class="modal" id="editPancard" tabIndex="-1" aria-labelledby="editwallrtlmodalTitle" style={{ display: "none" }} aria-hidden="true">
                <div class="modal-dialog  alert_modal modal-dialog-centered" role="document">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="editwallrtlmodalTitle">
                                Pan Details
                            </h5>
                            <button class="btn-close" type="button" data-bs-dismiss="modal" aria-label="Close"></button>
                            <hr />
                        </div>
                        <div class="modal-body">
                            <form>
                                <div class="form-group  mb-3 position-relative ">
                                    <label class="small mb-1"> Pan Number </label>
                                    <input type="text" name="panNumber" maxLength="30" class="form-control" id="first-name" value={panNumber} onChange={(e) => handlePanInput(e)} />
                                </div>
                                <div class="form-group  mb-3 position-relative ">
                                    <label class="small mb-1"> Name </label>
                                    <input type="text" name="panName" maxLength="30" class="form-control" id="first-name" value={nameOnPan} onChange={(e) => handlePanInput(e)} />
                                </div>
                                <div class="form-group  mb-3 position-relative ">
                                    <label class="small mb-1"> DOB </label>
                                    <input type="date" name="panDob" maxLength="30" class="form-control" id="first-name" value={panDob} onChange={(e) => handlePanInput(e)} />
                                </div>
                                <div class="form-group  mb-3 position-relative ">
                                    <div className="vs_team" style={{ alignItems: 'center' }}>
                                        <a href={ApiConfig?.appUrl + '/uploads/' + panImage} target='_blank' >
                                            <img src={panImage2 ? panImage2 : `${ApiConfig?.appUrl + '/uploads/' + panImage}`} width="50" height="50" alt="" />
                                        </a>
                                        <label class="small mb-1">  </label>
                                        <input type="file" name="panImage"
                                            maxLength="30" class="form-control" id="first-name" onChange={(e) => handlePanInput(e)} />
                                    </div>
                                </div>
                                <div class="form-group  mb-3 position-relative ">
                                    <label class="small mb-1"> State </label>
                                    <input type="text" name="panState" maxLength="30" class="form-control" id="first-name" value={panState} onChange={(e) => handlePanInput(e)} />
                                </div>
                                <div class="form-group  mb-3 position-relative ">
                                    <label class="small mb-1"> Status </label>
                                    <input type="text" name="panState" maxLength="30" class="form-control" id="first-name" disabled
                                        value={panStatus === 1 ? 'Active' : panStatus === 0 ? 'Inactive' : ''} />
                                </div>
                                <div class="form-group  position-relative mt-4">
                                    <button class="btn btn-primary   btn-block w-100" type="button" onClick={() => { editPANdtl(panNumber, nameOnPan, panDob, panImage, panState); }}>
                                        Edit Pan

                                    </button>
                                </div>
                                <button class="btn btn-Danger btn-block w-100" type="button" onClick={() => {
                                    dltPandetails(userId);
                                }}>
                                    Delete
                                </button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>

            {/*showimage*/}
            <div className="modal" id="showimage" tabIndex="-1" aria-labelledby="editwallrtlmodalTitle" style={{ display: "none" }} aria-hidden="true"     >
                <div className="modal-dialog modal-lg  alert_modal modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="editwallrtlmodalTitle">
                                Aadhar Details
                            </h5>
                            <button className="btn-close" type="button" data-bs-dismiss="modal" aria-label="Close"></button>
                            <hr />
                        </div>
                        <div className="modal-body">
                            {frontImage ? <div>
                                <div className=' mb-3' >
                                    <strong>Front Image</strong>
                                    <img alt='frontImage' src={'http://13.127.44.238:4002/' + frontImage} className='img-fluid mb-3' /> </div>
                                <div>
                                    <strong>Front Image</strong>
                                    <img alt='frontImage' src={'http://13.127.44.238:4002/' + backImage} className='img-fluid' /> </div>
                            </div>
                                :
                                <div className=' mb-3' >
                                    <div className='d-flex justify-content-center'> <strong>User is Verified with OTP</strong> </div>
                                    <hr />
                                    <div className='d-flex flex-column'> 
                                         <strong>Aadhar Name : {aadharDetails?.adahr_name}</strong> 
                                         <strong>Aadhar Number : {aadharDetails?.adhar_no}</strong> 
                                    </div>

                                </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default PendingRequest
