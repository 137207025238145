import React from 'react'
import Header from '../Header'
import Sidebar from '../Sidebar'

const WithdrawPending = () => {
    return (
        <>

            <div id="layoutSidenav_content">
                <main>
                    <header class="page-header page-header-dark bg-gradient-primary-to-secondary pb-10">
                        <div class="container-xl px-4">
                            <div class="page-header-content pt-4">
                                <div class="row align-items-center justify-content-between">
                                    <div class="col-auto mt-4">
                                        <h1 class="page-header-title">
                                            <div class="page-header-icon"><i class="ri-wallet-3-line"></i></div>
                                            Withdraw List
                                        </h1>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </header>

                    <div class="container-xl px-4 mt-n10">

                        <div class="card mb-4">

                            <div class="card-body">
                                <div class=" filter_bar mb-4 mb-md-5">
                                    <form method="get" accept-charset="utf-8" novalidate="novalidate" class="form-inline search_form" action="/admin/users/referraldata">
                                        <div class="row gx-2 align-items-center">


                                            <div class="form-group  mb-0 col">
                                                <div class="input text">
                                                    <input type="text" name="Name" class="form-control" placeholder="User Name" id="Name" />
                                                </div>
                                            </div>
                                            <div class="form-group  mb-0 col">
                                                <div class="input text">
                                                    <input type="email" name="email" class="form-control" placeholder="User Email" id="email" />
                                                </div>
                                            </div>
                                            <div class="form-group  mb-0 col">
                                                <div class="input text">
                                                    <input type="date" name="start_date" class="form-control datepicker-input start_date" placeholder="Enter Registered From" id="start-date" />
                                                </div>
                                            </div>
                                            <div class="form-group  mb-0 col">
                                                <div class="input text">
                                                    <input type="date" name="end_date" class="form-control datepicker-input end_date" placeholder="To" id="end-date" />
                                                </div>
                                            </div>
                                            <div class="form-group  mb-0 col">
                                                <div class="row gx-2" >
                                                    <div class="col" >
                                                        <button type="button" class="btn btn-primary submit px-5 w-100 btn-block">Search</button>
                                                    </div>
                                                    <div class="col" >
                                                        <a href="#" class="btn btn-dark px-4  w-100 btn-block"><i class="fa fa-undo me-2"></i> Reset</a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>

                                <table id="datatablesSimple">
                                    <thead>
                                        <tr>
                                            <th>#</th>
                                            <th>User Name</th>
                                            <th>Email</th>
                                            <th>Phone</th>
                                            <th>Refund Amount (INR)</th>
                                            <th>After Deduct Amount (INR)</th>
                                            <th>Type</th>
                                            <th>Status</th>
                                            <th>Requested Date</th>
                                            <th class="last_td">Action</th>
                                        </tr>
                                    </thead>
                                    <tfoot>
                                        <tr>
                                            <th>#</th>
                                            <th>User Name</th>
                                            <th>Email</th>
                                            <th>Phone</th>
                                            <th>Refund Amount (INR)</th>
                                            <th>After Deduct Amount (INR)</th>
                                            <th>Type</th>
                                            <th>Status</th>
                                            <th>Requested Date</th>
                                            <th  >Action</th>
                                        </tr>
                                    </tfoot>
                                    <tbody>
                                        <tr>
                                            <td>1</td>
                                            <td></td>
                                            <td></td>
                                            <td><a href="#"></a></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td>
                                                {/* <button type="btn" class="btn badge bg-success text-white rounded-pill">Active</button> */}
                                            </td>
                                            <td></td>
                                            <td>
                                                <div class="table_action_group">
                                                    <button type="btn" class="btn badge bg-success text-white rounded-pill">Edit</button>
                                                    <button type="btn" class="btn badge bg-primary text-white rounded-pill">View</button>
                                                    <button type="btn" class="btn badge bg-danger text-white rounded-pill">Delete</button>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>




                </main>
            </div>


            <div class="modal" id="editwallrtlmodal" tabIndex="-1" aria-labelledby="editwallrtlmodalTitle" style={{ display: 'none' }} aria-hidden="true">
                <div class="modal-dialog  alert_modal modal-dialog-centered" role="document">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="editwallrtlmodalTitle">Update Wallet</h5>
                            <button class="btn-close" type="button" data-bs-dismiss="modal" aria-label="Close"></button>
                            <hr />
                        </div>
                        <div class="modal-body">

                            <div class="form-group row align-items-center   mb-1">
                                <div class="col-6">
                                    <label class="small  me-2">User Mobile :</label>
                                </div>
                                <div class="col-6">
                                    <strong>----</strong>
                                </div>
                            </div>
                            <div class="form-group row align-items-center   mb-1">
                                <div class="col-6">
                                    <label class="small  me-2">User Amount :</label>
                                </div>
                                <div class="col-6">
                                    <strong>----</strong>
                                </div>
                            </div>
                            <div class="form-group row align-items-center  mb-1">
                                <div class="col-6">
                                    <label class="small  me-2">Wallet Type :</label>
                                </div>
                                <div class="col-6">
                                    <strong>----</strong>
                                </div>
                            </div>

                            <br />


                            <form>
                                <div class="form-group  mb-3 position-relative ">
                                    <label class="small mb-1"> Amount </label>
                                    <input class="form-control  input-copy" type="text" placeholder="Enter Amount" value="" />
                                </div>
                                <div class="form-group  mb-3 position-relative ">
                                    {/* <!-- <label class="small mb-1"> Sponcer Username </label> --> */}
                                    <select name="up_type" id="up_type" class="form-control form-select"><option value="1">Add</option><option value="2">Deduct</option></select>
                                </div>
                                <div class="form-group  position-relative mt-4">
                                    <button class="btn btn-primary   btn-block w-100" type="button">Add Referal</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}

export default WithdrawPending
