
import React, { useEffect, useState } from 'react';
import LoaderHelper from '../../Components/Loading/LoaderHelper';
import AuthService from '../../api/services/HomeService';
import { alertErrorMessage, alertSuccessMessage } from '../../Components/CustomAlertMessage';
import Moment from 'react-moment';

const UPIRequest = () => {
    const [list, setlist] = useState();
    const [skip, setSkip] = useState(0);
    const [type, setType] = useState(1);
    const [lastLength, setLastLength] = useState(0);

    useEffect(() => {
        UpiList(0, 1)
    }, []);

    const UpiList = async (skip, type) => {
        LoaderHelper.loaderStatus(true);
        await AuthService.upilist(skip, 20, type).then((result) => {
            if (result?.success) {
                LoaderHelper.loaderStatus(false);
                try {
                    setLastLength(result?.total_length)
                    setType(type)
                    setSkip(skip)
                    setlist(result?.data)
                } catch {
                    alertErrorMessage(result?.message);
                }
            } else {
                LoaderHelper.loaderStatus(false);
                alertErrorMessage(result.message);
            }
        });
    };

    const VerifyUPI = async (id, status, userid) => {
        await AuthService.veriupi(id, status, userid).then((result) => {
            if (result?.success) {
                try {
                    alertSuccessMessage("UPI Status Updated Successfully!!");
                    UpiList(skip, type)

                } catch {
                    alertErrorMessage(result.message);
                }
            } else {
                // alertErrorMessage(result.message);
            }
        });
    };

    const filter = (e) => {
        let uncheck = document.getElementById("pending");
        uncheck.checked = false
        if (e.target.checked) {
            UpiList(0, 2)
        } else {
            UpiList(0, 1)
        }
    };
    const filter2 = (e) => {
        let uncheck = document.getElementById("unverified");
        uncheck.checked = false
        if (e.target.checked) {
            UpiList(0, 0)
        } else {
            UpiList(0, 1)
        }
    };

    return (
        <>

            <div id="layoutSidenav_content">
                <main>
                    <header class="page-header page-header-dark bg-gradient-primary-to-secondary pb-10">
                        <div class="container-xl px-4">
                            <div class="page-header-content pt-4">
                                <div class="row align-items-center justify-content-between">
                                    <div class="col-auto mt-4">
                                        <h1 class="page-header-title">
                                            <div class="page-header-icon"><i class="ri-group-line"></i></div>
                                            UPI Request
                                        </h1>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </header>
                    <div class="container-xl px-4 mt-n10">

                        <div class="card mb-4">

                            <div class="card-body">
                                <div class=" filter_bar mb-4 mb-md-5">
                                    <form method="get" accept-charset="utf-8" novalidate="novalidate" class="form-inline search_form" action="/admin/users/referraldata">
                                        <div class="row gx-2 align-items-center">

                                            <div class="form-group mb-0 col-sm-6 col-md-3">
                                                <div class=" form-check ms-2" >
                                                    <input class="form-check-input text-lg" type="checkbox" id="unverified" onChange={filter} />
                                                    <label class="form-check-label text-lg" for="unverified">
                                                        Show Pending Request
                                                    </label>
                                                </div>
                                            </div>
                                            <div class="form-group mb-0 col-sm-6 col-md-3">
                                                <div class=" form-check ms-2" >
                                                    <input class="form-check-input text-lg" type="checkbox" id="pending" onChange={filter2} />
                                                    <label class="form-check-label text-lg" for="pending">
                                                        Show Rejected List
                                                    </label>
                                                </div>
                                            </div>

                                        </div>
                                    </form>
                                </div>


                                <table id="datatablesSimple">
                                    <thead>
                                        <tr>
                                            <th>#</th>
                                            <th>Name</th>
                                            <th>UPI ID</th>
                                            <th>Name on Aadhar</th>
                                            <th>Aadhar Number</th>
                                            <th>Aadhar Status</th>
                                            <th>Name On Pan</th>
                                            <th>Pan Number</th>
                                            <th>Pan Status</th>
                                            <th>Registration Date</th>
                                            <th>Status</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {list ? list?.map((data, index) => {
                                            return (
                                                <tr>
                                                    <td>{skip + index + 1}</td>
                                                    <td>{data?.name_in_upi}</td>
                                                    <td>{data?.upinumber}</td>
                                                    <td>{data?.adharname}</td>
                                                    <td>{data?.adharnumber}</td>
                                                    <td>{data?.adharstatus == 2 ? <span className='text-warning'> Pending</span> : data?.adharstatus == 1 ? <span className='text-success'> Approved</span> : <span className='text-danger'>Rejected</span>}</td>
                                                    <td>{data?.NameOnPan}</td>
                                                    <td>{data?.PanNumber}</td>
                                                    <td>{data?.Panverified == 2 ? <span className='text-warning'> Pending</span> : data?.Panverified == 1 ? <span className='text-success'> Approved</span> : <span className='text-danger'>Rejected</span>}</td>
                                                    <td  > <Moment date={data?.Regestrationdate} format="YYYY/MM/DD" /> </td>
                                                    <td>{data?.upi_verified == 2 ?
                                                        <>
                                                            <button type="btn" class="btn badge bg-success text-white rounded-pill"
                                                                onClick={() => { VerifyUPI(data?._id, '1', data?.user_id) }}
                                                            >Approve</button>

                                                            <button type="button" class="mt-2 mx-2   btn badge bg-danger text-white rounded-pill"
                                                                onClick={() => { VerifyUPI(data?._id, '3', data?.user_id) }}> Reject</button>
                                                        </> :
                                                        data?.upi_verified == 1 ? <p className='text-success text-center'>Approved</p> : data?.upi_verified == 3 ? <p className='text-danger text-center'>Rejected</p> : ''

                                                    }</td>
                                                </tr>
                                            )
                                        }) : ''}
                                    </tbody>
                                </table>
                                <div className="row d-flex justify-content-center">
                                    <button type="btn" class="btn badge bg-primary text-white rounded-pill  mx-2 p-3  col-1" disabled={skip === 0} onClick={() => { UpiList(0, type) }}>{"<<<<"}</button>
                                    <button type="btn" class="btn badge bg-primary text-white rounded-pill  mx-2 p-3  col-2" disabled={skip === 0} onClick={() => { UpiList(skip - 20, type) }}>{"<<Previous"}</button>
                                    <button type="btn" class="btn badge bg-primary text-white rounded-pill  mx-2 p-3  col-2" disabled={list?.length < 20} onClick={() => { UpiList(skip + 20, type) }}>{"Next>>"}</button>
                                    <button type="btn" class="btn badge bg-primary text-white rounded-pill mx-2 p-3 col-1" disabled={list?.length < 20} onClick={() => { UpiList(Math.floor(lastLength / 20) * 20, type) }}>{">>>>"}</button>
                                </div>
                            </div>
                        </div>
                    </div>

                </main>
            </div>

        </>
    )
}

export default UPIRequest
