import React, { useEffect, useState } from 'react'
import AuthService from '../../api/services/HomeService';
import { alertErrorMessage, alertSuccessMessage } from '../../Components/CustomAlertMessage';
import LoaderHelper from '../../Components/Loading/LoaderHelper';

const CrickerPoints = () => {
    const [t10List, sett10List] = useState();
    const [t20List, sett20List] = useState();
    const [odiList, setodiList] = useState();
    const [testList, settestList] = useState();
    const [inputData, setinputData] = useState();
    const rearrangeCategoryOrder = (arr, category, newIndex) => {
        const index = arr.findIndex((obj) => obj.Catogary === category);
        if (index > -1 && index !== newIndex) {
            const [removed] = arr.splice(index, 1);
            arr.splice(newIndex, 0, removed);
        }
    };

    const handleInputChange = (event, id, category, match_type) => {
        if (id !== inputData?._id) {
            setinputData()
        }
        const { name, value } = event.target;
        setinputData((prevFormData) => ({
            ...prevFormData,
            [name]: value,
            _id: id,
            Catogary: category,
            match_type: match_type,
        }));
    };

    useEffect(() => {
        getPointst10();
    }, []);
    const getPointstest = async () => {
        LoaderHelper.loaderStatus(true);
        await AuthService.getPointstest().then((result) => {
            if (result?.success) {
                LoaderHelper.loaderStatus(false);
                try {
                    rearrangeCategoryOrder(result?.data[0], "Bowling", 1);
                    rearrangeCategoryOrder(result?.data[0], "Batting", 0);
                    rearrangeCategoryOrder(result?.data[0], "Fielding", 2);
                    rearrangeCategoryOrder(result?.data[0], "Other", 3);
                    settestList(result?.data[0])
                } catch {
                    alertErrorMessage(result.message);
                }
            } else {
                LoaderHelper.loaderStatus(false);
                alertErrorMessage(result.message);
            }
        })
    };
    const getPointst10 = async () => {
        LoaderHelper.loaderStatus(true);
        await AuthService.getPoints().then((result) => {
            if (result?.success) {
                LoaderHelper.loaderStatus(false);
                rearrangeCategoryOrder(result?.data[0], "Bowling", 0);
                rearrangeCategoryOrder(result?.data[0], "Batting", 1);
                rearrangeCategoryOrder(result?.data[0], "Fielding", 2);
                rearrangeCategoryOrder(result?.data[0], "Other", 3);
                rearrangeCategoryOrder(result?.data[0], "EconomyRate", 4);
                rearrangeCategoryOrder(result?.data[0], "StrikeRate", 5);
                sett10List(result?.data[0])

                try {
                } catch {
                    alertErrorMessage(result.message);
                }
            } else {
                LoaderHelper.loaderStatus(false);
                alertErrorMessage(result.message);
            }
        })
    };


    const getPointst20 = async () => {
        LoaderHelper.loaderStatus(true);
        await AuthService.getPointst20().then((result) => {
            if (result?.success) {
                LoaderHelper.loaderStatus(false);
                try {
                    rearrangeCategoryOrder(result?.data[0], "Batting", 0);
                    rearrangeCategoryOrder(result?.data[0], "Bowling", 1);
                    rearrangeCategoryOrder(result?.data[0], "Fielding", 2);
                    rearrangeCategoryOrder(result?.data[0], "Other", 3);
                    rearrangeCategoryOrder(result?.data[0], "EconomyRate", 4);
                    rearrangeCategoryOrder(result?.data[0], "StrikeRate", 5);
                    sett20List(result?.data[0])
                } catch {
                    alertErrorMessage(result.message);
                }
            } else {
                LoaderHelper.loaderStatus(false);
                alertErrorMessage(result.message);
            }
        })
    };

    const getPointsODI = async () => {
        LoaderHelper.loaderStatus(true);
        await AuthService.getPointsODI().then((result) => {
            if (result?.success) {
                LoaderHelper.loaderStatus(false);
                try {
                    rearrangeCategoryOrder(result?.data[0], "Batting", 0);
                    rearrangeCategoryOrder(result?.data[0], "Bowling", 1);
                    rearrangeCategoryOrder(result?.data[0], "Fielding", 2);
                    rearrangeCategoryOrder(result?.data[0], "Other", 3);
                    rearrangeCategoryOrder(result?.data[0], "EconomyRate", 4);
                    rearrangeCategoryOrder(result?.data[0], "StrikeRate", 5);
                    setodiList(result?.data[0])
                } catch {
                    alertErrorMessage(result.message);
                }
            } else {
                LoaderHelper.loaderStatus(false);
                alertErrorMessage(result.message);
            }
        })
    };
    const EditPoints = async (inputData) => {
        LoaderHelper.loaderStatus(true);
        await AuthService.EditPoints(inputData).then((result) => {
            if (result?.success) {
                LoaderHelper.loaderStatus(false);
                try {
                    alertSuccessMessage('Edited successfully')
                    setinputData()
                    getPointstest();
                    getPointst10();
                    getPointst20();
                    getPointsODI();
                } catch {
                    alertErrorMessage(result.message);
                }
            } else {
                LoaderHelper.loaderStatus(false);
                alertErrorMessage(result.message);
            }
        })
    };


    return (
        <>

            <div id="layoutSidenav_content">
                <main>
                    <header class="page-header page-header-dark bg-gradient-primary-to-secondary pb-10">
                        <div class="container-xl px-4">
                            <div class="page-header-content pt-4">
                                <div class="row align-items-center justify-content-between">
                                    <div class="col-auto mt-4">
                                        <h1 class="page-header-title">
                                            <div class="page-header-icon"><i class="ri-football-line"></i></div>
                                            Points Details
                                        </h1>
                                        {/* <!-- <div class="page-header-subtitle">Example dashboard overview and content summary</div> --> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </header>


                    <div class="container-xl px-4 mt-n10">

                        <div class="card mb-4">

                            <div class="card-body">
                                {/* Tabbing */}
                                <ul class="nav nav-tabs" id="myTab" role="tablist">
                                    <li class="nav-item" role="presentation">
                                        <button class="nav-link active" id="T10-tab" data-bs-toggle="tab" data-bs-target="#T10" type="button" role="tab" aria-controls="T10" aria-selected="true" onClick={getPointst10}>T10</button>
                                    </li>
                                    <li class="nav-item" role="presentation">
                                        <button class="nav-link" id="T20-tab" data-bs-toggle="tab" data-bs-target="#T20" type="button" role="tab" aria-controls="T20" aria-selected="false" onClick={getPointst20}>T20</button>
                                    </li>
                                    <li class="nav-item" role="presentation">
                                        <button class="nav-link" id="ODI-tab" data-bs-toggle="tab" data-bs-target="#ODI" type="button" role="tab" aria-controls="ODI" aria-selected="false" onClick={getPointsODI}>ODI</button>
                                    </li>
                                    <li class="nav-item" role="presentation">
                                        <button class="nav-link" id="Test-tab" data-bs-toggle="tab" data-bs-target="#Test" type="button" role="tab" aria-controls="Test" aria-selected="false" onClick={getPointstest}>Test</button>
                                    </li>
                                </ul>

                                <div class="tab-content mt-4" id="myTabContent">

                                    {/* T10 Series.......... */}
                                    <div class="tab-pane show active" id="T10" role="tabpanel" aria-labelledby="T10-tab">

                                        <div class="row g-3">
                                            <div class="col-xl-4">
                                                <div class="card card_border h-100">
                                                    <div class="card-body d-flex justify-content-start flex-column pt-5 ">
                                                        <div class="d-flex align-items-center justify-content-start mb-4 ">
                                                            <div class="me-3"><h4 class="mb-0 "><strong>Batting</strong></h4></div>
                                                        </div>
                                                        {t10List ?
                                                            <form>
                                                                <div class="form-group row align-items-center">
                                                                    <div class="col-8" >
                                                                        <label class="small  me-2">Run: </label>
                                                                    </div>
                                                                    <div class="col-4" >
                                                                        <input class="form-control check  
                                                                            form-control check-solid" name='Run' type='number' types={t10List[1]?.Run} onWheel={(e) => { e.target.blur() }} onChange={(e) => handleInputChange(e, t10List[1]._id, t10List[1].Catogary, t10List[1].match_type)}
                                                                            value={inputData?._id === t10List[1]?._id ? inputData?.Run : t10List[1]?.Run}
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <div class="form-group row align-items-center">
                                                                    <div class="col-8" >
                                                                        <label class="small  me-2">Boundary Bonus:</label>
                                                                    </div>
                                                                    <div class="col-4" >
                                                                        <input class="form-control check  form-control check-solid" name='Boundary_Bonus' type='number' types={t10List[1]?.Boundary_Bonus}
                                                                            onWheel={(e) => { e.target.blur() }} onChange={(e) => handleInputChange(e, t10List[1]._id, t10List[1].Catogary, t10List[1].match_type)} value={inputData?._id === t10List[1]?._id ? inputData?.Boundary_Bonus : t10List[1]?.Boundary_Bonus} />
                                                                    </div>
                                                                </div>
                                                                <div class="form-group row align-items-center">
                                                                    <div class="col-8" >
                                                                        <label class="small  me-2">Six Bonus:</label>
                                                                    </div>
                                                                    <div class="col-4" >
                                                                        <input class="form-control check  form-control check-solid" type='number' types={t10List[1]?.Six_Bonus}
                                                                            name='Six_Bonus'
                                                                            onWheel={(e) => { e.target.blur() }} onChange={(e) => handleInputChange(e, t10List[1]._id, t10List[1].Catogary, t10List[1].match_type)} value={inputData?._id === t10List[1]?._id ? inputData?.Six_Bonus : t10List[1]?.Six_Bonus} />
                                                                    </div>
                                                                </div>
                                                                <div class="form-group row align-items-center">
                                                                    <div class="col-8" >
                                                                        <label class="small  me-2">30 Runs Bonus:</label>
                                                                    </div>
                                                                    <div class="col-4" >
                                                                        <input class="form-control check  form-control check-solid" type='number' types={t10List[1]?.thirty_run_Bonus}
                                                                            name='thirty_run_Bonus'
                                                                            onWheel={(e) => { e.target.blur() }} onChange={(e) => handleInputChange(e, t10List[1]._id, t10List[1].Catogary, t10List[1].match_type)} value={inputData?._id === t10List[1]?._id ? inputData?.thirty_run_Bonus : t10List[1]?.thirty_run_Bonus} />
                                                                    </div>
                                                                </div>
                                                                <div class="form-group row align-items-center">
                                                                    <div class="col-8" >
                                                                        <label class="small  me-2">50 Runs Bonus:</label>
                                                                    </div>
                                                                    <div class="col-4" >
                                                                        <input class="form-control check  form-control check-solid" type='number' types={t10List[1]?.half_century_Bonus}
                                                                            name='half_century_Bonus'
                                                                            onWheel={(e) => { e.target.blur() }} onChange={(e) => handleInputChange(e, t10List[1]._id, t10List[1].Catogary, t10List[1].match_type)} value={inputData?._id === t10List[1]?._id ? inputData?.half_century_Bonus : t10List[1]?.half_century_Bonus} />
                                                                    </div>
                                                                </div>
                                                                <div class="form-group row align-items-center">
                                                                    <div class="col-8" >
                                                                        <label class="small  me-2">Dismissal for a duck: <br /> <small>Batsman, Wicket-Keeper & All-Rounder</small></label>
                                                                    </div>
                                                                    <div class="col-4" >
                                                                        <input class="form-control check  form-control check-solid" type='number' types={t10List[1]?.Dismissal_on_Duck}
                                                                            name='Dismissal_on_Duck'
                                                                            onWheel={(e) => { e.target.blur() }} onChange={(e) => handleInputChange(e, t10List[1]._id, t10List[1].Catogary, t10List[1].match_type)} value={inputData?._id === t10List[1]?._id ? inputData?.Dismissal_on_Duck : t10List[1]?.Dismissal_on_Duck} />
                                                                    </div>
                                                                </div>
                                                                <button class="btn btn-primary   btn-block w-100 mt-2" type="button" onClick={() => EditPoints(inputData)} >Update</button>
                                                            </form> : ''}
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-xl-4">
                                                <div class="card card_border h-100">
                                                    <div class="card-body d-flex justify-content-start flex-column pt-5 ">
                                                        <div class="d-flex align-items-center justify-content-start mb-4 ">
                                                            <div class="me-3"><h4 class="mb-0 "><strong>Bowling</strong></h4></div>
                                                        </div>
                                                        {t10List ?
                                                            <form>
                                                                <div class="form-group row align-items-center">
                                                                    <div class="col-8" >
                                                                        <label class="small  me-2">Wicket: <small>Excluding Run Out</small> </label>
                                                                    </div>
                                                                    <div class="col-4" >
                                                                        <input class="form-control check  form-control check-solid" type='number' types={t10List[0]?.Wicket}
                                                                            name='Wicket'
                                                                            onWheel={(e) => { e.target.blur() }} onChange={(e) => handleInputChange(e, t10List[0]._id, t10List[0].Catogary, t10List[0].match_type)} value={inputData?._id === t10List[0]?._id ? inputData?.Wicket : t10List[0]?.Wicket} />
                                                                    </div>
                                                                </div>
                                                                <div class="form-group row align-items-center">
                                                                    <div class="col-8" >
                                                                        <label class="small  me-2">2 Wicket Bonus:</label>
                                                                    </div>
                                                                    <div class="col-4" >
                                                                        <input class="form-control check  form-control check-solid" type='number' types={t10List[0]?.TwoWicketBonus} name='TwoWicketBonus'
                                                                            onWheel={(e) => { e.target.blur() }} onChange={(e) => handleInputChange(e, t10List[0]._id, t10List[0].Catogary, t10List[0].match_type)} value={inputData?._id === t10List[0]?._id ? inputData?.TwoWicketBonus : t10List[0]?.TwoWicketBonus} />
                                                                    </div>
                                                                </div>
                                                                <div class="form-group row align-items-center">
                                                                    <div class="col-8" >
                                                                        <label class="small  me-2">3 Wicket Bonus:</label>
                                                                    </div>
                                                                    <div class="col-4" >
                                                                        <input class="form-control check  form-control check-solid" type='number' types={t10List[0]?.ThreeWicket}

                                                                            name='ThreeWicket'
                                                                            onWheel={(e) => { e.target.blur() }} onChange={(e) => handleInputChange(e, t10List[0]._id, t10List[0].Catogary, t10List[0].match_type)} value={inputData?._id === t10List[0]?._id ? inputData?.ThreeWicket : t10List[0]?.ThreeWicket} />
                                                                    </div>
                                                                </div>
                                                                <div class="form-group row align-items-center">
                                                                    <div class="col-8" >
                                                                        <label class="small  me-2">Maiden over:</label>
                                                                    </div>
                                                                    <div class="col-4" >
                                                                        <input class="form-control check  form-control check-solid" type='number' types={t10List[0]?.Maiden}
                                                                            name='Maiden'
                                                                            onWheel={(e) => { e.target.blur() }} onChange={(e) => handleInputChange(e, t10List[0]._id, t10List[0].Catogary, t10List[0].match_type)} value={inputData?._id === t10List[0]?._id ? inputData?.Maiden : t10List[0]?.Maiden}
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <div class="form-group row align-items-center">
                                                                    <div class="col-8" >
                                                                        <label class="small  me-2">Bonus (LBW / Bowled):</label>
                                                                    </div>
                                                                    <div class="col-4" >
                                                                        <input class="form-control check  form-control check-solid" type='number' types={t10List[0]?.lbwbonus}
                                                                            name='lbwbonus'
                                                                            onWheel={(e) => { e.target.blur() }} onChange={(e) => handleInputChange(e, t10List[0]._id, t10List[0].Catogary, t10List[0].match_type)} value={inputData?._id === t10List[0]?._id ? inputData?.lbwbonus : t10List[0]?.lbwbonus}
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <button class="btn btn-primary   btn-block w-100 mt-2" type="button" onClick={() => EditPoints(inputData)} >Update</button>
                                                            </form> : ''}
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-xl-4">
                                                <div class="card card_border h-100">
                                                    <div class="card-body d-flex justify-content-start flex-column pt-5 ">
                                                        <div class="d-flex align-items-center justify-content-start mb-4 ">
                                                            <div class="me-3"><h4 class="mb-0 "><strong>Fielding</strong></h4></div>
                                                        </div>
                                                        {t10List ?
                                                            <form>
                                                                <div class="form-group row align-items-center">
                                                                    <div class="col-8" >
                                                                        <label class="small  me-2">Catch: </label>
                                                                    </div>
                                                                    <div class="col-4" >
                                                                        <input class="form-control check  form-control check-solid" type='number' types={t10List[2]?.catch}
                                                                            name='catch'
                                                                            onWheel={(e) => { e.target.blur() }} onChange={(e) => handleInputChange(e, t10List[2]._id, t10List[2].Catogary, t10List[2].match_type)} value={inputData?._id === t10List[2]?._id ? inputData?.catch : t10List[2]?.catch} />
                                                                    </div>
                                                                </div>
                                                                <div class="form-group row align-items-center">
                                                                    <div class="col-8" >
                                                                        <label class="small  me-2">Run out (Direct hit):</label>
                                                                    </div>
                                                                    <div class="col-4" >
                                                                        <input class="form-control check  form-control check-solid" type='number' types={t10List[2]?.RunOut_throw}
                                                                            name='RunOut_throw'
                                                                            onWheel={(e) => { e.target.blur() }} onChange={(e) => handleInputChange(e, t10List[2]._id, t10List[2].Catogary, t10List[2].match_type)} value={inputData?._id === t10List[2]?._id ? inputData?.RunOut_throw : t10List[2]?.RunOut_throw} />
                                                                    </div>
                                                                </div>
                                                                <div class="form-group row align-items-center">
                                                                    <div class="col-8" >
                                                                        <label class="small  me-2">Run out (Not a direct hit) :</label>
                                                                    </div>
                                                                    <div class="col-4" >
                                                                        <input class="form-control check  form-control check-solid" type='number' types={t10List[2]?.RunOut_catch}
                                                                            name='RunOut_catch'
                                                                            onWheel={(e) => { e.target.blur() }} onChange={(e) => handleInputChange(e, t10List[2]._id, t10List[2].Catogary, t10List[2].match_type)} value={inputData?._id === t10List[2]?._id ? inputData?.RunOut_catch : t10List[2]?.RunOut_catch} />
                                                                    </div>
                                                                </div>
                                                                <div class="form-group row align-items-center">
                                                                    <div class="col-8" >
                                                                        <label class="small  me-2">Stumping :</label>
                                                                    </div>
                                                                    <div class="col-4" >
                                                                        <input class="form-control check  form-control check-solid" type='number' types={t10List[2]?.stumping}
                                                                            name='stumping'
                                                                            onWheel={(e) => { e.target.blur() }} onChange={(e) => handleInputChange(e, t10List[2]._id, t10List[2].Catogary, t10List[2].match_type)} value={inputData?._id === t10List[2]?._id ? inputData?.stumping : t10List[2]?.stumping} />
                                                                    </div>
                                                                </div>
                                                                <div class="form-group row align-items-center">
                                                                    <div class="col-8" >
                                                                        <label class="small  me-2">3 Catch Bonus:</label>
                                                                    </div>
                                                                    <div class="col-4" >
                                                                        <input class="form-control check  form-control check-solid" type='number' types={t10List[2]?.morethenthreecatch}
                                                                            name='morethenthreecatch'
                                                                            onWheel={(e) => { e.target.blur() }} onChange={(e) => handleInputChange(e, t10List[2]._id, t10List[2].Catogary, t10List[2].match_type)} value={inputData?._id === t10List[2]?._id ? inputData?.morethenthreecatch : t10List[2]?.morethenthreecatch} />
                                                                    </div>
                                                                </div>

                                                                <button class="btn btn-primary   btn-block w-100 mt-2" type="button" onClick={() => EditPoints(inputData)} >Update</button>
                                                            </form> : ''}
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-xl-4">
                                                <div class="card card_border h-100">
                                                    <div class="card-body d-flex justify-content-start flex-column pt-5 ">
                                                        <div class="d-flex align-items-center justify-content-start mb-4 ">
                                                            <div class="me-3"><h4 class="mb-0 "><strong>Other</strong></h4></div>
                                                        </div>
                                                        {t10List ?
                                                            <form>
                                                                <div class="form-group row align-items-center">
                                                                    <div class="col-8" >
                                                                        <label class="small  me-2">Captain:</label>
                                                                    </div>
                                                                    <div class="col-4" >
                                                                        <input class="form-control check  form-control check-solid" type='number' types={t10List[3]?.Captain}
                                                                            name='Captain'
                                                                            onWheel={(e) => { e.target.blur() }} onChange={(e) => handleInputChange(e, t10List[3]._id, t10List[3].Catogary, t10List[3].match_type)} value={inputData?._id === t10List[3]?._id ? inputData?.Captain : t10List[3]?.Captain}
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <div class="form-group row align-items-center">
                                                                    <div class="col-8" >
                                                                        <label class="small  me-2">Vice-Captain:</label>
                                                                    </div>
                                                                    <div class="col-4" >
                                                                        <input class="form-control check  form-control check-solid" type='number' types={t10List[3]?.Vice_Captain}
                                                                            name='Vice_Captain'
                                                                            onWheel={(e) => { e.target.blur() }} onChange={(e) => handleInputChange(e, t10List[3]._id, t10List[3].Catogary, t10List[3].match_type)} value={inputData?._id === t10List[3]?._id ? inputData?.Vice_Captain : t10List[3]?.Vice_Captain} />
                                                                    </div>
                                                                </div>
                                                                <div class="form-group row align-items-center">
                                                                    <div class="col-8" >
                                                                        <label class="small  me-2">In announced lineups:</label>
                                                                    </div>
                                                                    <div class="col-4" >
                                                                        <input class="form-control check  form-control check-solid" type='number' types={t10List[3]?.Starting11}
                                                                            name='Starting11'
                                                                            onWheel={(e) => { e.target.blur() }} onChange={(e) => handleInputChange(e, t10List[3]._id, t10List[3].Catogary, t10List[3].match_type)} value={inputData?._id === t10List[3]?._id ? inputData?.Starting11 : t10List[3]?.Starting11} />
                                                                    </div>
                                                                </div>
                                                                <div class="form-group row align-items-center">
                                                                    <div class="col-8" >
                                                                        <label class="small  me-2">Playing substitue <br />
                                                                            <small> (Concussion, COVID-19, X-Factor, or Impact Player)</small>:</label>
                                                                    </div>
                                                                    <div class="col-4" >
                                                                        <input class="form-control check  form-control check-solid" type='number' types={t10List[3]?.playingsubstitue}
                                                                            name='playingsubstitue'
                                                                            onWheel={(e) => { e.target.blur() }} onChange={(e) => handleInputChange(e, t10List[3]._id, t10List[3].Catogary, t10List[3].match_type)} value={inputData?._id === t10List[3]?._id ? inputData?.playingsubstitue : t10List[3]?.playingsubstitue} />
                                                                    </div>
                                                                </div>
                                                                <button class="btn btn-primary   btn-block w-100 mt-2" type="button" onClick={() => EditPoints(inputData)} >Update</button>
                                                            </form> : ''}
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-xl-4">
                                                <div class="card card_border h-100">
                                                    <div class="card-body d-flex justify-content-start flex-column pt-5 ">
                                                        <div class="d-flex align-items-center justify-content-start mb-4 ">
                                                            <div class="me-3"><h4 class="mb-0 "><strong> Economy Rate (<small>Min 1 Over</small>)</strong></h4></div>
                                                        </div>
                                                        {t10List ?
                                                            <form>
                                                                <div class="form-group row align-items-center">
                                                                    <div class="col-8" >
                                                                        <label class="small  me-2">Below 7 runs per over:</label>
                                                                    </div>
                                                                    <div class="col-4" >
                                                                        <input class="form-control check  form-control check-solid" type='number' types={t10List[4]?.BlwSvn}
                                                                            name='BlwSvn'
                                                                            onWheel={(e) => { e.target.blur() }} onChange={(e) => handleInputChange(e, t10List[4]._id, t10List[4].Catogary, t10List[4].match_type)} value={inputData?._id === t10List[4]?._id ? inputData?.BlwSvn : t10List[4]?.BlwSvn} />
                                                                    </div>
                                                                </div>
                                                                <div class="form-group row align-items-center">
                                                                    <div class="col-8" >
                                                                        <label class="small  me-2">Between 7-7.9 runs per over: </label>
                                                                    </div>
                                                                    <div class="col-4" >
                                                                        <input class="form-control check  form-control check-solid" type='number' types={t10List[4]?.Seven_Seven_pntnin}
                                                                            name='Seven_Seven_pntnin'
                                                                            onWheel={(e) => { e.target.blur() }} onChange={(e) => handleInputChange(e, t10List[4]._id, t10List[4].Catogary, t10List[4].match_type)} value={inputData?._id === t10List[4]?._id ? inputData?.Seven_Seven_pntnin : t10List[4]?.Seven_Seven_pntnin} />
                                                                    </div>
                                                                </div>
                                                                <div class="form-group row align-items-center">
                                                                    <div class="col-8" >
                                                                        <label class="small  me-2">Between 8-9 runs per over:</label>
                                                                    </div>
                                                                    <div class="col-4" >
                                                                        <input class="form-control check  form-control check-solid" type='number' types={t10List[4]?.eight_nine}
                                                                            name='eight_nine'
                                                                            onWheel={(e) => { e.target.blur() }} onChange={(e) => handleInputChange(e, t10List[4]._id, t10List[4].Catogary, t10List[4].match_type)} value={inputData?._id === t10List[4]?._id ? inputData?.eight_nine : t10List[4]?.eight_nine} />
                                                                    </div>
                                                                </div>
                                                                <div class="form-group row align-items-center">
                                                                    <div class="col-8" >
                                                                        <label class="small  me-2">Between 14-15 runs per over:</label>
                                                                    </div>
                                                                    <div class="col-4" >
                                                                        <input class="form-control check  form-control check-solid" type='number' types={t10List[4]?.forteen_fvteen}
                                                                            name='forteen_fvteen'
                                                                            onWheel={(e) => { e.target.blur() }} onChange={(e) => handleInputChange(e, t10List[4]._id, t10List[4].Catogary, t10List[4].match_type)} value={inputData?._id === t10List[4]?._id ? inputData?.forteen_fvteen : t10List[4]?.forteen_fvteen} />
                                                                    </div>
                                                                </div>
                                                                <div class="form-group row align-items-center">
                                                                    <div class="col-8" >
                                                                        <label class="small  me-2">Between 15.1-16 runs per over:</label>
                                                                    </div>
                                                                    <div class="col-4" >
                                                                        <input class="form-control check  form-control check-solid" type='number' types={t10List[4]?.fiftenpntone_sixteen}
                                                                            name='fiftenpntone_sixteen'
                                                                            onWheel={(e) => { e.target.blur() }} onChange={(e) => handleInputChange(e, t10List[4]._id, t10List[4].Catogary, t10List[4].match_type)} value={inputData?._id === t10List[4]?._id ? inputData?.fiftenpntone_sixteen : t10List[4]?.fiftenpntone_sixteen} />
                                                                    </div>
                                                                </div>
                                                                <div class="form-group row align-items-center">
                                                                    <div class="col-8" >
                                                                        <label class="small  me-2">Above 16 runs per over:</label>
                                                                    </div>
                                                                    <div class="col-4" >
                                                                        <input class="form-control check  form-control check-solid" type='number' types={t10List[4]?.sixteen}
                                                                            name='sixteen'
                                                                            onWheel={(e) => { e.target.blur() }} onChange={(e) => handleInputChange(e, t10List[4]._id, t10List[4].Catogary, t10List[4].match_type)} value={inputData?._id === t10List[4]?._id ? inputData?.sixteen : t10List[4]?.sixteen} />
                                                                    </div>
                                                                </div>
                                                                <button class="btn btn-primary   btn-block w-100 mt-2" type="button" onClick={() => EditPoints(inputData)} >Update</button>
                                                            </form> : ""}
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-xl-4">
                                                <div class="card card_border h-100">
                                                    <div class="card-body d-flex justify-content-start flex-column pt-5 ">
                                                        <div class="d-flex align-items-center justify-content-start mb-4 ">
                                                            <div class="me-3"><h4 class="mb-0 "><strong> Strike Rate (Except Bowlers) <br /> (<small>Min 5 Balls</small>)</strong></h4></div>
                                                        </div>
                                                        {t10List ?
                                                            <form>
                                                                <div class="form-group row align-items-center">
                                                                    <div class="col-8" >
                                                                        <label class="small  me-2">Between 70-80 runs per 100 balls:</label>
                                                                    </div>
                                                                    <div class="col-4" >
                                                                        <input class="form-control check  form-control check-solid" type='number' types={t10List[5]?.Seventy_Eighty}
                                                                            name='Seventy_Eighty'
                                                                            onWheel={(e) => { e.target.blur() }} onChange={(e) => handleInputChange(e, t10List[5]._id, t10List[5].Catogary, t10List[5].match_type)} value={inputData?._id === t10List[5]?._id ? inputData?.Seventy_Eighty : t10List[5]?.Seventy_Eighty} />
                                                                    </div>
                                                                </div>
                                                                <div class="form-group row align-items-center">
                                                                    <div class="col-8" >
                                                                        <label class="small  me-2">Between 150-170 runs per 100 balls:</label>
                                                                    </div>
                                                                    <div class="col-4" >
                                                                        <input class="form-control check  form-control check-solid" type='number' types={t10List[5]?.onefiftytooneseventy}
                                                                            name='onefiftytooneseventy'
                                                                            onWheel={(e) => { e.target.blur() }} onChange={(e) => handleInputChange(e, t10List[5]._id, t10List[5].Catogary, t10List[5].match_type)} value={inputData?._id === t10List[5]?._id ? inputData?.onefiftytooneseventy : t10List[5]?.onefiftytooneseventy} />
                                                                    </div>
                                                                </div>
                                                                <div class="form-group row align-items-center">
                                                                    <div class="col-8" >
                                                                        <label class="small  me-2">Between 171-199 runs per 100 balls:</label>
                                                                    </div>
                                                                    <div class="col-4" >
                                                                        <input class="form-control check  form-control check-solid" type='number' types={t10List[5]?.onsvnty_to_oneightynine}
                                                                            name='onsvnty_to_oneightynine'
                                                                            onWheel={(e) => { e.target.blur() }} onChange={(e) => handleInputChange(e, t10List[5]._id, t10List[5].Catogary, t10List[5].match_type)} value={inputData?._id === t10List[5]?._id ? inputData?.onsvnty_to_oneightynine : t10List[5]?.onsvnty_to_oneightynine} />
                                                                    </div>
                                                                </div>
                                                                <div class="form-group row align-items-center">
                                                                    <div class="col-8" >
                                                                        <label class="small  me-2">Over 190 runs per 100 balls:</label>
                                                                    </div>
                                                                    <div class="col-4" >
                                                                        <input class="form-control check  form-control check-solid" type='number' types={t10List[5]?.over_one_ninty}
                                                                            name='over_one_ninty'
                                                                            onWheel={(e) => { e.target.blur() }} onChange={(e) => handleInputChange(e, t10List[5]._id, t10List[5].Catogary, t10List[5].match_type)} value={inputData?._id === t10List[5]?._id ? inputData?.over_one_ninty : t10List[5]?.over_one_ninty} />
                                                                    </div>
                                                                </div>
                                                                <div class="form-group row align-items-center">
                                                                    <div class="col-8" >
                                                                        <label class="small  me-2">Below 60 runs per 100 balls:</label>
                                                                    </div>
                                                                    <div class="col-4" >
                                                                        <input class="form-control check  form-control check-solid" type='number' types={t10List[5]?.Belowsxty}
                                                                            name='Belowsxty'
                                                                            onWheel={(e) => { e.target.blur() }} onChange={(e) => handleInputChange(e, t10List[5]._id, t10List[5].Catogary, t10List[5].match_type)} value={inputData?._id === t10List[5]?._id ? inputData?.Belowsxty : t10List[5]?.Belowsxty} />
                                                                    </div>
                                                                </div>
                                                                <button class="btn btn-primary   btn-block w-100 mt-2" type="button" onClick={() => EditPoints(inputData)} >Update</button>
                                                            </form> : ''}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>



                                    {/* T20 Series.......... */}

                                    <div class="tab-pane" id="T20" role="tabpanel" aria-labelledby="T20-tab">
                                        <div class="row g-3">
                                            <div class="col-xl-4">
                                                <div class="card card_border h-100">
                                                    <div class="card-body d-flex justify-content-start flex-column pt-5 ">
                                                        <div class="d-flex align-items-center justify-content-start mb-4 ">
                                                            <div class="me-3"><h4 class="mb-0 "><strong>Batting</strong></h4></div>
                                                        </div>
                                                        {t20List ?
                                                            <form>
                                                                <div class="form-group row align-items-center">
                                                                    <div class="col-8" >
                                                                        <label class="small  me-2">Run: </label>
                                                                    </div>
                                                                    <div class="col-4" >
                                                                        <input class="form-control check  form-control check-solid" type='number' types={t20List[0]?.Run}
                                                                            name='Run'
                                                                            onWheel={(e) => { e.target.blur() }} onChange={(e) => handleInputChange(e, t20List[0]._id, t20List[0].Catogary, t20List[0].match_type)} value={inputData?._id === t20List[0]?._id ? inputData?.Run : t20List[0]?.Run}
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <div class="form-group row align-items-center">
                                                                    <div class="col-8" >
                                                                        <label class="small  me-2">Boundary Bonus:</label>
                                                                    </div>
                                                                    <div class="col-4" >
                                                                        <input class="form-control check  form-control check-solid" type='number' types={t20List[0]?.Boundary_Bonus}
                                                                            name='Boundary_Bonus'
                                                                            onWheel={(e) => { e.target.blur() }} onChange={(e) => handleInputChange(e, t20List[0]._id, t20List[0].Catogary, t20List[0].match_type)} value={inputData?._id === t20List[0]?._id ? inputData?.Boundary_Bonus : t20List[0]?.Boundary_Bonus} />
                                                                    </div>
                                                                </div>
                                                                <div class="form-group row align-items-center">
                                                                    <div class="col-8" >
                                                                        <label class="small  me-2">Six Bonus:</label>
                                                                    </div>
                                                                    <div class="col-4" >
                                                                        <input class="form-control check  form-control check-solid" type='number' types={t20List[0]?.Six_Bonus}
                                                                            name='Six_Bonus'
                                                                            onWheel={(e) => { e.target.blur() }} onChange={(e) => handleInputChange(e, t20List[0]._id, t20List[0].Catogary, t20List[0].match_type)} value={inputData?._id === t20List[0]?._id ? inputData?.Six_Bonus : t20List[0]?.Six_Bonus} />
                                                                    </div>
                                                                </div>
                                                                <div class="form-group row align-items-center">
                                                                    <div class="col-8" >
                                                                        <label class="small  me-2">Half-century Bonus:
                                                                        </label>
                                                                    </div>
                                                                    <div class="col-4" >
                                                                        <input class="form-control check  form-control check-solid" type='number' types={t20List[0]?.half_century_Bonus}
                                                                            name='half_century_Bonus'
                                                                            onWheel={(e) => { e.target.blur() }} onChange={(e) => handleInputChange(e, t20List[0]._id, t20List[0].Catogary, t20List[0].match_type)} value={inputData?._id === t20List[0]?._id ? inputData?.half_century_Bonus : t20List[0]?.half_century_Bonus} />
                                                                    </div>
                                                                </div>
                                                                <div class="form-group row align-items-center">
                                                                    <div class="col-8" >
                                                                        <label class="small  me-2">Century Bonus:
                                                                        </label>
                                                                    </div>
                                                                    <div class="col-4" >
                                                                        <input class="form-control check  form-control check-solid" type='number'
                                                                            name='Century_Bonus'
                                                                            onWheel={(e) => { e.target.blur() }} onChange={(e) => handleInputChange(e, t20List[0]._id, t20List[0].Catogary, t20List[0].match_type)} value={inputData?._id === t20List[0]?._id ? inputData?.Century_Bonus : t20List[0]?.Century_Bonus}
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <div class="form-group row align-items-center">
                                                                    <div class="col-8" >
                                                                        <label class="small  me-2">Dismissal for a duck: <br /> <small>Batsman, Wicket-Keeper & All-Rounder</small></label>
                                                                    </div>
                                                                    <div class="col-4" >
                                                                        <input class="form-control check  form-control check-solid" type='number' types={t20List[0]?.Dismissal_on_Duck}
                                                                            name='Dismissal_on_Duck'
                                                                            onWheel={(e) => { e.target.blur() }} onChange={(e) => handleInputChange(e, t20List[0]._id, t20List[0].Catogary, t20List[0].match_type)} value={inputData?._id === t20List[0]?._id ? inputData?.Dismissal_on_Duck : t20List[0]?.Dismissal_on_Duck}
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <div class="form-group row align-items-center">
                                                                    <div class="col-8" >
                                                                        <label class="small  me-2">30 Run Bonus: <br /> </label>
                                                                    </div>
                                                                    <div class="col-4" >
                                                                        <input class="form-control check  form-control check-solid" type='number' types={t20List[0]?.thirty_run_Bonus}
                                                                            name='thirty_run_Bonus'
                                                                            onWheel={(e) => { e.target.blur() }} onChange={(e) => handleInputChange(e, t20List[0]._id, t20List[0].Catogary, t20List[0].match_type)} value={inputData?._id === t20List[0]?._id ? inputData?.thirty_run_Bonus : t20List[0]?.thirty_run_Bonus}
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <button class="btn btn-primary   btn-block w-100 mt-2" type="button" onClick={() => EditPoints(inputData)} >Update</button>
                                                            </form> : ''}
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-xl-4">
                                                <div class="card card_border h-100">
                                                    <div class="card-body d-flex justify-content-start flex-column pt-5 ">
                                                        <div class="d-flex align-items-center justify-content-start mb-4 ">
                                                            <div class="me-3"><h4 class="mb-0 "><strong>Bowling</strong></h4></div>
                                                        </div>
                                                        {t20List ?
                                                            <form>
                                                                <div class="form-group row align-items-center">
                                                                    <div class="col-8" >
                                                                        <label class="small  me-2">Wicket: <small>Excluding Run Out</small> </label>
                                                                    </div>
                                                                    <div class="col-4" >
                                                                        <input class="form-control check  form-control check-solid" type='number' types={t20List[1]?.Wicket}
                                                                            name='Wicket'
                                                                            onWheel={(e) => { e.target.blur() }} onChange={(e) => handleInputChange(e, t20List[1]._id, t20List[1].Catogary, t20List[1].match_type)} value={inputData?._id === t20List[1]?._id ? inputData?.Wicket : t20List[1]?.Wicket} />
                                                                    </div>
                                                                </div>
                                                                <div class="form-group row align-items-center">
                                                                    <div class="col-8" >
                                                                        <label class="small  me-2">4 wicket Bonus:</label>
                                                                    </div>
                                                                    <div class="col-4" >
                                                                        <input class="form-control check  form-control check-solid" type='number' types={t20List[1]?.FourWicketBonus}
                                                                            name='FourWicketBonus'
                                                                            onWheel={(e) => { e.target.blur() }} onChange={(e) => handleInputChange(e, t20List[1]._id, t20List[1].Catogary, t20List[1].match_type)} value={inputData?._id === t20List[1]?._id ? inputData?.FourWicketBonus : t20List[1]?.FourWicketBonus}
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <div class="form-group row align-items-center">
                                                                    <div class="col-8" >
                                                                        <label class="small  me-2">5 wicket Bonus:</label>
                                                                    </div>
                                                                    <div class="col-4" >
                                                                        <input class="form-control check  form-control check-solid" type='number' types={t20List[1]?.FiveWicket}
                                                                            name='FiveWicket'
                                                                            onWheel={(e) => { e.target.blur() }} onChange={(e) => handleInputChange(e, t20List[1]._id, t20List[1].Catogary, t20List[1].match_type)} value={inputData?._id === t20List[1]?._id ? inputData?.FiveWicket : t20List[1]?.FiveWicket} />
                                                                    </div>
                                                                </div>
                                                                <div class="form-group row align-items-center">
                                                                    <div class="col-8" >
                                                                        <label class="small  me-2">Maiden over:</label>
                                                                    </div>
                                                                    <div class="col-4" >
                                                                        <input class="form-control check  form-control check-solid" type='number' types={t20List[1]?.Maiden}
                                                                            name='Maiden'
                                                                            onWheel={(e) => { e.target.blur() }} onChange={(e) => handleInputChange(e, t20List[1]._id, t20List[1].Catogary, t20List[1].match_type)} value={inputData?._id === t20List[1]?._id ? inputData?.Maiden : t20List[1]?.Maiden} />
                                                                    </div>
                                                                </div>
                                                                <div class="form-group row align-items-center">
                                                                    <div class="col-8" >
                                                                        <label class="small  me-2">3 Wicket Bonus:</label>
                                                                    </div>
                                                                    <div class="col-4" >
                                                                        <input class="form-control check  form-control check-solid" type='number' types={t20List[1]?.ThreeWicket}
                                                                            name='ThreeWicket'
                                                                            onWheel={(e) => { e.target.blur() }} onChange={(e) => handleInputChange(e, t20List[1]._id, t20List[1].Catogary, t20List[1].match_type)} value={inputData?._id === t20List[1]?._id ? inputData?.ThreeWicket : t20List[1]?.ThreeWicket} />
                                                                    </div>
                                                                </div>
                                                                <div class="form-group row align-items-center">
                                                                    <div class="col-8" >
                                                                        <label class="small  me-2">Bonus (LBW / Bowled):</label>
                                                                    </div>
                                                                    <div class="col-4" >
                                                                        <input class="form-control check  form-control check-solid" type='number' types={t20List[1]?.lbwbonus}
                                                                            name='lbwbonus'
                                                                            onWheel={(e) => { e.target.blur() }} onChange={(e) => handleInputChange(e, t20List[1]._id, t20List[1].Catogary, t20List[1].match_type)} value={inputData?._id === t20List[1]?._id ? inputData?.lbwbonus : t20List[1]?.lbwbonus} />
                                                                    </div>
                                                                </div>
                                                                <button class="btn btn-primary   btn-block w-100 mt-2" type="button" onClick={() => EditPoints(inputData)} >Update</button>
                                                            </form> : ''}
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-xl-4">
                                                <div class="card card_border h-100">
                                                    <div class="card-body d-flex justify-content-start flex-column pt-5 ">
                                                        <div class="d-flex align-items-center justify-content-start mb-4 ">
                                                            <div class="me-3"><h4 class="mb-0 "><strong>Fielding</strong></h4></div>
                                                        </div>
                                                        {t20List ?
                                                            <form>
                                                                <div class="form-group row align-items-center">
                                                                    <div class="col-8" >
                                                                        <label class="small  me-2">Catch: </label>
                                                                    </div>
                                                                    <div class="col-4" >
                                                                        <input class="form-control check  form-control check-solid" type='number' types={t20List[2]?.catch}
                                                                            name='catch'
                                                                            onWheel={(e) => { e.target.blur() }} onChange={(e) => handleInputChange(e, t20List[2]._id, t20List[2].Catogary, t20List[2].match_type)} value={inputData?._id === t20List[2]?._id ? inputData?.catch : t20List[2]?.catch}
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <div class="form-group row align-items-center">
                                                                    <div class="col-8" >
                                                                        <label class="small  me-2">Run out (Not a direct hit):</label>
                                                                    </div>
                                                                    <div class="col-4" >
                                                                        <input class="form-control check  form-control check-solid" type='number' types={t20List[2]?.RunOut_catch}
                                                                            name='RunOut_catch'
                                                                            onWheel={(e) => { e.target.blur() }} onChange={(e) => handleInputChange(e, t20List[2]._id, t20List[2].Catogary, t20List[2].match_type)} value={inputData?._id === t20List[2]?._id ? inputData?.RunOut_catch : t20List[2]?.RunOut_catch} />
                                                                    </div>
                                                                </div>
                                                                <div class="form-group row align-items-center">
                                                                    <div class="col-8" >
                                                                        <label class="small  me-2">Run out (Direct hit):</label>
                                                                    </div>
                                                                    <div class="col-4" >
                                                                        <input class="form-control check  form-control check-solid" type='number' types={t20List[2]?.RunOut_throw}
                                                                            name='RunOut_throw'
                                                                            onWheel={(e) => { e.target.blur() }} onChange={(e) => handleInputChange(e, t20List[2]._id, t20List[2].Catogary, t20List[2].match_type)} value={inputData?._id === t20List[2]?._id ? inputData?.RunOut_throw : t20List[2]?.RunOut_throw} />
                                                                    </div>
                                                                </div>
                                                                <div class="form-group row align-items-center">
                                                                    <div class="col-8" >
                                                                        <label class="small  me-2">3 Catch Bonus:</label>
                                                                    </div>
                                                                    <div class="col-4" >
                                                                        <input class="form-control check  form-control check-solid" type='number' types={t20List[2]?.morethenthreecatch}
                                                                            name='morethenthreecatch'
                                                                            onWheel={(e) => { e.target.blur() }} onChange={(e) => handleInputChange(e, t20List[2]._id, t20List[2].Catogary, t20List[2].match_type)} value={inputData?._id === t20List[2]?._id ? inputData?.morethenthreecatch : t20List[2]?.morethenthreecatch} />
                                                                    </div>
                                                                </div>
                                                                <div class="form-group row align-items-center">
                                                                    <div class="col-8" >
                                                                        <label class="small  me-2">Stumping:</label>
                                                                    </div>
                                                                    <div class="col-4" >
                                                                        <input class="form-control check  form-control check-solid" type='number' types={t20List[2]?.stumping}
                                                                            name='stumping'
                                                                            onWheel={(e) => { e.target.blur() }} onChange={(e) => handleInputChange(e, t20List[2]._id, t20List[2].Catogary, t20List[2].match_type)} value={inputData?._id === t20List[2]?._id ? inputData?.stumping : t20List[2]?.stumping} />
                                                                    </div>
                                                                </div>

                                                                <button class="btn btn-primary   btn-block w-100 mt-2" type="button" onClick={() => EditPoints(inputData)} >Update</button>
                                                            </form> : ''}
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-xl-4">
                                                <div class="card card_border h-100">
                                                    <div class="card-body d-flex justify-content-start flex-column pt-5 ">
                                                        <div class="d-flex align-items-center justify-content-start mb-4 ">
                                                            <div class="me-3"><h4 class="mb-0 "><strong>Other</strong></h4></div>
                                                        </div>
                                                        {t20List ?
                                                            <form>
                                                                <div class="form-group row align-items-center">
                                                                    <div class="col-8" >
                                                                        <label class="small  me-2">Captain:</label>
                                                                    </div>
                                                                    <div class="col-4" >
                                                                        <input class="form-control check  form-control check-solid" type='number' types={t20List[3]?.Captain}
                                                                            name='Captain'
                                                                            onWheel={(e) => { e.target.blur() }} onChange={(e) => handleInputChange(e, t20List[3]._id, t20List[3].Catogary, t20List[3].match_type)} value={inputData?._id === t20List[3]?._id ? inputData?.Captain : t20List[3]?.Captain} />
                                                                    </div>
                                                                </div>
                                                                <div class="form-group row align-items-center">
                                                                    <div class="col-8" >
                                                                        <label class="small  me-2">Vice-Captain:</label>
                                                                    </div>
                                                                    <div class="col-4" >
                                                                        <input class="form-control check  form-control check-solid" type='number' types={t20List[3]?.Vice_Captain}
                                                                            name='Vice_Captain'
                                                                            onWheel={(e) => { e.target.blur() }} onChange={(e) => handleInputChange(e, t20List[3]._id, t20List[3].Catogary, t20List[3].match_type)} value={inputData?._id === t20List[3]?._id ? inputData?.Vice_Captain : t20List[3]?.Vice_Captain} />
                                                                    </div>
                                                                </div>
                                                                <div class="form-group row align-items-center">
                                                                    <div class="col-8" >
                                                                        <label class="small  me-2">In announced lineups:</label>
                                                                    </div>
                                                                    <div class="col-4" >
                                                                        <input class="form-control check  form-control check-solid" type='number' types={t20List[3]?.Starting11}
                                                                            name='Starting11'
                                                                            onWheel={(e) => { e.target.blur() }} onChange={(e) => handleInputChange(e, t20List[3]._id, t20List[3].Catogary, t20List[3].match_type)} value={inputData?._id === t20List[3]?._id ? inputData?.Starting11 : t20List[3]?.Starting11} />
                                                                    </div>
                                                                </div>
                                                                <div class="form-group row align-items-center">
                                                                    <div class="col-8" >
                                                                        <label class="small  me-2">Playing Substitue <br />
                                                                            <small>(Concussion, COVID-19, X-Factor, or Impact Player)</small>:</label>
                                                                    </div>
                                                                    <div class="col-4" >
                                                                        <input class="form-control check  form-control check-solid" type='number' types={t20List[3]?.playingsubstitue}
                                                                            name='playingsubstitue'
                                                                            onWheel={(e) => { e.target.blur() }} onChange={(e) => handleInputChange(e, t20List[3]._id, t20List[3].Catogary, t20List[3].match_type)} value={inputData?._id === t20List[3]?._id ? inputData?.playingsubstitue : t20List[3]?.playingsubstitue} />
                                                                    </div>
                                                                </div>
                                                                <button class="btn btn-primary   btn-block w-100 mt-2" type="button" onClick={() => EditPoints(inputData)} >Update</button>
                                                            </form> : ''}
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-xl-4">
                                                <div class="card card_border h-100">
                                                    <div class="card-body d-flex justify-content-start flex-column pt-5 ">
                                                        <div class="d-flex align-items-center justify-content-start mb-4 ">
                                                            <div class="me-3"><h4 class="mb-0 "><strong> Economy Rate (<small>Min 2 Over To Be Bowled</small>)</strong></h4></div>
                                                        </div>
                                                        {t20List ?
                                                            <form>
                                                                <div class="form-group row align-items-center">
                                                                    <div class="col-8" >
                                                                        <label class="small  me-2">Below 5 runs per over:</label>
                                                                    </div>
                                                                    <div class="col-4" >
                                                                        <input class="form-control check  form-control check-solid" type='number' types={t20List[4]?.FiveRuns}
                                                                            name='FiveRuns'
                                                                            onWheel={(e) => { e.target.blur() }} onChange={(e) => handleInputChange(e, t20List[4]._id, t20List[4].Catogary, t20List[4].match_type)} value={inputData?._id === t20List[4]?._id ? inputData?.FiveRuns : t20List[4]?.FiveRuns}
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <div class="form-group row align-items-center">
                                                                    <div class="col-8" >
                                                                        <label class="small  me-2">Between 5-5.99 runs per over:
                                                                        </label>
                                                                    </div>
                                                                    <div class="col-4" >
                                                                        <input class="form-control check  form-control check-solid" type='number' types={t20List[4]?.FveTosixNine}
                                                                            name='FveTosixNine'
                                                                            onWheel={(e) => { e.target.blur() }} onChange={(e) => handleInputChange(e, t20List[4]._id, t20List[4].Catogary, t20List[4].match_type)} value={inputData?._id === t20List[4]?._id ? inputData?.FveTosixNine : t20List[4]?.FveTosixNine} />
                                                                    </div>
                                                                </div>
                                                                <div class="form-group row align-items-center">
                                                                    <div class="col-8" >
                                                                        <label class="small  me-2">Between 6-7 runs per over:
                                                                        </label>
                                                                    </div>
                                                                    <div class="col-4" >
                                                                        <input class="form-control check  form-control check-solid" type='number' types={t20List[4]?.sixto_seven}
                                                                            name='sixto_seven'
                                                                            onWheel={(e) => { e.target.blur() }} onChange={(e) => handleInputChange(e, t20List[4]._id, t20List[4].Catogary, t20List[4].match_type)} value={inputData?._id === t20List[4]?._id ? inputData?.sixto_seven : t20List[4]?.sixto_seven} />
                                                                    </div>
                                                                </div>
                                                                <div class="form-group row align-items-center">
                                                                    <div class="col-8" >
                                                                        <label class="small  me-2">Between 10-11 runs per over:
                                                                        </label>
                                                                    </div>
                                                                    <div class="col-4" >
                                                                        <input class="form-control check  form-control check-solid" type='number' types={t20List[4]?.TentoEleven}
                                                                            name='TentoEleven'
                                                                            onWheel={(e) => { e.target.blur() }} onChange={(e) => handleInputChange(e, t20List[4]._id, t20List[4].Catogary, t20List[4].match_type)} value={inputData?._id === t20List[4]?._id ? inputData?.TentoEleven : t20List[4]?.TentoEleven} />
                                                                    </div>
                                                                </div>
                                                                <div class="form-group row align-items-center">
                                                                    <div class="col-8" >
                                                                        <label class="small  me-2">Between 11-12 runs per over:
                                                                        </label>
                                                                    </div>
                                                                    <div class="col-4" >
                                                                        <input class="form-control check  form-control check-solid" type='number' types={t20List[4]?.Eleventotwelve}
                                                                            name='Eleventotwelve'
                                                                            onWheel={(e) => { e.target.blur() }} onChange={(e) => handleInputChange(e, t20List[4]._id, t20List[4].Catogary, t20List[4].match_type)} value={inputData?._id === t20List[4]?._id ? inputData?.Eleventotwelve : t20List[4]?.Eleventotwelve} />
                                                                    </div>
                                                                </div>
                                                                <div class="form-group row align-items-center">
                                                                    <div class="col-8" >
                                                                        <label class="small  me-2">Above 12 runs per over:
                                                                        </label>
                                                                    </div>
                                                                    <div class="col-4" >
                                                                        <input class="form-control check  form-control check-solid" type='number' types={t20List[4]?.Abovetwelve}
                                                                            name='Abovetwelve'
                                                                            onWheel={(e) => { e.target.blur() }} onChange={(e) => handleInputChange(e, t20List[4]._id, t20List[4].Catogary, t20List[4].match_type)} value={inputData?._id === t20List[4]?._id ? inputData?.Abovetwelve : t20List[4]?.Abovetwelve} />
                                                                    </div>
                                                                </div>
                                                                <button class="btn btn-primary   btn-block w-100 mt-2" type="button" onClick={() => EditPoints(inputData)} >Update</button>
                                                            </form> : ""}
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-xl-4">
                                                <div class="card card_border h-100">
                                                    <div class="card-body d-flex justify-content-start flex-column pt-5 ">
                                                        <div class="d-flex align-items-center justify-content-start mb-4 ">
                                                            <div class="me-3"><h4 class="mb-0 "><strong> Strike Rate (Except Bowlers) <br /> (<small>Min 10 Balls To Be Played</small>)</strong></h4></div>
                                                        </div>
                                                        {t20List ?
                                                            <form>
                                                                <div class="form-group row align-items-center">
                                                                    <div class="col-8" >
                                                                        <label class="small  me-2">Below 50 runs per 100 balls:</label>
                                                                    </div>
                                                                    <div class="col-4" >
                                                                        <input class="form-control check  form-control check-solid" type='number' types={t20List[5]?.Belowfifty} name='Belowfifty'
                                                                            onWheel={(e) => { e.target.blur() }} onChange={(e) => handleInputChange(e, t20List[5]._id, t20List[5].Catogary, t20List[5].match_type)} value={inputData?._id === t20List[5]?._id ? inputData?.Belowfifty : t20List[5]?.Belowfifty} />
                                                                    </div>
                                                                </div>
                                                                {/* <div class="form-group row align-items-center">
                                                                    <div class="col-8" >
                                                                        <label class="small  me-2">Between 50-59 runs per 100 balls:
                                                                        </label>
                                                                    </div>
                                                                    <div class="col-4" >
                                                                        <input class="form-control check  form-control check-solid" type='number' types={t20List[5]?.fiftyTofiftyNine} name='fiftyTofiftyNine'
                                                                            onWheel={(e) => { e.target.blur() }} onChange={(e) => handleInputChange(e, t20List[5]._id, t20List[5].Catogary, t20List[5].match_type)} value={inputData?._id === t20List[5]?._id ? inputData?.fiftyTofiftyNine : t20List[5]?.fiftyTofiftyNine} />
                                                                    </div>
                                                                </div> */}
                                                                <div class="form-group row align-items-center">
                                                                    <div class="col-8" >
                                                                        <label class="small  me-2">Between 50-59 runs per 100 balls:
                                                                        </label>
                                                                    </div>
                                                                    <div class="col-4" >
                                                                        <input class="form-control check  form-control check-solid" type='number' types={t20List[5]?.fiftyTofiftyNine} name='fiftyTofiftyNine'
                                                                            onWheel={(e) => { e.target.blur() }} onChange={(e) => handleInputChange(e, t20List[5]._id, t20List[5].Catogary, t20List[5].match_type)} value={inputData?._id === t20List[5]?._id ? inputData?.fiftyTofiftyNine : t20List[5]?.fiftyTofiftyNine} />
                                                                    </div>
                                                                </div>
                                                                <div class="form-group row align-items-center">
                                                                    <div class="col-8" >
                                                                        <label class="small  me-2">Between 60-70 runs per 100 balls:
                                                                        </label>
                                                                    </div>
                                                                    <div class="col-4" >
                                                                        <input class="form-control check  form-control check-solid" type='number' types={t20List[5]?.sixty_to_seventy} name='sixty_to_seventy'
                                                                            onWheel={(e) => { e.target.blur() }} onChange={(e) => handleInputChange(e, t20List[5]._id, t20List[5].Catogary, t20List[5].match_type)} value={inputData?._id === t20List[5]?._id ? inputData?.sixty_to_seventy : t20List[5]?.sixty_to_seventy} />
                                                                    </div>
                                                                </div>
                                                                <div class="form-group row align-items-center">
                                                                    <div class="col-8" >
                                                                        <label class="small  me-2">Between 130-150 runs per 100 balls:
                                                                        </label>
                                                                    </div>
                                                                    <div class="col-4" >
                                                                        <input class="form-control check  form-control check-solid" type='number' types={t20List[5]?.onethirty_to_one_fifty} name='onethirty_to_one_fifty'
                                                                            onWheel={(e) => { e.target.blur() }} onChange={(e) => handleInputChange(e, t20List[5]._id, t20List[5].Catogary, t20List[5].match_type)} value={inputData?._id === t20List[5]?._id ? inputData?.onethirty_to_one_fifty : t20List[5]?.onethirty_to_one_fifty} />
                                                                    </div>
                                                                </div>
                                                                <div class="form-group row align-items-center">
                                                                    <div class="col-8" >
                                                                        <label class="small  me-2">Between 150-170 runs per 100 balls:
                                                                        </label>
                                                                    </div>
                                                                    <div class="col-4" >
                                                                        <input class="form-control check  form-control check-solid" type='number' types={t20List[5]?.onefiftyto_one_seventy} name='onefiftyto_one_seventy'
                                                                            onWheel={(e) => { e.target.blur() }} onChange={(e) => handleInputChange(e, t20List[5]._id, t20List[5].Catogary, t20List[5].match_type)} value={inputData?._id === t20List[5]?._id ? inputData?.onefiftyto_one_seventy : t20List[5]?.onefiftyto_one_seventy} />
                                                                    </div>
                                                                </div>
                                                                <div class="form-group row align-items-center">
                                                                    <div class="col-8" >
                                                                        <label class="small  me-2"> Above 170 runs per 100 balls:
                                                                        </label>
                                                                    </div>
                                                                    <div class="col-4" >
                                                                        <input class="form-control check  form-control check-solid" type='number' types={t20List[5]?.Aboveone_sventy} name='Aboveone_sventy'
                                                                            onWheel={(e) => { e.target.blur() }} onChange={(e) => handleInputChange(e, t20List[5]._id, t20List[5].Catogary, t20List[5].match_type)} value={inputData?._id === t20List[5]?._id ? inputData?.Aboveone_sventy : t20List[5]?.Aboveone_sventy} />
                                                                    </div>
                                                                </div>
                                                                <button class="btn btn-primary   btn-block w-100 mt-2" type="button" onClick={() => EditPoints(inputData)} >Update</button>
                                                            </form> : ''}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>


                                    {/* ODI Series.......... */}

                                    <div class="tab-pane" id="ODI" role="tabpanel" aria-labelledby="ODI-tab">

                                        <div class="row g-3">
                                            <div class="col-xl-4">
                                                <div class="card card_border h-100">
                                                    <div class="card-body d-flex justify-content-start flex-column pt-5 ">
                                                        <div class="d-flex align-items-center justify-content-start mb-4 ">
                                                            <div class="me-3"><h4 class="mb-0 "><strong>Batting</strong></h4></div>
                                                        </div>
                                                        {odiList ?
                                                            <form>
                                                                <div class="form-group row align-items-center">
                                                                    <div class="col-8" >
                                                                        <label class="small  me-2">Run: </label>
                                                                    </div>
                                                                    <div class="col-4" >
                                                                        <input class="form-control check  form-control check-solid" type='number' types={odiList[0]?.Run}
                                                                            name='Run'
                                                                            onWheel={(e) => { e.target.blur() }} onChange={(e) => handleInputChange(e, odiList[0]._id, odiList[0].Catogary, odiList[0].match_type)} value={inputData?._id === odiList[0]?._id ? inputData?.Run : odiList[0]?.Run}
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <div class="form-group row align-items-center">
                                                                    <div class="col-8" >
                                                                        <label class="small  me-2">Boundary Bonus:</label>
                                                                    </div>
                                                                    <div class="col-4" >
                                                                        <input class="form-control check  form-control check-solid" type='number' types={odiList[0]?.Boundary_Bonus}
                                                                            name='Boundary_Bonus'
                                                                            onWheel={(e) => { e.target.blur() }} onChange={(e) => handleInputChange(e, odiList[0]._id, odiList[0].Catogary, odiList[0].match_type)} value={inputData?._id === odiList[0]?._id ? inputData?.Boundary_Bonus : odiList[0]?.Boundary_Bonus} />
                                                                    </div>
                                                                </div>
                                                                <div class="form-group row align-items-center">
                                                                    <div class="col-8" >
                                                                        <label class="small  me-2">Six Bonus:</label>
                                                                    </div>
                                                                    <div class="col-4" >
                                                                        <input class="form-control check  form-control check-solid" type='number' types={odiList[0]?.Six_Bonus}
                                                                            name='Six_Bonus'
                                                                            onWheel={(e) => { e.target.blur() }} onChange={(e) => handleInputChange(e, odiList[0]._id, odiList[0].Catogary, odiList[0].match_type)} value={inputData?._id === odiList[0]?._id ? inputData?.Six_Bonus : odiList[0]?.Six_Bonus} />
                                                                    </div>
                                                                </div>
                                                                <div class="form-group row align-items-center">
                                                                    <div class="col-8" >
                                                                        <label class="small  me-2">Half-century Bonus:
                                                                        </label>
                                                                    </div>
                                                                    <div class="col-4" >
                                                                        <input class="form-control check  form-control check-solid" type='number' types={odiList[0]?.half_century_Bonus}
                                                                            name='half_century_Bonus'
                                                                            onWheel={(e) => { e.target.blur() }} onChange={(e) => handleInputChange(e, odiList[0]._id, odiList[0].Catogary, odiList[0].match_type)} value={inputData?._id === odiList[0]?._id ? inputData?.half_century_Bonus : odiList[0]?.half_century_Bonus} />
                                                                    </div>
                                                                </div>
                                                                <div class="form-group row align-items-center">
                                                                    <div class="col-8" >
                                                                        <label class="small  me-2">Century Bonus:
                                                                        </label>
                                                                    </div>
                                                                    <div class="col-4" >
                                                                        <input class="form-control check  form-control check-solid" type='number' types={odiList[0]?.Century_Bonus}
                                                                            name='Century_Bonus'
                                                                            onWheel={(e) => { e.target.blur() }} onChange={(e) => handleInputChange(e, odiList[0]._id, odiList[0].Catogary, odiList[0].match_type)} value={inputData?._id === odiList[0]?._id ? inputData?.Century_Bonus : odiList[0]?.Century_Bonus} />
                                                                    </div>
                                                                </div>
                                                                <div class="form-group row align-items-center">
                                                                    <div class="col-8" >
                                                                        <label class="small  me-2">Dismissal for a duck: <br /> <small>Batsman, Wicket-Keeper & All-Rounder</small></label>
                                                                    </div>
                                                                    <div class="col-4" >
                                                                        <input class="form-control check  form-control check-solid" type='number' types={odiList[0]?.Dismissal_on_Duck}
                                                                            name='Dismissal_on_Duck'
                                                                            onWheel={(e) => { e.target.blur() }} onChange={(e) => handleInputChange(e, odiList[0]._id, odiList[0].Catogary, odiList[0].match_type)} value={inputData?._id === odiList[0]?._id ? inputData?.Dismissal_on_Duck : odiList[0]?.Dismissal_on_Duck} />
                                                                    </div>
                                                                </div>
                                                                <button class="btn btn-primary   btn-block w-100 mt-2" type="button" onClick={() => EditPoints(inputData)} >Update</button>
                                                            </form> : ''}
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-xl-4">
                                                <div class="card card_border h-100">
                                                    <div class="card-body d-flex justify-content-start flex-column pt-5 ">
                                                        <div class="d-flex align-items-center justify-content-start mb-4 ">
                                                            <div class="me-3"><h4 class="mb-0 "><strong>Bowling</strong></h4></div>
                                                        </div>
                                                        {odiList ?
                                                            <form>
                                                                <div class="form-group row align-items-center">
                                                                    <div class="col-8" >
                                                                        <label class="small  me-2">Wicket: <small>Excluding Run Out</small> </label>
                                                                    </div>
                                                                    <div class="col-4" >
                                                                        <input class="form-control check  form-control check-solid" type='number' types={odiList[1]?.Wicket}
                                                                            name='Wicket'
                                                                            onWheel={(e) => { e.target.blur() }} onChange={(e) => handleInputChange(e, odiList[1]._id, odiList[1].Catogary, odiList[1].match_type)} value={inputData?._id === odiList[1]?._id ? inputData?.Wicket : odiList[1]?.Wicket} />
                                                                    </div>
                                                                </div>
                                                                <div class="form-group row align-items-center">
                                                                    <div class="col-8" >
                                                                        <label class="small  me-2">4 wicket Bonus:</label>
                                                                    </div>
                                                                    <div class="col-4" >
                                                                        <input class="form-control check  form-control check-solid" type='number' types={odiList[1]?.FourWicketBonus}
                                                                            name='FourWicketBonus'
                                                                            onWheel={(e) => { e.target.blur() }} onChange={(e) => handleInputChange(e, odiList[1]._id, odiList[1].Catogary, odiList[1].match_type)} value={inputData?._id === odiList[1]?._id ? inputData?.FourWicketBonus : odiList[1]?.FourWicketBonus}
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <div class="form-group row align-items-center">
                                                                    <div class="col-8" >
                                                                        <label class="small  me-2">5 wicket Bonus:</label>
                                                                    </div>
                                                                    <div class="col-4" >
                                                                        <input class="form-control check  form-control check-solid" type='number' types={odiList[1]?.FiveWicket}
                                                                            name='FiveWicket'
                                                                            onWheel={(e) => { e.target.blur() }} onChange={(e) => handleInputChange(e, odiList[1]._id, odiList[1].Catogary, odiList[1].match_type)} value={inputData?._id === odiList[1]?._id ? inputData?.FiveWicket : odiList[1]?.FiveWicket} />
                                                                    </div>
                                                                </div>
                                                                <div class="form-group row align-items-center">
                                                                    <div class="col-8" >
                                                                        <label class="small  me-2">3 wicket Bonus:</label>
                                                                    </div>
                                                                    <div class="col-4" >
                                                                        <input class="form-control check  form-control check-solid" type='number' types={odiList[1]?.ThreeWicket}
                                                                            name='ThreeWicket'
                                                                            onWheel={(e) => { e.target.blur() }} onChange={(e) => handleInputChange(e, odiList[1]._id, odiList[1].Catogary, odiList[1].match_type)} value={inputData?._id === odiList[1]?._id ? inputData?.ThreeWicket : odiList[1]?.ThreeWicket} />
                                                                    </div>
                                                                </div>
                                                                <div class="form-group row align-items-center">
                                                                    <div class="col-8" >
                                                                        <label class="small  me-2">Maiden over:</label>
                                                                    </div>
                                                                    <div class="col-4" >
                                                                        <input class="form-control check  form-control check-solid" type='number' types={odiList[1]?.Maiden}
                                                                            name='Maiden'
                                                                            onWheel={(e) => { e.target.blur() }} onChange={(e) => handleInputChange(e, odiList[1]._id, odiList[1].Catogary, odiList[1].match_type)} value={inputData?._id === odiList[1]?._id ? inputData?.Maiden : odiList[1]?.Maiden} />
                                                                    </div>
                                                                </div>
                                                                <div class="form-group row align-items-center">
                                                                    <div class="col-8" >
                                                                        <label class="small  me-2">Bonus (LBW / Bowled):</label>
                                                                    </div>
                                                                    <div class="col-4" >
                                                                        <input class="form-control check  form-control check-solid" type='number' types={odiList[1]?.lbwbonus}
                                                                            name='lbwbonus'
                                                                            onWheel={(e) => { e.target.blur() }} onChange={(e) => handleInputChange(e, odiList[1]._id, odiList[1].Catogary, odiList[1].match_type)} value={inputData?._id === odiList[1]?._id ? inputData?.lbwbonus : odiList[1]?.lbwbonus} />
                                                                    </div>
                                                                </div>
                                                                <button class="btn btn-primary   btn-block w-100 mt-2" type="button" onClick={() => EditPoints(inputData)} >Update</button>
                                                            </form> : ''}
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-xl-4">
                                                <div class="card card_border h-100">
                                                    <div class="card-body d-flex justify-content-start flex-column pt-5 ">
                                                        <div class="d-flex align-items-center justify-content-start mb-4 ">
                                                            <div class="me-3"><h4 class="mb-0 "><strong>Fielding</strong></h4></div>
                                                        </div>
                                                        {odiList ?
                                                            <form>
                                                                <div class="form-group row align-items-center">
                                                                    <div class="col-8" >
                                                                        <label class="small  me-2">Catch: </label>
                                                                    </div>
                                                                    <div class="col-4" >
                                                                        <input class="form-control check  form-control check-solid" type='number' types={odiList[2]?.catch}
                                                                            name='catch'
                                                                            onWheel={(e) => { e.target.blur() }} onChange={(e) => handleInputChange(e, odiList[2]._id, odiList[2].Catogary, odiList[2].match_type)} value={inputData?._id === odiList[2]?._id ? inputData?.catch : odiList[2]?.catch} />
                                                                    </div>
                                                                </div>
                                                                <div class="form-group row align-items-center">
                                                                    <div class="col-8" >
                                                                        <label class="small  me-2">Stumping:
                                                                        </label>
                                                                    </div>
                                                                    <div class="col-4" >
                                                                        <input class="form-control check  form-control check-solid" type='number'
                                                                            name='stumping'
                                                                            onWheel={(e) => { e.target.blur() }} onChange={(e) => handleInputChange(e, odiList[2]._id, odiList[2].Catogary, odiList[2].match_type)} value={inputData?._id === odiList[2]?._id ? inputData?.stumping : odiList[2]?.stumping} />
                                                                    </div>
                                                                </div>
                                                                <div class="form-group row align-items-center">
                                                                    <div class="col-8" >
                                                                        <label class="small  me-2">Run out (Not a direct hit):</label>
                                                                    </div>
                                                                    <div class="col-4" >
                                                                        <input class="form-control check  form-control check-solid" type='number' types={odiList[2]?.RunOut_catch} name='RunOut_catch'
                                                                            onWheel={(e) => { e.target.blur() }} onChange={(e) => handleInputChange(e, odiList[2]._id, odiList[2].Catogary, odiList[2].match_type)} value={inputData?._id === odiList[2]?._id ? inputData?.RunOut_catch : odiList[2]?.RunOut_catch} />
                                                                    </div>
                                                                </div>
                                                                <div class="form-group row align-items-center">
                                                                    <div class="col-8" >
                                                                        <label class="small  me-2">Run out (Direct hit):</label>
                                                                    </div>
                                                                    <div class="col-4" >
                                                                        <input class="form-control check  form-control check-solid" type='number' types={odiList[2]?.RunOut_throw} name='RunOut_throw'
                                                                            onWheel={(e) => { e.target.blur() }} onChange={(e) => handleInputChange(e, odiList[2]._id, odiList[2].Catogary, odiList[2].match_type)} value={inputData?._id === odiList[2]?._id ? inputData?.RunOut_throw : odiList[2]?.RunOut_throw} />
                                                                    </div>
                                                                </div>
                                                                <div class="form-group row align-items-center">
                                                                    <div class="col-8" >
                                                                        <label class="small  me-2">3 Catch Bonus:</label>
                                                                    </div>
                                                                    <div class="col-4" >
                                                                        <input class="form-control check  form-control check-solid" type='number' types={odiList[2]?.morethenthreecatch} name='morethenthreecatch'
                                                                            onWheel={(e) => { e.target.blur() }} onChange={(e) => handleInputChange(e, odiList[2]._id, odiList[2].Catogary, odiList[2].match_type)} value={inputData?._id === odiList[2]?._id ? inputData?.morethenthreecatch : odiList[2]?.morethenthreecatch} />
                                                                    </div>
                                                                </div>

                                                                <button class="btn btn-primary   btn-block w-100 mt-2" type="button" onClick={() => EditPoints(inputData)} >Update</button>
                                                            </form> : ''}
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-xl-4">
                                                <div class="card card_border h-100">
                                                    <div class="card-body d-flex justify-content-start flex-column pt-5 ">
                                                        <div class="d-flex align-items-center justify-content-start mb-4 ">
                                                            <div class="me-3"><h4 class="mb-0 "><strong>Other</strong></h4></div>
                                                        </div>
                                                        {odiList ?
                                                            <form>
                                                                <div class="form-group row align-items-center">
                                                                    <div class="col-8" >
                                                                        <label class="small  me-2">Captain:</label>
                                                                    </div>
                                                                    <div class="col-4" >
                                                                        <input class="form-control check  form-control check-solid" type='number' types={odiList[3]?.Captain}
                                                                            name='Captain'
                                                                            onWheel={(e) => { e.target.blur() }} onChange={(e) => handleInputChange(e, odiList[3]._id, odiList[3].Catogary, odiList[3].match_type)} value={inputData?._id === odiList[3]?._id ? inputData?.Captain : odiList[3]?.Captain} />
                                                                    </div>
                                                                </div>
                                                                <div class="form-group row align-items-center">
                                                                    <div class="col-8" >
                                                                        <label class="small  me-2">Vice-Captain:</label>
                                                                    </div>
                                                                    <div class="col-4" >
                                                                        <input class="form-control check  form-control check-solid" type='number' types={odiList[3]?.Vice_Captain}
                                                                            name='Vice_Captain'
                                                                            onWheel={(e) => { e.target.blur() }} onChange={(e) => handleInputChange(e, odiList[3]._id, odiList[3].Catogary, odiList[3].match_type)} value={inputData?._id === odiList[3]?._id ? inputData?.Vice_Captain : odiList[3]?.Vice_Captain} />
                                                                    </div>
                                                                </div>
                                                                <div class="form-group row align-items-center">
                                                                    <div class="col-8" >
                                                                        <label class="small  me-2">In announced lineups:</label>
                                                                    </div>
                                                                    <div class="col-4" >
                                                                        <input class="form-control check  form-control check-solid" type='number' types={odiList[3]?.Starting11}
                                                                            name='Starting11'
                                                                            onWheel={(e) => { e.target.blur() }} onChange={(e) => handleInputChange(e, odiList[3]._id, odiList[3].Catogary, odiList[3].match_type)} value={inputData?._id === odiList[3]?._id ? inputData?.Starting11 : odiList[3]?.Starting11}
                                                                        />

                                                                    </div>
                                                                </div>
                                                                <div class="form-group row align-items-center">
                                                                    <div class="col-8" >
                                                                        <label class="small  me-2">Playing Substitue <br />
                                                                            <small>(Concussion, COVID-19, X-Factor, or Impact Player)</small>:</label>
                                                                    </div>
                                                                    <div class="col-4" >
                                                                        <input class="form-control check  form-control check-solid" type='number' types={odiList[3]?.playingsubstitue}
                                                                            name='playingsubstitue'
                                                                            onWheel={(e) => { e.target.blur() }} onChange={(e) => handleInputChange(e, odiList[3]._id, odiList[3].Catogary, odiList[3].match_type)} value={inputData?._id === odiList[3]?._id ? inputData?.playingsubstitue : odiList[3]?.playingsubstitue}
                                                                        />

                                                                    </div>
                                                                </div>
                                                                <button class="btn btn-primary   btn-block w-100 mt-2" type="button" onClick={() => EditPoints(inputData)} >Update</button>
                                                            </form> : ''}
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-xl-4">
                                                <div class="card card_border h-100">
                                                    <div class="card-body d-flex justify-content-start flex-column pt-5 ">
                                                        <div class="d-flex align-items-center justify-content-start mb-4 ">
                                                            <div class="me-3"><h4 class="mb-0 "><strong> Economy Rate (<small>Min 5 Over To Be Bowled</small>)</strong></h4></div>
                                                        </div>
                                                        {odiList ?
                                                            <form>
                                                                <div class="form-group row align-items-center">
                                                                    <div class="col-8" >
                                                                        <label class="small  me-2">Below 2.5 runs per over:
                                                                        </label>
                                                                    </div>
                                                                    <div class="col-4" >
                                                                        <input class="form-control check  form-control check-solid" type='number' types={odiList[4]?.TwoPNTfive}
                                                                            name='TwoPNTfive'
                                                                            onWheel={(e) => { e.target.blur() }} onChange={(e) => handleInputChange(e, odiList[4]._id, odiList[4].Catogary, odiList[4].match_type)} value={inputData?._id === odiList[4]?._id ? inputData?.TwoPNTfive : odiList[4]?.TwoPNTfive} />
                                                                    </div>
                                                                </div>
                                                                <div class="form-group row align-items-center">
                                                                    <div class="col-8" >
                                                                        <label class="small  me-2">Between 2.5 – 3.5 runs per over:

                                                                        </label>
                                                                    </div>
                                                                    <div class="col-4" >
                                                                        <input class="form-control check  form-control check-solid" type='number' types={odiList[4]?.two_pntfive_thre_pntfive}
                                                                            name='two_pntfive_thre_pntfive'
                                                                            onWheel={(e) => { e.target.blur() }} onChange={(e) => handleInputChange(e, odiList[4]._id, odiList[4].Catogary, odiList[4].match_type)} value={inputData?._id === odiList[4]?._id ? inputData?.two_pntfive_thre_pntfive : odiList[4]?.two_pntfive_thre_pntfive} />
                                                                    </div>
                                                                </div>
                                                                <div class="form-group row align-items-center">
                                                                    <div class="col-8" >
                                                                        <label class="small  me-2">Between 3.5-4.5 runs per over:

                                                                        </label>
                                                                    </div>
                                                                    <div class="col-4" >
                                                                        <input class="form-control check  form-control check-solid" type='number' types={odiList[4]?.ThreFiveTOforfive}
                                                                            name='ThreFiveTOforfive'
                                                                            onWheel={(e) => { e.target.blur() }} onChange={(e) => handleInputChange(e, odiList[4]._id, odiList[4].Catogary, odiList[4].match_type)} value={inputData?._id === odiList[4]?._id ? inputData?.ThreFiveTOforfive : odiList[4]?.ThreFiveTOforfive} />
                                                                    </div>
                                                                </div>
                                                                <div class="form-group row align-items-center">
                                                                    <div class="col-8" >
                                                                        <label class="small  me-2">Between 7-8 runs per over:

                                                                        </label>
                                                                    </div>
                                                                    <div class="col-4" >
                                                                        <input class="form-control check  form-control check-solid" type='number' types={odiList[4]?.SevenEight}
                                                                            name='SevenEight'
                                                                            onWheel={(e) => { e.target.blur() }} onChange={(e) => handleInputChange(e, odiList[4]._id, odiList[4].Catogary, odiList[4].match_type)} value={inputData?._id === odiList[4]?._id ? inputData?.SevenEight : odiList[4]?.SevenEight} />
                                                                    </div>
                                                                </div>
                                                                <div class="form-group row align-items-center">
                                                                    <div class="col-8" >
                                                                        <label class="small  me-2">Between 8.01-9 runs per over:

                                                                        </label>
                                                                    </div>
                                                                    <div class="col-4" >
                                                                        <input class="form-control check  form-control check-solid" type='number' types={odiList[4]?.eight_nine}
                                                                            name='eight_nine'
                                                                            onWheel={(e) => { e.target.blur() }} onChange={(e) => handleInputChange(e, odiList[4]._id, odiList[4].Catogary, odiList[4].match_type)} value={inputData?._id === odiList[4]?._id ? inputData?.eight_nine : odiList[4]?.eight_nine} />
                                                                    </div>
                                                                </div>
                                                                <div class="form-group row align-items-center">
                                                                    <div class="col-8" >
                                                                        <label class="small  me-2">Above 9 runs per over:

                                                                        </label>
                                                                    </div>
                                                                    <div class="col-4" >
                                                                        <input class="form-control check  form-control check-solid" type='number' types={odiList[4]?.AboveNine}
                                                                            name='AboveNine'
                                                                            onWheel={(e) => { e.target.blur() }} onChange={(e) => handleInputChange(e, odiList[4]._id, odiList[4].Catogary, odiList[4].match_type)} value={inputData?._id === odiList[4]?._id ? inputData?.AboveNine : odiList[4]?.AboveNine} />
                                                                    </div>
                                                                </div>
                                                                <button class="btn btn-primary   btn-block w-100 mt-2" type="button" onClick={() => EditPoints(inputData)} >Update</button>
                                                            </form> : ""}
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-xl-4">
                                                <div class="card card_border h-100">
                                                    <div class="card-body d-flex justify-content-start flex-column pt-5 ">
                                                        <div class="d-flex align-items-center justify-content-start mb-4 ">
                                                            <div class="me-3"><h4 class="mb-0 "><strong> Strike Rate (Except Bowlers) <br /> (<small>Min 20 Balls</small>)</strong></h4></div>
                                                        </div>
                                                        {odiList ?
                                                            <form>
                                                                <div class="form-group row align-items-center">
                                                                    <div class="col-8" >
                                                                        <label class="small  me-2">Below 30 runs per 100 balls:
                                                                        </label>
                                                                    </div>
                                                                    <div class="col-4" >
                                                                        <input class="form-control check  form-control check-solid" type='number' types={odiList[5]?.belowthirty} name='belowthirty'
                                                                            onWheel={(e) => { e.target.blur() }} onChange={(e) => handleInputChange(e, odiList[5]._id, odiList[5].Catogary, odiList[5].match_type)} value={inputData?._id === odiList[5]?._id ? inputData?.belowthirty : odiList[5]?.belowthirty} />
                                                                    </div>
                                                                </div>
                                                                <div class="form-group row align-items-center">
                                                                    <div class="col-8" >
                                                                        <label class="small  me-2">Between 30-39.99 runs per 100 balls:

                                                                        </label>
                                                                    </div>
                                                                    <div class="col-4" >
                                                                        <input class="form-control check  form-control check-solid" type='number' types={odiList[5]?.thirty_to_thirty_nine
                                                                        }
                                                                            name='thirty_to_thirty_nine'
                                                                            onWheel={(e) => { e.target.blur() }} onChange={(e) => handleInputChange(e, odiList[5]._id, odiList[5].Catogary, odiList[5].match_type)} value={inputData?._id === odiList[5]?._id ? inputData?.thirty_to_thirty_nine : odiList[5]?.thirty_to_thirty_nine} />
                                                                    </div>
                                                                </div>
                                                                <div class="form-group row align-items-center">
                                                                    <div class="col-8" >
                                                                        <label class="small  me-2">Between 40-50 runs per 100 balls:

                                                                        </label>
                                                                    </div>
                                                                    <div class="col-4" >
                                                                        <input class="form-control check  form-control check-solid" type='number' types={odiList[5]?.fourt_fifty}
                                                                            name='fourt_fifty'
                                                                            onWheel={(e) => { e.target.blur() }} onChange={(e) => handleInputChange(e, odiList[5]._id, odiList[5].Catogary, odiList[5].match_type)} value={inputData?._id === odiList[5]?._id ? inputData?.fourt_fifty : odiList[5]?.fourt_fifty} />
                                                                    </div>
                                                                </div>

                                                                <div class="form-group row align-items-center">
                                                                    <div class="col-8" >
                                                                        <label class="small  me-2">Between 100-120 runs per 100 balls:

                                                                        </label>
                                                                    </div>
                                                                    <div class="col-4" >
                                                                        <input class="form-control check  form-control check-solid" type='number' types={odiList[5]?.one_hndrd_to_one_twenty}
                                                                            name='one_hndrd_to_one_twenty'
                                                                            onWheel={(e) => { e.target.blur() }} onChange={(e) => handleInputChange(e, odiList[5]._id, odiList[5].Catogary, odiList[5].match_type)} value={inputData?._id === odiList[5]?._id ? inputData?.one_hndrd_to_one_twenty : odiList[5]?.one_hndrd_to_one_twenty} />
                                                                    </div>
                                                                </div>

                                                                <div class="form-group row align-items-center">
                                                                    <div class="col-8" >
                                                                        <label class="small  me-2">Between 120.01-140 runs per 100 balls:

                                                                        </label>
                                                                    </div>
                                                                    <div class="col-4" >
                                                                        <input class="form-control check  form-control check-solid" type='number' types={odiList[5]?.one_twenty_to_onefourty}
                                                                            name='one_twenty_to_onefourty'
                                                                            onWheel={(e) => { e.target.blur() }} onChange={(e) => handleInputChange(e, odiList[5]._id, odiList[5].Catogary, odiList[5].match_type)} value={inputData?._id === odiList[5]?._id ? inputData?.one_twenty_to_onefourty : odiList[5]?.one_twenty_to_onefourty} />
                                                                    </div>
                                                                </div>
                                                                <div class="form-group row align-items-center">
                                                                    <div class="col-8" >
                                                                        <label class="small  me-2"> Above 140 runs per 100 balls:</label>
                                                                    </div>
                                                                    <div class="col-4" >
                                                                        <input class="form-control check  form-control check-solid" type='number' types={odiList[5]?.above_one_forty}
                                                                            name='above_one_forty' onWheel={(e) => { e.target.blur() }} onChange={(e) => handleInputChange(e, odiList[5]._id, odiList[5].Catogary, odiList[5].match_type)} value={inputData?._id === odiList[5]?._id ? inputData?.above_one_forty : odiList[5]?.above_one_forty} />
                                                                    </div>
                                                                </div>


                                                                <button class="btn btn-primary   btn-block w-100 mt-2" type="button" onClick={() => EditPoints(inputData)} >Update</button>
                                                            </form> : ''}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>


                                    {/* TEST Series.......... */}
                                    <div class="tab-pane" id="Test" role="tabpanel" aria-labelledby="Test-tab">
                                        <div class="row g-3">
                                            <div class="col-xl-4">
                                                <div class="card card_border h-100">
                                                    <div class="card-body d-flex justify-content-start flex-column pt-5 ">
                                                        <div class="d-flex align-items-center justify-content-start mb-4 ">
                                                            <div class="me-3"><h4 class="mb-0 "><strong>Batting</strong></h4></div>
                                                        </div>
                                                        {testList ?
                                                            <form>
                                                                <div class="form-group row align-items-center">
                                                                    <div class="col-8" >
                                                                        <label class="small  me-2">Run: </label>
                                                                    </div>
                                                                    <div class="col-4" >
                                                                        <input class="form-control check  form-control check-solid" type='number' types={testList[0]?.Run}
                                                                            name='Run'
                                                                            onWheel={(e) => { e.target.blur() }} onChange={(e) => handleInputChange(e, testList[0]._id, testList[0].Catogary, testList[0].match_type)} value={inputData?._id === testList[0]?._id ? inputData?.Run : testList[0]?.Run}
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <div class="form-group row align-items-center">
                                                                    <div class="col-8" >
                                                                        <label class="small  me-2">Boundary Bonus:</label>
                                                                    </div>
                                                                    <div class="col-4" >
                                                                        <input class="form-control check  form-control check-solid" type='number' types={testList[0]?.Boundary_Bonus}
                                                                            name='Boundary_Bonus'
                                                                            onWheel={(e) => { e.target.blur() }} onChange={(e) => handleInputChange(e, testList[0]._id, testList[0].Catogary, testList[0].match_type)} value={inputData?._id === testList[0]?._id ? inputData?.Boundary_Bonus : testList[0]?.Boundary_Bonus} />
                                                                    </div>
                                                                </div>
                                                                <div class="form-group row align-items-center">
                                                                    <div class="col-8" >
                                                                        <label class="small  me-2">Six Bonus:</label>
                                                                    </div>
                                                                    <div class="col-4" >
                                                                        <input class="form-control check  form-control check-solid" type='number' types={testList[0]?.Six_Bonus}
                                                                            name='Six_Bonus'
                                                                            onWheel={(e) => { e.target.blur() }} onChange={(e) => handleInputChange(e, testList[0]._id, testList[0].Catogary, testList[0].match_type)} value={inputData?._id === testList[0]?._id ? inputData?.Six_Bonus : testList[0]?.Six_Bonus} />
                                                                    </div>
                                                                </div>
                                                                <div class="form-group row align-items-center">
                                                                    <div class="col-8" >
                                                                        <label class="small  me-2">Half-century Bonus:
                                                                        </label>
                                                                    </div>
                                                                    <div class="col-4" >
                                                                        <input class="form-control check  form-control check-solid" type='number' types={testList[0]?.half_century_Bonus}
                                                                            name='half_century_Bonus'
                                                                            onWheel={(e) => { e.target.blur() }} onChange={(e) => handleInputChange(e, testList[0]._id, testList[0].Catogary, testList[0].match_type)} value={inputData?._id === testList[0]?._id ? inputData?.half_century_Bonus : testList[0]?.half_century_Bonus} />
                                                                    </div>
                                                                </div>
                                                                <div class="form-group row align-items-center">
                                                                    <div class="col-8" >
                                                                        <label class="small  me-2">Century Bonus:
                                                                        </label>
                                                                    </div>
                                                                    <div class="col-4" >
                                                                        <input class="form-control check  form-control check-solid" type='number' types={testList[0]?.Century_Bonus}
                                                                            name='Century_Bonus'
                                                                            onWheel={(e) => { e.target.blur() }} onChange={(e) => handleInputChange(e, testList[0]._id, testList[0].Catogary, testList[0].match_type)} value={inputData?._id === testList[0]?._id ? inputData?.Century_Bonus : testList[0]?.Century_Bonus} />
                                                                    </div>
                                                                </div>
                                                                <div class="form-group row align-items-center">
                                                                    <div class="col-8" >
                                                                        <label class="small  me-2">Dismissal for a duck: <br /> <small>Batsman, Wicket-Keeper & All-Rounder</small></label>
                                                                    </div>
                                                                    <div class="col-4" >
                                                                        <input class="form-control check  form-control check-solid" type='number' types={testList[0]?.Dismissal_on_Duck}
                                                                            name='Dismissal_on_Duck'
                                                                            onWheel={(e) => { e.target.blur() }} onChange={(e) => handleInputChange(e, testList[0]._id, testList[0].Catogary, testList[0].match_type)} value={inputData?._id === testList[0]?._id ? inputData?.Dismissal_on_Duck : testList[0]?.Dismissal_on_Duck} />
                                                                    </div>
                                                                </div>
                                                       
                                                                <button class="btn btn-primary   btn-block w-100 mt-2" type="button" onClick={() => EditPoints(inputData)} >Update</button>
                                                            </form> : ''}
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-xl-4">
                                                <div class="card card_border h-100">
                                                    <div class="card-body d-flex justify-content-start flex-column pt-5 ">
                                                        <div class="d-flex align-items-center justify-content-start mb-4 ">
                                                            <div class="me-3"><h4 class="mb-0 "><strong>Bowling</strong></h4></div>
                                                        </div>
                                                        {testList ?
                                                            <form>
                                                                <div class="form-group row align-items-center">
                                                                    <div class="col-8" >
                                                                        <label class="small  me-2">Wicket: <small>Excluding Run Out</small> </label>
                                                                    </div>
                                                                    <div class="col-4" >
                                                                        <input class="form-control check  form-control check-solid" type='number' types={testList[1]?.Wicket}
                                                                            name='Wicket'
                                                                            onWheel={(e) => { e.target.blur() }} onChange={(e) => handleInputChange(e, testList[1]._id, testList[1].Catogary, testList[1].match_type)} value={inputData?._id === testList[1]?._id ? inputData?.Wicket : testList[1]?.Wicket} />
                                                                    </div>
                                                                </div>
                                                                <div class="form-group row align-items-center">
                                                                    <div class="col-8" >
                                                                        <label class="small  me-2">Bonus: <small>(LBW / Bowled)</small> </label>
                                                                    </div>
                                                                    <div class="col-4" >
                                                                        <input class="form-control check  form-control check-solid" type='number' types={testList[1]?.lbwbonus}
                                                                            name='lbwbonus'
                                                                            onWheel={(e) => { e.target.blur() }} onChange={(e) => handleInputChange(e, testList[1]._id, testList[1].Catogary, testList[1].match_type)} value={inputData?._id === testList[1]?._id ? inputData?.lbwbonus : testList[1]?.lbwbonus} />
                                                                    </div>
                                                                </div>
                                                                {/* <div class="form-group row align-items-center">
                                                                    <div class="col-8" >
                                                                        <label class="small  me-2">3 wicket Bonus:</label>
                                                                    </div>
                                                                    <div class="col-4" >
                                                                        <input class="form-control check  form-control check-solid" type='number' types={testList[1]?.ThreeWicket}
                                                                            name='ThreeWicket'
                                                                            onWheel={(e) => { e.target.blur() }} onChange={(e) => handleInputChange(e, testList[1]._id, testList[1].Catogary, testList[1].match_type)} value={inputData?._id === testList[1]?._id ? inputData?.ThreeWicket : testList[1]?.ThreeWicket} />
                                                                    </div>
                                                                </div> */}
                                                                <div class="form-group row align-items-center">
                                                                    <div class="col-8" >
                                                                        <label class="small  me-2">4 wicket Bonus:</label>
                                                                    </div>
                                                                    <div class="col-4" >
                                                                        <input class="form-control check  form-control check-solid" type='number' types={testList[1]?.FourWicketBonus}
                                                                            name='FourWicketBonus'
                                                                            onWheel={(e) => { e.target.blur() }} onChange={(e) => handleInputChange(e, testList[1]._id, testList[1].Catogary, testList[1].match_type)} value={inputData?._id === testList[1]?._id ? inputData?.FourWicketBonus : testList[1]?.FourWicketBonus} />
                                                                    </div>
                                                                </div>
                                                                <div class="form-group row align-items-center">
                                                                    <div class="col-8" >
                                                                        <label class="small  me-2">5 wicket Bonus:</label>
                                                                    </div>
                                                                    <div class="col-4" >
                                                                        <input class="form-control check  form-control check-solid" type='number' types={testList[1]?.FiveWicket}
                                                                            name='FiveWicket'
                                                                            onWheel={(e) => { e.target.blur() }} onChange={(e) => handleInputChange(e, testList[1]._id, testList[1].Catogary, testList[1].match_type)} value={inputData?._id === testList[1]?._id ? inputData?.FiveWicket : testList[1]?.FiveWicket} />
                                                                    </div>
                                                                </div>
                                                                <button class="btn btn-primary   btn-block w-100 mt-2" type="button" onClick={() => EditPoints(inputData)} >Update</button>
                                                            </form> : ''}
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-xl-4">
                                                <div class="card card_border h-100">
                                                    <div class="card-body d-flex justify-content-start flex-column pt-5 ">
                                                        <div class="d-flex align-items-center justify-content-start mb-4 ">
                                                            <div class="me-3"><h4 class="mb-0 "><strong>Fielding</strong></h4></div>
                                                        </div>
                                                        {testList ?
                                                            <form>
                                                                <div class="form-group row align-items-center">
                                                                    <div class="col-8" >
                                                                        <label class="small  me-2">Catch: </label>
                                                                    </div>
                                                                    <div class="col-4" >
                                                                        <input class="form-control check  form-control check-solid" type='number' types={testList[2]?.catch}
                                                                            name='catch'
                                                                            onWheel={(e) => { e.target.blur() }} onChange={(e) => handleInputChange(e, testList[2]._id, testList[2].Catogary, testList[2].match_type)} value={inputData?._id === testList[2]?._id ? inputData?.catch : testList[2]?.catch} />
                                                                    </div>
                                                                </div>
                                                                <div class="form-group row align-items-center">
                                                                    <div class="col-8" >
                                                                        <label class="small  me-2">Stumping :
                                                                        </label>
                                                                    </div>
                                                                    <div class="col-4" >
                                                                        <input class="form-control check  form-control check-solid" type='number' name='stumping'
                                                                            onWheel={(e) => { e.target.blur() }} onChange={(e) => handleInputChange(e, testList[2]._id, testList[2].Catogary, testList[2].match_type)} value={inputData?._id === testList[2]?._id ? inputData?.stumping : testList[2]?.stumping} />
                                                                    </div>
                                                                </div>
                                                                <div class="form-group row align-items-center">
                                                                    <div class="col-8" >
                                                                        <label class="small  me-2">Run out (Not a direct hit):</label>
                                                                    </div>
                                                                    <div class="col-4" >
                                                                        <input class="form-control check  form-control check-solid" type='number' types={testList[2]?.RunOut_catch}
                                                                            name='RunOut_catch'
                                                                            onWheel={(e) => { e.target.blur() }} onChange={(e) => handleInputChange(e, testList[2]._id, testList[2].Catogary, testList[2].match_type)} value={inputData?._id === testList[2]?._id ? inputData?.RunOut_catch : testList[2]?.RunOut_catch} />
                                                                    </div>
                                                                </div>
                                                                <div class="form-group row align-items-center">
                                                                    <div class="col-8" >
                                                                        <label class="small  me-2">Run out (Direct hit):</label>
                                                                    </div>
                                                                    <div class="col-4" >
                                                                        <input class="form-control check  form-control check-solid" type='number' types={testList[2]?.RunOut_throw}
                                                                            name='RunOut_throw'
                                                                            onWheel={(e) => { e.target.blur() }} onChange={(e) => handleInputChange(e, testList[2]._id, testList[2].Catogary, testList[2].match_type)} value={inputData?._id === testList[2]?._id ? inputData?.RunOut_throw : testList[2]?.RunOut_throw} />
                                                                    </div>
                                                                </div>

                                                                <button class="btn btn-primary   btn-block w-100 mt-2" type="button" onClick={() => EditPoints(inputData)} >Update</button>
                                                            </form> : ''}
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-xl-4">
                                                <div class="card card_border h-100">
                                                    <div class="card-body d-flex justify-content-start flex-column pt-5 ">
                                                        <div class="d-flex align-items-center justify-content-start mb-4 ">
                                                            <div class="me-3"><h4 class="mb-0 "><strong>Other</strong></h4></div>
                                                        </div>
                                                        {testList ?
                                                            <form>
                                                                <div class="form-group row align-items-center">
                                                                    <div class="col-8" >
                                                                        <label class="small  me-2">Captain:</label>
                                                                    </div>
                                                                    <div class="col-4" >
                                                                        <input class="form-control check  form-control check-solid" type='number' types={testList[3]?.Captain}
                                                                            name='Captain'
                                                                            onWheel={(e) => { e.target.blur() }} onChange={(e) => handleInputChange(e, testList[3]._id, testList[3].Catogary, testList[3].match_type)} value={inputData?._id === testList[3]?._id ? inputData?.Captain : testList[3]?.Captain} />
                                                                    </div>
                                                                </div>
                                                                <div class="form-group row align-items-center">
                                                                    <div class="col-8" >
                                                                        <label class="small  me-2">Vice-Captain:</label>
                                                                    </div>
                                                                    <div class="col-4" >
                                                                        <input class="form-control check  form-control check-solid" type='number' types={testList[3]?.Vice_Captain}
                                                                            name='Vice_Captain'
                                                                            onWheel={(e) => { e.target.blur() }} onChange={(e) => handleInputChange(e, testList[3]._id, testList[3].Catogary, testList[3].match_type)} value={inputData?._id === testList[3]?._id ? inputData?.Vice_Captain : testList[3]?.Vice_Captain} />
                                                                    </div>
                                                                </div>
                                                                <div class="form-group row align-items-center">
                                                                    <div class="col-8" >
                                                                        <label class="small  me-2">In announced lineups:</label>
                                                                    </div>
                                                                    <div class="col-4" >
                                                                        <input class="form-control check  form-control check-solid" type='number' types={testList[3]?.Starting11}
                                                                            name='Starting11'
                                                                            onWheel={(e) => { e.target.blur() }} onChange={(e) => handleInputChange(e, testList[3]._id, testList[3].Catogary, testList[3].match_type)} value={inputData?._id === testList[3]?._id ? inputData?.Starting11 : testList[3]?.Starting11} />
                                                                    </div>
                                                                </div>
                                                                <div class="form-group row align-items-center">
                                                                    <div class="col-8" >
                                                                        <label class="small  me-2">Playing substitue <br /><small>(Concussion, COVID-19, X-Factor, or Impact Player)</small>:</label>
                                                                    </div>
                                                                    <div class="col-4" >
                                                                        <input class="form-control check  form-control check-solid" type='number' types={testList[3]?.playingsubstitue}
                                                                            name='playingsubstitue'
                                                                            onWheel={(e) => { e.target.blur() }} onChange={(e) => handleInputChange(e, testList[3]._id, testList[3].Catogary, testList[3].match_type)} value={inputData?._id === testList[3]?._id ? inputData?.playingsubstitue : testList[3]?.playingsubstitue} />
                                                                    </div>
                                                                </div>
                                                                <button class="btn btn-primary   btn-block w-100 mt-2" type="button" onClick={() => EditPoints(inputData)} >Update</button>
                                                            </form> : ''}
                                                    </div>
                                                </div>
                                            </div>


                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>




                </main>
            </div>

        </>
    )
}

export default CrickerPoints
