import React from 'react'
import Header from '../Header'
import Sidebar from '../Sidebar'

const UserRefInfo = () => {
    return (
        <>

            <div id="layoutSidenav_content">
                <main>
                    <header class="page-header page-header-dark bg-gradient-primary-to-secondary pb-10">
                        <div class="container-xl px-4">
                            <div class="page-header-content pt-4">
                                <div class="row align-items-center justify-content-between mt-4">
                                    <div class="col-auto ">
                                        <h1 class="page-header-title">
                                            <div class="page-header-icon"><i class="ri-group-line"></i></div>
                                            Users Referral Info
                                        </h1>
                                        {/* <!-- <div class="page-header-subtitle">Example dashboard overview and content summary</div> --> */}
                                    </div>
                                    <div class="col-auto"><button class="btn btn-white btn-block w-100" data-bs-toggle="modal" data-bs-target="#addreferalmodal" type="button">Add New Referral </button></div>
                                </div>
                            </div>
                        </div>
                    </header>
                    {/* <!-- Main page content--> */}
                    <div class="container-xl px-4 mt-n10">
                        <div class="card mb-4">

                            <div class="card-body">
                                <div class=" filter_bar mb-4 mb-md-5">
                                    <form method="get" accept-charset="utf-8" novalidate="novalidate" class="form-inline search_form" action="/admin/users/referraldata">
                                        <div class="row gx-2 align-items-center">
                                            {/* <div class="form-group mb-0 col text-end">
                                                    <div class="input text">
                                                        <label class="me-3" >Filter</label>
                                                    </div>
                                                </div> */}
                                            <div class="form-group mb-0 col">
                                                <div class="input text">
                                                    <input type="text" name="name" class="form-control" placeholder="Username" id="name" />
                                                </div>
                                            </div>
                                            <div class="form-group  mb-0 col">
                                                <div class="input text">
                                                    <input type="text" name="sponsor_name" class="form-control" placeholder="Sponsor Username" id="sponsor-name" />
                                                </div>
                                            </div>
                                            <div class="form-group  mb-0 col ">
                                                <div class="row gx-2" >
                                                    <div class="col" >
                                                        <button type="button" class="btn btn-primary submit px-5 w-100 btn-block">Search</button>
                                                    </div>
                                                    <div class="col" >
                                                        <a href="#" class="btn btn-dark px-4  w-100 btn-block"><i class="fa fa-undo me-2"></i> Reset</a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                                <table id="datatablesSimple">
                                    <thead>
                                        <tr>
                                            <th>#</th>
                                            <th>User Referral Code</th>
                                            <th>    User Name</th>
                                            <th>User Email</th>
                                            <th>User Phone number</th>
                                            <th>Sponsor Referral Code</th>
                                            <th>Date</th>
                                        </tr>
                                    </thead>
                                    <tfoot>
                                        <tr>
                                            <th>#</th>
                                            <th>User Referral Code</th>
                                            <th>    User Name</th>
                                            <th>User Email</th>
                                            <th>User Phone number</th>
                                            <th>Sponsor Referral Code</th>
                                            <th>Date</th>
                                        </tr>
                                    </tfoot>
                                    <tbody>
                                        <tr>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>

                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </main>
            </div>






            <div class="modal" id="addreferalmodal" tabIndex="-1" aria-labelledby="addreferalmodalTitle" style={{ display: 'none' }} aria-hidden="true">
                <div class="modal-dialog  alert_modal modal-dialog-centered" role="document">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="addreferalmodalTitle">Add New Referral</h5>
                            <button class="btn-close" type="button" data-bs-dismiss="modal" aria-label="Close"></button>
                            <hr />
                        </div>
                        <div class="modal-body">
                            <form>
                                <div class="form-group  mb-3 position-relative ">
                                    <label class="small mb-1"> Username </label>
                                    <input class="form-control  form-control-solid input-copy" type="text" placeholder="Enter Username" value="" />
                                </div>
                                <div class="form-group  mb-3 position-relative ">
                                    <label class="small mb-1"> Sponsor Username </label>
                                    <input class="form-control  form-control-solid input-copy" type="text" placeholder="Enter Sponsor Username " value="" />
                                </div>
                                <div class="form-group  position-relative mt-4">
                                    <button class="btn btn-primary   btn-block w-100" type="button">Add Referal</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default UserRefInfo
