import React from 'react';
import {render} from "react-dom"
import App from './App';
import ContextProvider from './Components/Context/ContextProvider';
const root = document.getElementById('root')
render(
  
  <React.StrictMode>
    <ContextProvider>
    <App />
  </ContextProvider>
  </React.StrictMode>,
  root
);
