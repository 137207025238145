import React, { useEffect, useState } from 'react';
import LoaderHelper from '../../Components/Loading/LoaderHelper';
import AuthService from '../../api/services/HomeService';
import { alertErrorMessage, alertSuccessMessage } from '../../Components/CustomAlertMessage';

const AadharRequest = () => {
    const [list, setlist] = useState();
    const [list2, setlist2] = useState();
    const [kycStatus, setKycStatus] = useState('1');
    const [Image, setImage] = useState();
    const [skip, setSkip] = useState(0);

    useEffect(() => {
        AadharList(kycStatus, 0)
    }, []);

    const AadharList = async (kycStatus, skip) => {
        LoaderHelper.loaderStatus(true);
        await AuthService.ListAadhar(kycStatus, skip, 20).then((result) => {
            if (result?.success) {
                LoaderHelper.loaderStatus(false);
                try {
                    setSkip(skip)
                    setlist(result?.data)
                    setlist2(result?.data)
                } catch {
                    alertErrorMessage(result.message);
                }
            } else {
                LoaderHelper.loaderStatus(false);
                alertErrorMessage(result.message);
            }
        });
    };

    const VerifyAadhar = async (id, status, userid) => {
        await AuthService.VerifyAadhar(id, status, userid).then((result) => {
            if (result?.success) {
                try {
                    alertSuccessMessage("Aadhar Status Updated Successfully!!");
                    AadharList(kycStatus, skip)
                } catch {
                    alertErrorMessage(result.message);
                }
            } else {
                // alertErrorMessage(result.message);
            }
        });
    };

    const deleteAAdhar = async (id, status, userid) => {
        await AuthService.delete_adhar(id, status, userid).then((result) => {
            if (result?.success) {
                try {
                    alertSuccessMessage(result?.message);
                    AadharList(kycStatus, skip)
                } catch {
                    alertErrorMessage(result.message);
                }
            } else {
                alertErrorMessage(result.message);
            }
        });
    };

    const filter = (e) => {
        let filterd;
        if (e.target.checked) {
            filterd = list2?.filter((data) => {
                return data?.adhar_verified == 3
            })
            setlist(filterd)
        } else {
            setlist(list2)
        }
    };

    return (
        <>

            <div id="layoutSidenav_content">
                <main>
                    <header class="page-header page-header-dark bg-gradient-primary-to-secondary pb-10">
                        <div class="container-xl px-4">
                            <div class="page-header-content pt-4">
                                <div class="row align-items-center justify-content-between">
                                    <div class="col-auto mt-4">
                                        <h1 class="page-header-title">
                                            <div class="page-header-icon"><i class="ri-group-line"></i></div>
                                            Aadhar Request
                                        </h1>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </header>
                    <div class="container-xl px-4 mt-n10">
                        <div class="card mb-4">
                            <div class="card-body">
                                <div class=" filter_bar mb-4 mb-md-5">

                                    <select onChange={(e) => {
                                        setKycStatus(e.target.value);
                                        AadharList(e.target.value, 0);
                                    }} className='form-control'>
                                        <option value="1">Approved Request</option>
                                        <option value="2">Pending Request</option>
                                    </select>
                                </div>
                                <table id="datatablesSimple">
                                    <thead>
                                        <tr>
                                            <th>#</th>
                                            <th>Name</th>
                                            <th>Phone Number</th>
                                            <th>Aadhar Number</th>
                                            <th>Aadhar Front Image</th>
                                            <th>Aadhar Back Image</th>
                                            <th>Status</th>
                                        </tr>
                                    </thead>
                                    <tfoot>
                                        <tr>
                                            <th>#</th>
                                            <th>Name</th>
                                            <th>Phone Number</th>
                                            <th>Aadhar Number</th>
                                            <th>Aadhar Front Image</th>
                                            <th>Aadhar Back Image</th>
                                            <th>Status</th>
                                        </tr>
                                    </tfoot>
                                    <tbody>
                                        {list ? list?.map((data, index) => {
                                            return (
                                                <tr>
                                                    <td>{index + skip + 1}</td>
                                                    <td>{data?.adahr_name}</td>
                                                    <td>{data?.data?.mobile_number}</td>
                                                    <td>{data?.adhar_no}</td>

                                                    <td><a href={'http://13.127.44.238:4002/' + data?.adhar_front_image} target='_blank' rel="noreferrer" onClick={() => setImage(data?.adhar_front_image)} data-bs-toggle="modal" data-bs-target="#showimage"> <img alt='frontImage' src={'http://13.127.44.238:4002/' + data?.adhar_front_image} height='300' /></a></td>

                                                    <td><a href={'http://13.127.44.238:4002/' + data?.adhar_back_image} target='_blank' rel="noreferrer" onClick={() => setImage(data?.adhar_back_image)} data-bs-toggle="modal" data-bs-target="#showimage"> <img alt='backImage' src={'http://13.127.44.238:4002/' + data?.adhar_back_image} height='300' /></a></td>

                                                    <td>
                                                        {data?.adhar_verified == 1 ? <button type="button" class="mt-2 mx-2   btn badge bg-danger text-white rounded-pill"
                                                            onClick={() => deleteAAdhar(data?._id, '0', data?.user_id)}> Delete</button> :
                                                            <>
                                                                <button type="btn" class="btn badge bg-success text-white rounded-pill"
                                                                    onClick={() => { VerifyAadhar(data?._id, '1', data?.user_id) }}
                                                                >Approve</button>

                                                                <button type="button" class="mt-2 mx-2   btn badge bg-danger text-white rounded-pill"
                                                                    onClick={() => deleteAAdhar(data?._id, '0', data?.user_id)}> Delete</button>
                                                            </>}
                                                    </td>
                                                </tr>
                                            )
                                        }) : ''}
                                    </tbody>
                                </table>
                                <div>
                                    <button type="btn" class="btn badge bg-primary text-white rounded-pill mx-2 p-2" disabled={skip === 0} onClick={() => { AadharList(kycStatus, 0) }}>{"<<<<"}</button>
                                    <button type="btn" class="btn badge bg-primary text-white rounded-pill mx-2 p-2" disabled={skip === 0} onClick={() => { AadharList(kycStatus, skip - 20) }}>{"<<Previous"}</button>
                                    <button type="btn" class="btn badge bg-primary text-white rounded-pill p-2" disabled={list?.length < 20} onClick={() => { AadharList(kycStatus, skip + 20) }}>{"Next>>"}</button>
                                </div>
                            </div>
                        </div>
                    </div>

                </main>
            </div>

            {/*showimage*/}
            <div className="modal" id="showimage" tabIndex="-1" aria-labelledby="editwallrtlmodalTitle" style={{ display: "none" }} aria-hidden="true"     >
                <div className="modal-dialog modal-lg  alert_modal modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="editwallrtlmodalTitle">
                                Aadhar Image
                            </h5>
                            <button className="btn-close" type="button" data-bs-dismiss="modal" aria-label="Close"></button>
                            <hr />
                        </div>
                        <div className="modal-body">
                            <img alt='frontImage' src={'http://13.127.44.238:4002/' + Image} className='img-fluid' />
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}

export default AadharRequest
