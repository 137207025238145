import React from 'react'
import Header from '../Header'
import Sidebar from '../Sidebar'

const Earnings = () => {
    return (
        <>

                <div id="layoutSidenav_content">
                    <main>
                        <header class="page-header page-header-dark bg-gradient-primary-to-secondary pb-10">
                            <div class="container-xl px-4">
                                <div class="page-header-content pt-4">
                                    <div class="row align-items-center justify-content-between">
                                        <div class="col-auto mt-4">
                                            <h1 class="page-header-title">
                                                <div class="page-header-icon"><i class="ri-exchange-dollar-line"></i></div>
                                                Earning Manager
                                            </h1>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </header>
            
                        <div class="container-xl px-4 mt-n10">

                            <div class="card mb-4">

                                <div class="card-body">
                                    <div class=" filter_bar mb-4 mb-md-5">
                                        <form method="get" accept-charset="utf-8" novalidate="novalidate" class="form-inline search_form" action="/admin/users/referraldata">
                                            <div class="row gx-2 align-items-center">

                                                {/* <div class="form-group mb-0 col text-end">
                                                    <div class="input text">
                                                        <label class="me-3">Filter</label>
                                                    </div>
                                                </div> */}
                                                <div class="form-group  mb-0 col">
                                                    <div class="input text">
                                                        <select name="series" class="form-control form-select series">
                                                            <option value="">Select Series</option>
                                                            <option value="126662">Afghanistan tour of Sri Lanka</option>
                                                            <option value="126168">Africa Cricket Association Cup</option>
                                                            <option value="125645">Asia Cup</option>
                                                            <option value="125787">Asia Cup Qualifier</option>
                                                            <option value="125880">Assam T20</option>
                                                            <option value="125629">Australia tour of India</option>
                                                            <option value="126217">Bangladesh tour of United Arab Emirates</option>
                                                            <option value="125586">Big Bash League</option>
                                                            <option value="125273">Caribbean Premier League</option>
                                                            <option value="126587">CBSF T-20 League</option>
                                                            <option value="126044">Cricket Association T20</option>
                                                            <option value="126257">CSA Provincial T20 Cup</option>
                                                            <option value="126543">CSA T20 Challenge</option>
                                                            <option value="126649">Dubai D10 Division 1</option>
                                                            <option value="126676">Dubai D10 Division 2</option>
                                                            <option value="126746">ECS Barcelona T10</option>
                                                            <option value="126544">ECS Croatia</option>
                                                            <option value="126619">ECS Malta</option>
                                                            <option value="125918">ECS T10 Belgium</option>
                                                            <option value="126189">ECT10 Spain</option>
                                                            <option value="125635">England tour of Australia</option>
                                                            <option value="125912">England tour of Pakistan</option>
                                                            <option value="126141">European Cricket Championship</option>
                                                            <option value="125953">FairBreak XI Tour Of Netherlands</option>
                                                            <option value="125886">FairBreak XI tour of Scotland</option>
                                                            <option value="125769">FanCode ECS T10 Germany, Krefeld</option>
                                                            <option value="125815">ICC Academy Summer Cup</option>
                                                            <option value="113521">ICC Men's Cricket World Cup League 2</option>
                                                            <option value="125017">ICC Men's CWC Challenge League B</option>
                                                            <option value="122941">ICC Men's T20 World Cup</option>
                                                            <option value="126057">ICC Mens T20 World Cup East Asia Pacific Qualifier A</option>
                                                            <option value="126237">ICC Mens T20 World Cup Warm-up Matches</option>
                                                            <option value="125959">ICC Mens T20I World Cup East Asia Pacific Qualifier B</option>
                                                            <option value="126197">ICC Women's T20 World Cup Qualifier</option>
                                                            <option value="126195">ICC Women's T20 World Cup Qualifier Warm Up Matches</option>
                                                            <option value="126535">ICCA Arabian T20 League</option>
                                                            <option value="126659">India tour of Bangladesh</option>
                                                            <option value="125582">India tour of New Zealand</option>
                                                            <option value="125716">India Women tour of England</option>
                                                            <option value="126404">Indonesia tour of Japan</option>
                                                            <option value="125715">Ireland Women tour of Netherlands</option>
                                                            <option value="126658">Ireland Women tour of Pakistan</option>
                                                            <option value="125940">Ireland Women tour of Scotland</option>
                                                            <option value="125966">Japan Cricket League</option>
                                                            <option value="126221">KCA Cup T20</option>
                                                            <option value="126063">KCA Pink T20 Challengers</option>
                                                            <option value="125893">KCA Women's Maharaja T20 Trophy</option>
                                                            <option value="126289">KCC T20 Championship</option>
                                                            <option value="125956">Lahore Qalandars tour of Namibia</option>
                                                            <option value="126133">Legends League Cricket</option>
                                                            <option value="125659">Maharaja T20 Trophy</option>
                                                            <option value="126690">Malaysia T20 Quadrangular Series</option>
                                                            <option value="124650">MCA T20 Syawal Peace Cup</option>
                                                            <option value="125223">Minor League Cricket</option>
                                                            <option value="125941">National T20 Cup</option>
                                                            <option value="125789">Nepal tour of Kenya</option>
                                                            <option value="125631">New Zealand T20I Tri-Series</option>
                                                            <option value="124957">New Zealand tour of Australia</option>
                                                            <option value="126661">New Zealand tour of Pakistan</option>
                                                            <option value="126166">New Zealand Women tour of West Indies</option>
                                                            <option value="126466">Oman D10 League</option>
                                                            <option value="126347">Pakistan Junior League</option>
                                                            <option value="126716">Pondicherry Inter District T20</option>
                                                            <option value="126403">RCA T10 Cup</option>
                                                            <option value="125984">RCA T20 Cup</option>
                                                            <option value="125909">Road Safety World Series</option>
                                                            <option value="126705">Rwanda tour of Tanzania</option>
                                                            <option value="126777">Senior Women's Inter Zonal T20 Trophy</option>
                                                            <option value="126467">Senior Women's T20 League</option>
                                                            <option value="126730">Singapore Women tour of Indonesia</option>
                                                            <option value="125915">South Africa tour of Australia</option>
                                                            <option value="122987">South Africa tour of England</option>
                                                            <option value="125630">South Africa tour of India</option>
                                                            <option value="126713">Spain T20I Tri Series</option>
                                                            <option value="125837">Sri Lanka Under-19 tour of England</option>
                                                            <option value="126173">Sukan Malaysia XX MSN</option>
                                                            <option value="126268">Syed Mushtaq Ali Trophy</option>
                                                            <option value="125869">The 6IXTY Men's Competition</option>
                                                            <option value="125874">The 6IXTY Women's Competition</option>
                                                            <option value="123037">The Hundred Mens Competition</option>
                                                            <option value="125275">The Hundred Women's Competition</option>
                                                            <option value="126787">United Arab Emirates tour of Nepal</option>
                                                            <option value="124959">West Indies tour of Australia</option>
                                                            <option value="126464">Western Australia XI vs India World Cup Warm Up</option>
                                                            <option value="126232">Women's Asia Cup</option>
                                                            <option value="125591">Women's Big Bash League</option>
                                                            <option value="125860">Women's Caribbean Premier League</option>
                                                            <option value="125865">Women's Continental Cup</option>
                                                            <option value="126692">Women's Under-19 T20 Challenger Trophy</option>
                                                            <option value="126673">Womens East Asia Cup</option>
                                                            <option value="126128">Womens T20I Quadrangular Series in UAE</option>
                                                            <option value="126065">Zimbabwe T10</option>
                                                            <option value="124944">Zimbabwe tour of Australia</option>
                                                            <option value="125924">Zimbabwe Women in South Africa</option>
                                                        </select>
                                                    </div>
                                                </div>
                                                <div class="form-group  mb-0 col">
                                                    <div class="input text">
                                                        <input type="date" name="end_date" class="form-control datepicker-input end_date" placeholder="To" id="end-date"/>
                                                    </div>
                                                </div>
                                                <div class="form-group  mb-0 col">
                                                    <div class="input text">
                                                        <input type="date" name="end_date" class="form-control datepicker-input end_date" placeholder="To" id="end-date"/>
                                                    </div>
                                                </div>
                                                <div class="form-group  mb-0 col">
                                                    <div class="row gx-2" >
                                                        <div class="col" >
                                                            <button type="button" class="btn btn-primary submit px-5 w-100 btn-block">Search</button>
                                                        </div>
                                                        <div class="col" >
                                                            <a href="#" class="btn btn-dark px-4  w-100 btn-block"><i class="fa fa-undo me-2"></i> Reset</a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </div>

                                    <table id="datatablesSimple">
                                        <thead>
                                            <tr>
                                                <th>#</th>
                                                <th>Match Name <br /> Series Name</th>
                                                <th>Amount Collected <br /> (AC)</th>
                                                <th>Amount Used</th>
                                                <th>Winning Distribution <br /> (WD)</th>
                                                <th>Total Earning <br /> [AC -(WD+Bonus)]</th>
                                            </tr>
                                        </thead>
                                        <tfoot>
                                            <tr>
                                                <th>#</th>
                                                <th>Match Name <br /> Series Name</th>
                                                <th>Amount Collected <br /> (AC)</th>
                                                <th>Amount Used</th>
                                                <th>Winning Distribution <br /> (WD)</th>
                                                <th>Total Earning <br /> [AC -(WD+Bonus)]</th>
                                            </tr>
                                        </tfoot>
                                        <tbody>
                                            <tr>
                                                <td>1</td>
                                                <td>--</td>
                                                <td>--</td>
                                                <td>--</td>
                                                <td>--</td>
                                                <td>--</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>




                    </main>
                </div>



            <div class="modal" id="editwallrtlmodal" tabIndex="-1" aria-labelledby="editwallrtlmodalTitle" style={{display: 'none'}} aria-hidden="true">
                <div class="modal-dialog  alert_modal modal-dialog-centered" role="document">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="editwallrtlmodalTitle">Update Wallet</h5>
                            <button class="btn-close" type="button" data-bs-dismiss="modal" aria-label="Close"></button>
                            <hr />
                        </div>
                        <div class="modal-body">

                            <div class="form-group row align-items-center   mb-1">
                                <div class="col-6">
                                    <label class="small  me-2">User Mobile :</label>
                                </div>
                                <div class="col-6">
                                    <strong>----</strong>
                                </div>
                            </div>
                            <div class="form-group row align-items-center   mb-1">
                                <div class="col-6">
                                    <label class="small  me-2">User Amount :</label>
                                </div>
                                <div class="col-6">
                                    <strong>----</strong>
                                </div>
                            </div>
                            <div class="form-group row align-items-center  mb-1">
                                <div class="col-6">
                                    <label class="small  me-2">Wallet Type :</label>
                                </div>
                                <div class="col-6">
                                    <strong>----</strong>
                                </div>
                            </div>

                            <br />


                            <form>
                                <div class="form-group  mb-3 position-relative ">
                                    <label class="small mb-1"> Amount </label>
                                    <input class="form-control  input-copy" type="text" placeholder="Enter Amount" value=""/>
                                </div>
                                <div class="form-group  mb-3 position-relative ">
                                    {/* <!-- <label class="small mb-1"> Sponcer Username </label> --> */}
                                    <select name="up_type" id="up_type" class="form-control form-select"><option value="1">Add</option><option value="2">Deduct</option></select>
                                </div>
                                <div class="form-group  position-relative mt-4">
                                    <button class="btn btn-primary   btn-block w-100" type="button">Add Referal</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>



        </>
    )
}

export default Earnings
