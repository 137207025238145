
import React, { useContext, useEffect, useState } from 'react'
import { context } from '../../Components/Context/ContextProvider'


const ContestDetails = () => {
    const [showPage, setshowPage] = useState(true);
    const { contestDeatils } = useContext(context)
    const [eachContestDetails, seteachContestDetails] = useState();

    let contestCategory = []
    let uniqueContestCategory;

    for (let i = 0; i < contestDeatils?.contest_category_details?.length; i++) {
        contestCategory.push(contestDeatils?.contest_category_details[i]?.categoryName)
    }
    uniqueContestCategory = [...new Set(contestCategory)];
    console.log(uniqueContestCategory, 'uniqueContestCategory');
    useEffect(() => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth"
        });


    }, []);

    const PerContestDetails = async (data) => {
        seteachContestDetails(data)
        setshowPage(!showPage);
        // let matchId = contestDeatils?.match_id
        // await AuthService.ViewPerCon(matchId, data).then((result) => {
        //     if (result?.success) {
        //         try {
        //             setshowPage(!showPage);

        //             seteachContestDetails(result?.data[0]?.data[0])
        //         } catch {
        //             alertErrorMessage(result.message);
        //         }
        //     } else {
        //         alertErrorMessage(result.message);
        //     }
        // })
    };


    // const downloadPdf = () => {
    //     const doc = new jsPDF();

    //     contestDeatils?.data.forEach((item, index) => {
    //       doc.text(`${index + 1}.Category: ${item?.categoryName},Entery fee: ${item?.Entery_fee}, Contest Size: ${item.size}, winning_amoun: ${item.winning_amount}, contest_type: ${item.contest_type}`, 10, 10 + index * 10);
    //     });

    //     doc.save('Contest.pdf');

    // }

    return (
        <>

            <div id="layoutSidenav_content">
                <main>
                    <header class="page-header page-header-dark bg-gradient-primary-to-secondary pb-10">
                        <div class="container-xl px-4">
                            <div class="page-header-content pt-4">
                                <div class="row align-items-center justify-content-between">
                                    <div class="col-auto mt-4">
                                        <h1 class="page-header-title">
                                            <div class="page-header-icon"><i class="ri-football-line"></i></div>
                                            Details For Added Contest
                                        </h1>
                                        {/* <!-- <div class="page-header-subtitle">Example dashboard overview and content summary</div> --> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </header>
                    {/* <!-- Main page content--> */}
                    <div class="container-xl px-4 mt-n10 " style={{ display: showPage ? '' : 'none' }}>

                        <div class="card mb-4">
                            <div className='card-header py-2 d-flex align-items-center justify-content-between' >
                                <div>
                                    <a className='btn btn-icon' href='/ScheduleContest'  > <i class="ri-arrow-left-line"></i> </a> Back
                                </div>
                                {/* <CSVLink data={contestDeatils?.data } className='btn btn-primary'>Export as CSV</CSVLink> */}
                                {/* <button onClick={downloadPdf} className='btn btn-primary' id='download-btn' >
                                        Download
                                    </button> */}
                            </div>
                            <div class="card-body">

                                <table id=" " className='dataTable-table' >


                                    <tbody>
                                        {uniqueContestCategory ? uniqueContestCategory?.map((data1) => {
                                            return (
                                                <>

                                                    <tr>
                                                        <td colspan="8" style={{ backgroundColor: 'rgb(204, 214, 245)' }}>
                                                            <div className='vs_team align-items-center' >
                                                                {/* <img src={cricImage} width="45" height="45" alt="Delhi Capitals Women" /> */}
                                                                <h4 className='mb-0' >{data1}</h4>
                                                            </div>
                                                        </td>
                                                    </tr>

                                                    < tr >
                                                        <th>Winning Amount</th>
                                                        <th>Contest Size</th>
                                                        {/* <th>Entry Fee</th> */}
                                                        <th>Contest Type</th>
                                                        <th>Action</th>
                                                    </tr>

                                                    {contestDeatils?.contest_category_details ? contestDeatils?.contest_category_details?.map((data, index) => {

                                                        return (
                                                            data1 === data?.categoryName ?
                                                                <tr>
                                                                    <td>{data?.WinningAmount}</td>
                                                                    <td>{data?.Contestsize}</td>
                                                                    {/* <td>{data?.Entery_fee}</td> */}
                                                                    <td>{data?.ContestType}</td>
                                                                    <td>
                                                                        <button class=" btn btn-sm btn-primary " type='button' title="View User" onClick={() => { PerContestDetails(data) }}> View </button>
                                                                        {/* <button class=" btn btn-sm btn-primary " type='button' title="View User" onClick={() => { PerContestDetails(data?._id) }}> View </button> */}
                                                                    </td>
                                                                </tr> : ''

                                                        )
                                                    }) : <h4>No Data Found</h4>}


                                                </>
                                            )




                                        }) : <h4>No Data Found</h4>}




                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>

                    {/* Contest View Pgae */}
                    <div class="container-xl px-4 mt-n10 " style={{ display: showPage ? 'none' : '' }}>

                        <div class="card mb-4">
                            <div className='card-header py-2 d-flex align-items-center justify-content-between' >
                                <div>
                                    <button className='btn btn-icon' type='button' onClick={() => { setshowPage(!showPage) }} > <i class="ri-arrow-left-line" ></i> </button> Back
                                </div>
                                {/* <button className='btn btn-primary' >
                                        Download
                                    </button> */}
                            </div>

                            <div class="card-body">


                                <div class="card-deck row mb-4">
                                    <div className='col-md-6' >
                                        <div class="card card-border">
                                            <div class="card-body">
                                                <div class="row">
                                                    <div class="col-sm-6">Category:</div>
                                                    <div class="col-sm-6">
                                                        <strong>{eachContestDetails?.categoryName}</strong>
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div class="col-sm-6">Winning Prize:</div>
                                                    <div class="col-sm-6">
                                                        <strong>{eachContestDetails?.WinningAmount} INR</strong>
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div class="col-sm-6">Max Team Size:</div>
                                                    <div class="col-sm-6">
                                                        <strong>{eachContestDetails?.Contestsize}</strong>
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div class="col-sm-6">Winner Team Size:</div>
                                                    <div class="col-sm-6">
                                                        <strong>N/A</strong>
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div class="col-sm-6">Entry Fee:</div>
                                                    <div class="col-sm-6">
                                                        <strong>{eachContestDetails?.EnteryFee} INR</strong>
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div class="col-sm-6">Contest Type:</div>
                                                    <div class="col-sm-6">
                                                        <strong>{eachContestDetails?.ContestType}</strong>
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div class="col-sm-6">Entery Type:</div>
                                                    <div class="col-sm-6">
                                                        <strong>{eachContestDetails?.EnteryType}</strong>
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div class="col-sm-6">Usable Bonus Percantage:</div>
                                                    <div class="col-sm-6">
                                                        <strong>{eachContestDetails?.UsableBonusPercantage}</strong>
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div class="col-sm-6">Join With Mulitple Team:</div>
                                                    <div class="col-sm-6">
                                                        <strong>{eachContestDetails?.JoinWithMULT?.toString()}</strong>
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div class="col-sm-6">Auto CreateTeam:</div>
                                                    <div class="col-sm-6">
                                                        <strong>{eachContestDetails?.AutoCreate?.toString()}</strong>
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div class="col-sm-6">Confirmed Win:</div>
                                                    <div class="col-sm-6">
                                                        <strong>{eachContestDetails?.ConfirmedWin?.toString()}</strong>
                                                    </div>
                                                </div>
                                                <hr/>
                                                {eachContestDetails?.Rankdata ? eachContestDetails?.Rankdata?.map((data) => {
                                                    return (
                                                        <>
                                                        <div class="row">
                                                            <div class="col-sm-6">{data?.StartRank} Rank - {data?.EndRank} Rank </div>
                                                            <div class="col-sm-6">
                                                                <strong>Each {data?.Price}rs.</strong>
                                                            </div>
                                                        </div>
                                                     

                                                        </>
                                                    )
                                                }) : ''}

                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-md-6' >
                                        <div class="card card-border">
                                            <div class="card-body">
                                                <div class="row">
                                                    <div class="col-sm-6">Starts At:</div>
                                                    <div class="col-sm-6">
                                                        <strong>N/A</strong>
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div class="col-sm-6">Entries Left:</div>
                                                    <div class="col-sm-6">
                                                        <strong>N/A</strong>
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div class="col-sm-6">Teams Joined:</div>
                                                    <div class="col-sm-6">
                                                        <strong>N/A</strong>
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div class="col-sm-6">Contest Invite Code:</div>
                                                    <div class="col-sm-6">
                                                        <strong>N/A</strong>
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div class="col-sm-6">Contest Status:</div>
                                                    <div class="col-sm-6">
                                                        <strong>{eachContestDetails?.status }</strong>
                                                    </div>
                                                </div>
                                              
                                              
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <table id=" " className='dataTable-table d-none' >
                                    <tbody>
                                        <tr>
                                            <td colspan="8" >
                                                <div className='vs_team align-items-center' >
                                                    <h4 className='mb-0' >Joined Team</h4>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <th>Id </th>
                                            <th>Image</th>
                                            <th>Team Name</th>
                                            <th>Created By </th>
                                            <th>Points</th>
                                            <th>Rank</th>
                                            <th>Amount</th>
                                            <th>Action</th>
                                        </tr>
                                        <tr>
                                            <td>--</td>
                                            <td>--</td>
                                            <td>--</td>
                                            <td>--</td>
                                            <td>--</td>
                                            <td>--</td>
                                            <td>--</td>
                                            <td>
                                                <button class=" btn btn-sm btn-primary " title="View User"> View </button>
                                            </td>
                                        </tr>




                                    </tbody>
                                </table>



                            </div>
                        </div>
                    </div>




                </main>
            </div>



        </>
    )
}

export default ContestDetails
