import React from "react"
import Loading from "./Components/Loading";
import LoaderHelper from "./Components/Loading/LoaderHelper";
import Routing from "./Routing/index";

function App() {
  return (
    <>
      <Loading ref={ref => LoaderHelper.setLoader(ref)} />
      <Routing />
    </>
  );
}

export default App;
