import React from 'react'
import Header from '../Header'
import Sidebar from '../Sidebar'

const Transaction = () => {
    return (
        <>

            <div id="layoutSidenav_content">
                <main>
                    <header class="page-header page-header-dark bg-gradient-primary-to-secondary pb-10">
                        <div class="container-xl px-4">
                            <div class="page-header-content pt-4">
                                <div class="row align-items-center justify-content-between">
                                    <div class="col-auto mt-4">
                                        <h1 class="page-header-title">
                                            <div class="page-header-icon"><i class="ri-arrow-up-down-line"></i></div>
                                            Transaction
                                        </h1>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </header>
                    {/* <!-- Main page content--> */}
                    {/* <!-- Example Colored Cards for Dashboard Demo--> */}
                    <div class="container-xl px-4 mt-n10">

                        <div class="card mb-4">

                            <div class="card-body">
                                <div class=" filter_bar mb-4 mb-md-5">
                                    <form method="get" accept-charset="utf-8" novalidate="novalidate" class="form-inline search_form" action="/admin/users/referraldata">
                                        <div class="row gx-2 align-items-center">
                                            {/* <div class="form-group mb-0 col text-end">
                                        <div class="input text">
                                            <label class="me-3">Filter</label>
                                        </div>
                                    </div> */}

                                            <div class="form-group  mb-0 col">
                                                <div class="input text">
                                                    <input type="tel" name="phone" class="form-control" placeholder="User Mobile" id="phone" />
                                                </div>
                                            </div>
                                            <div class="form-group  mb-0 col">
                                                <div class="input text">
                                                    <input type="email" name="email" class="form-control" placeholder="User Email" id="email" />
                                                </div>
                                            </div>
                                            <div class="form-group mb-0 col">
                                                <select name="added_type" class="form-control form-select">
                                                    <option value="">Select Transaction Type</option>
                                                    <option value="1">Deposited Cash</option>
                                                    <option value="2">Mobile Verified</option>
                                                    <option value="3">Joined A Contest</option>
                                                    <option value="4">Won A contest</option>
                                                    <option value="5">Friend Joined Contests</option>
                                                    <option value="6">Contest Cancelled</option>
                                                    <option value="7">Level Up Cash Credited</option>
                                                    <option value="8">Coupon Bonus</option>
                                                    <option value="9">Withdrawal</option>
                                                    <option value="10">Friend Used Invite Code</option>
                                                    <option value="11">Withdraw Pending</option>
                                                    <option value="12">Withdraw Rejected</option>
                                                    <option value="13">Withdraw COnfirmed</option>
                                                    <option value="14">Admin Added</option>
                                                    <option value="15">Admin Deducted</option>
                                                    <option value="16">Pocker Entry Fee</option>
                                                    <option value="17">Pocker Won Prize</option>
                                                    <option value="18">Ticket transaction</option>
                                                    <option value="19">Crown transaction</option>
                                                    <option value="20">Afflicted Commission</option>
                                                    <option value="21">Level Commission</option>
                                                </select>
                                            </div>
                                            <div class="form-group  mb-0 col">
                                                <div class="row gx-2" >
                                                    <div class="col" >
                                                        <button type="button" class="btn btn-primary submit px-5 w-100 btn-block">Search</button>
                                                    </div>
                                                    <div class="col" >
                                                        <a href="#" class="btn btn-dark px-4  w-100 btn-block"><i class="fa fa-undo me-2"></i> Reset</a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>

                                <table id="datatablesSimple">
                                    <thead>
                                        <tr>
                                            <th>#</th>
                                            <th>Transaction Id</th>
                                            <th>Local Transaction Id</th>
                                            <th>User Mobile</th>
                                            <th>User Email</th>
                                            <th>Cr/Db</th>
                                            <th>Payment Mode</th>
                                            <th>Transaction Type</th>
                                            <th>Amount</th>
                                            <th>Date &amp; Time</th>
                                        </tr>
                                    </thead>
                                    <tfoot>
                                        <tr>
                                            <th>#</th>
                                            <th>Transaction Id</th>
                                            <th>Local Transaction Id</th>
                                            <th>User Mobile</th>
                                            <th>User Email</th>
                                            <th>Cr/Db</th>
                                            <th>Payment Mode</th>
                                            <th>Transaction Type</th>
                                            <th>Amount</th>
                                            <th>Date &amp; Time</th>
                                        </tr>
                                    </tfoot>
                                    <tbody>
                                        <tr>
                                            <td>1</td>
                                            <td>---</td>
                                            <td>---</td>
                                            <td>---</td>
                                            <td><a href="#" class="">---</a></td>
                                            <td> ---  </td>
                                            <td>---</td>
                                            <td>---</td>
                                            <td>---</td>
                                            <td>---</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>




                </main>
            </div>

        </>
    )
}

export default Transaction
