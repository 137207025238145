import React, { useState } from 'react'

const Sidebar = () => {

    const permissions = sessionStorage.getItem('permissions');
    const userTypes = sessionStorage.getItem('userType');
    const [permission, setpermissionss] = useState(permissions ? [...new Set(permissions.split(','))] : []);
    const [userType, setuserType] = useState(+userTypes[0]);

    return (
        <>
            <div id="layoutSidenav_nav">
                <nav className="sidenav shadow-right sidenav-light">
                    <div className="sidenav-menu">
                        <div className="nav accordion" id="accordionSidenav">
                            <div className="sidenav-menu-heading"></div>
                            <>
                                {<a className="nav-link collapsed" href="/Dashboard">
                                    <div className="nav-link-icon"><i className="ri-layout-grid-line"></i></div>
                                    Dashboard</a>}


                                {(userType === 1) && <> <div className="nav-link collapsed" data-bs-toggle="collapse" data-bs-target="#collapseSubAdmin" aria-expanded="false" aria-controls="collapseSubAdmin">
                                    <div className="nav-link-icon"><i className="ri-group-line"></i></div>
                                    Sub Admin
                                    <div className="sidenav-collapse-arrow"><i className="fas fa-angle-down"></i></div>
                                </div>
                                    <div className="collapse" id="collapseSubAdmin" data-bs-parent="#accordionSidenav">
                                        <nav className="sidenav-menu-nested nav">
                                            <a className="nav-link" href="/SubAdminList">Sub Admin List</a>
                                            <a className="nav-link" href="/AddSubAdmin">Add New </a>
                                        </nav>
                                    </div> </>}
                                {(permission.includes('Users') || userType === 1) && <>   <div className="nav-link collapsed" data-bs-toggle="collapse" data-bs-target="#collapseTraders" aria-expanded="false" aria-controls="collapseTraders">
                                    <div className="nav-link-icon"><i className="ri-group-line"></i></div>
                                    Users
                                    <div className="sidenav-collapse-arrow"><i className="fas fa-angle-down"></i></div>
                                </div>
                                    <div className="collapse" id="collapseTraders" data-bs-parent="#accordionSidenav">
                                        <nav className="sidenav-menu-nested nav">
                                            <a className="nav-link" href="/UsersList">Users List</a>
                                            <a className="nav-link" href="/AddNewUser">Add New User </a>
                                        </nav>
                                    </div> </>}

                                {(permission.includes('KYCRequest') || userType === 1) && <>    <div className="nav-link collapsed" data-bs-toggle="collapse" data-bs-target="#collapseKyc" aria-expanded="false" aria-controls="collapseKyc">
                                    <div className="nav-link-icon"><i className="ri-shield-user-line"></i></div>
                                    KYC Request
                                    <div className="sidenav-collapse-arrow"><i className="fas fa-angle-down"></i></div>
                                </div>
                                    <div className="collapse" id="collapseKyc" data-bs-parent="#accordionSidenav">
                                        <nav className="sidenav-menu-nested nav">
                                            <a className="nav-link" href="/BankRequest">Bank Request</a>
                                            <a className="nav-link" href="/AadharRequest">Aadhar Request</a>
                                            <a className="nav-link" href="/UPIRequest">UPI Request</a>
                                            <a className="nav-link" href="/voteridRequest">Voter ID Request</a>
                                            <a className="nav-link" href="/DlRequest">Dl Request</a>



                                        </nav>
                                    </div></>}


                                {(permission.includes('KYCRequest') || userType === 1) &&
                                    <>
                                        <div className="nav-link collapsed" data-bs-toggle="collapse" data-bs-target="#collapsePanCard" aria-expanded="false" aria-controls="collapsePanCard">
                                            <div className="nav-link-icon">< i class="ri-bank-card-line"></i></div>
                                            Pan Card Request
                                            <div className="sidenav-collapse-arrow"><i className="fas fa-angle-down"></i></div>
                                        </div>
                                        <div className="collapse" id="collapsePanCard" data-bs-parent="#accordionSidenav">
                                            <nav className="sidenav-menu-nested nav">
                                                <a className="nav-link" href="/approved_pancard">Approved Request</a>
                                                <a className="nav-link" href="/pending_pancard">Pending Request</a>
                                                <a className="nav-link" href="/rejected_pancard">Rejected Request</a>
                                            </nav>
                                        </div>
                                    </>
                                }

                                {(permission.includes('CategoryManager') || userType === 1) && <>   <div className="nav-link collapsed" data-bs-toggle="collapse" data-bs-target="#collapseCategory" aria-expanded="false" aria-controls="collapseCategory">
                                    <div className="nav-link-icon"><i className="ri-list-check-2"></i></div>
                                    Category Manager
                                    <div className="sidenav-collapse-arrow"><i className="fas fa-angle-down"></i></div>
                                </div>
                                    <div className="collapse" id="collapseCategory" data-bs-parent="#accordionSidenav">
                                        <nav className="sidenav-menu-nested nav">
                                            <a className="nav-link" href="/CategoryList">Category List</a>
                                            <a className="nav-link" href="/AddCategory">Add New Category </a>
                                        </nav>
                                    </div></>}
                                {(permission.includes('ContestManager') || userType === 1) && <>     <div className="nav-link collapsed" data-bs-toggle="collapse" data-bs-target="#collapseContest" aria-expanded="false" aria-controls="collapseContest">
                                    <div className="nav-link-icon"><i className="ri-trophy-line"></i></div>
                                    Contest Manager
                                    <div className="sidenav-collapse-arrow"><i className="fas fa-angle-down"></i></div>
                                </div>
                                    <div className="collapse" id="collapseContest" data-bs-parent="#accordionSidenav">
                                        <nav className="sidenav-menu-nested nav">
                                            <a className="nav-link" href="/ContestList">Contest List</a>
                                            <a className="nav-link" href="/AddContest">Add New Contest </a>
                                        </nav>
                                    </div>
                                </>}
                                {(permission.includes('CricketModules') || userType === 1) && <>
                                    <div className="nav-link collapsed" data-bs-toggle="collapse" data-bs-target="#collapseCricket" aria-expanded="false" aria-controls="collapseCricket">
                                        <div className="nav-link-icon"><i className="ri-football-line"></i></div>
                                        Cricket Modules
                                        <div className="sidenav-collapse-arrow"><i className="fas fa-angle-down"></i></div>
                                    </div>
                                    <div className="collapse" id="collapseCricket" data-bs-parent="#accordionSidenav">
                                        <nav className="sidenav-menu-nested nav">
                                            <a className="nav-link" href="/SeriesContest">Series Contest</a>
                                            <a className="nav-link" href="/ScheduleContest">Schedule Contest </a>
                                            <a className="nav-link" href="/CrickerPoints">Points System </a>
                                            <a className="nav-link" href="/ManagePlayers"> Manage Player </a>
                                            <a className="nav-link" href="/TeamsManager">Teams Manager </a>
                                            <a className="nav-link" href="/PreSquadMathces">Pre Squad Matches </a>
                                        </nav>
                                    </div>
                                </>}
                                {(permission.includes('PaymentMethod') || userType === 1) && <>   <div className="nav-link collapsed" data-bs-toggle="collapse" data-bs-target="#collapseMethod" aria-expanded="false" aria-controls="collapseMethod">
                                    <div className="nav-link-icon"><i className="ri-bank-card-line"></i></div>
                                    Payment Method
                                    <div className="sidenav-collapse-arrow"><i className="fas fa-angle-down"></i></div>
                                </div>
                                    <div className="collapse" id="collapseMethod" data-bs-parent="#accordionSidenav">
                                        <nav className="sidenav-menu-nested nav">
                                            <a className="nav-link" href="/PaymentList">List</a>
                                            <a className="nav-link" href="/AddNewPayment">Add New </a>
                                        </nav>
                                    </div>
                                </>}
                                {(permission.includes('TDS') || userType === 1) && <>  <a className="nav-link collapsed" href="/TDS">
                                    <div className="nav-link-icon"><i className="ri-brackets-fill"></i></div>
                                    TDS

                                </a>
                                </>}
                                {(permission.includes('Transaction') || userType === 1) && <>  <a className="nav-link collapsed" href="/Transaction">
                                    <div className="nav-link-icon"><i className="ri-arrow-up-down-fill"></i></div>
                                    Transaction

                                </a>
                                </>}
                                {(permission.includes('WalletManager') || userType === 1) && <>  <a className="nav-link collapsed" href="/WalletManager">
                                    <div className="nav-link-icon"><i className="ri-wallet-3-line"></i></div>
                                    Wallet Manager

                                </a>
                                </>}
                                {(permission.includes('Earning') || userType === 1) && <> <a className="nav-link collapsed" href="/Earnings">
                                    <div className="nav-link-icon"><i className="ri-exchange-dollar-line"></i></div>
                                    Earning

                                </a> </>}
                                {(permission.includes('PaymentOffers') || userType === 1) && <>  <div className="nav-link collapsed" data-bs-toggle="collapse" data-bs-target="#collapseOffers" aria-expanded="false" aria-controls="collapseOffers">
                                    <div className="nav-link-icon"><i className="ri-bank-card-line"></i></div>
                                    Payment Offers
                                    <div className="sidenav-collapse-arrow"><i className="fas fa-angle-down"></i></div>
                                </div>
                                    <div className="collapse" id="collapseOffers" data-bs-parent="#accordionSidenav">
                                        <nav className="sidenav-menu-nested nav">
                                            <a className="nav-link" href="/List">List</a>
                                            <a className="nav-link" href="/AddNew">Add New </a>
                                        </nav>
                                    </div></>}
                                {(permission.includes('BannerManager') || userType === 1) && <>   <div className="nav-link collapsed" data-bs-toggle="collapse" data-bs-target="#collapseBanner" aria-expanded="false" aria-controls="collapseBanner">
                                    <div className="nav-link-icon"><i className="ri-image-line"></i></div>
                                    Banner Manager
                                    <div className="sidenav-collapse-arrow"><i className="fas fa-angle-down"></i></div>
                                </div>
                                    <div className="collapse" id="collapseBanner" data-bs-parent="#accordionSidenav">
                                        <nav className="sidenav-menu-nested nav">
                                            <a className="nav-link" href="/BannerList">List</a>
                                            <a className="nav-link" href="/AddBanner">Add New </a>
                                        </nav>
                                    </div></>}
                                {(permission.includes('WithdrawPending') || userType === 1) && <>  <a className="nav-link collapsed" href="/WithdrawPending">
                                    <div className="nav-link-icon"><i className="ri-wallet-3-line"></i></div>
                                    Withdraw (Pending)

                                </a> </>}
                                {(permission.includes('WithdrawConfirmed') || userType === 1) && <>  <a className="nav-link collapsed" href="/WithdrawConfirmed">
                                    <div className="nav-link-icon"><i className="ri-wallet-3-line"></i></div>
                                    Withdraw (Confirmed)

                                </a> </>}
                                {(permission.includes('Notification') || userType === 1) && <>      <div className="nav-link collapsed" data-bs-toggle="collapse" data-bs-target="#collapseNotification" aria-expanded="false" aria-controls="collapseNotification">
                                    <div className="nav-link-icon"><i className="ri-notification-3-line"></i></div>
                                    Notification
                                    <div className="sidenav-collapse-arrow"><i className="fas fa-angle-down"></i></div>
                                </div>
                                    <div className="collapse" id="collapseNotification" data-bs-parent="#accordionSidenav">
                                        <nav className="sidenav-menu-nested nav">
                                            <a className="nav-link" href="/EmailNotification">Email Notification </a>
                                            <a className="nav-link" href="/SmsNotification">SMS Notification </a>
                                            <a className="nav-link" href="/PushNotification">Push Notification </a>
                                        </nav>
                                    </div> </>}

                                {(permission.includes('ContentManager') || userType === 1) && <>  <div className="nav-link collapsed" data-bs-toggle="collapse" data-bs-target="#collapseContents" aria-expanded="false" aria-controls="collapseContents">
                                    <div className="nav-link-icon"><i className="ri-article-line"></i></div>
                                    Content Manager
                                    <div className="sidenav-collapse-arrow"><i className="fas fa-angle-down"></i></div>
                                </div>
                                    <div className="collapse" id="collapseContents" data-bs-parent="#accordionSidenav">
                                        <nav className="sidenav-menu-nested nav">
                                            <a className="nav-link" href="/ContentList">Content Managers List </a>
                                            <a className="nav-link" href="/AddContent">Add Content Manager </a>
                                        </nav>
                                    </div></>}

                                {(permission.includes('Settings') || userType === 1) && <>  <a className="nav-link collapsed" href="/Settings">
                                    <div className="nav-link-icon"><i className="ri-settings-2-line"></i></div>
                                    Settings
                                </a></>}

                                {(permission.includes('EmailTemplates') || userType === 1) && <> <a className="nav-link collapse" href="/EmailTemplate">
                                    <div className="nav-link-icon"><i className="ri-wallet-3-line  "></i></div>
                                    Email Templates
                                </a> </>}

                                {(userType === 1) && <>  <a className="nav-link collapsed" href="/Profile">
                                    <div className="nav-link-icon"><i className="ri-user-smile-line"></i></div>
                                    Profile
                                </a></>}

                                {(userType === 1) && <>  <a className="nav-link collapsed" href="/ChangePassword">
                                    <div className="nav-link-icon"><i className="ri-lock-password-line"></i></div>
                                    Change Password

                                </a> </>}
                            </>
                        </div>
                    </div>
                </nav>
            </div >
        </>
    )
}

export default Sidebar
