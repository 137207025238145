import React, { useEffect, useState } from 'react'
import ReactPaginate from 'react-paginate';
import AuthService from '../../api/services/HomeService';
import { alertErrorMessage, alertSuccessMessage, alertWarningMessage } from '../../Components/CustomAlertMessage';
import LoaderHelper from '../../Components/Loading/LoaderHelper';
import Header from '../Header'
import Sidebar from '../Sidebar'
import Moment from 'react-moment';

const SeriesContest = () => {
    const [seriesList, setseriesList] = useState([]);
    const [shortName, setshortName] = useState('');
    const [userId, setuserId] = useState();
    const [matchseries, setmatchseries] = useState();
    const [dates, setdate] = useState();
    const [time, settime] = useState();
    const [matchId, setmatchId] = useState();
    const [cid, setcid] = useState();
    useEffect(() => {
        handleMatchesSeries()
    }, []);

    const handleMatchesSeries = async () => {
        LoaderHelper.loaderStatus(true);
        await AuthService.GetSeriesList().then((result) => {
            if (result?.success) {
                LoaderHelper.loaderStatus(false);
                try {
                    setseriesList(result?.data)
                    // alertSuccessMessage('List Fetched Successfully!!');
                } catch {
                    alertErrorMessage(result.message);
                }
            } else {
                LoaderHelper.loaderStatus(false);
                alertErrorMessage(result.message);
                console.log('Something went wrong');
            }
        })
    };

    const MatcheStatus = async (id, status) => {
        await AuthService.SeriesStats(id, status).then((result) => {
            if (result?.success) {
                try {
                    handleMatchesSeries()
                } catch {
                }
            } else {

                console.log('Something went wrong');
            }
        })
    };
    const SeriesShrt = async (id, name) => {
        await AuthService.SeriesShrt(id, name).then((result) => {
            if (result?.success) {
                try {
                    handleMatchesSeries()
                } catch {
                }
            } else {

                console.log('Something went wrong');
            }
        })
    };
    const MatchesSeries = async (id) => {
        setcid(id)
        LoaderHelper.loaderStatus(true);
        await AuthService.MatchesSeries(id).then((result) => {
            if (result?.success) {
                LoaderHelper.loaderStatus(false);
                try {
                    setmatchseries(result?.data)
                } catch {
                }
            } else {
                LoaderHelper.loaderStatus(false);
                console.log('Something went wrong');
            }
        })
    };

    const MatchDate = async (id, matchId, data) => {
        let currentDate = Date.now()
        if(new Date(data).getTime() < new Date(currentDate).getTime()){
            alertWarningMessage('Date should be greater');
        }else{
        await AuthService.MatchDate(id, matchId, data).then((result) => {
            if (result?.success) {
                try {
                    MatchesSeries(id)
                    alertSuccessMessage('Updated')
                    setdate('')
                    settime('')
                } catch {
                }
            } else {

                console.log('Something went wrong');
            }
        })}
    };
    const MatchEdit = async ( matchId, data) => {
        await AuthService.MatchEdit( matchId, data).then((result) => {
            if (result?.success) {
                try {
                    MatchesSeries(cid)
                    alertSuccessMessage('Updated')
                } catch {
                }
            } else {

                console.log('Something went wrong');
            }
        })
    };

    const handleEdit = (data) => {
        setuserId(data?._id)
        setshortName(data?.abbr)
    }

 /* custom pagination */

 const [currentPage, setCurrentPage] = useState(1);
 const [itemsPerPage, setItemsPerPage] = useState(5);


 const handlePageChange = ({ selected }) => {
     setCurrentPage(selected + 1);
 };

 const pageCount = Math.ceil(seriesList.length / itemsPerPage);
 const currentItems = seriesList.slice(
     (currentPage - 1) * itemsPerPage,
     currentPage * itemsPerPage
 );
 // custom paginatio ends

    return (
        <>


                <div id="layoutSidenav_content">
                    <main>
                        <header class="page-header page-header-dark bg-gradient-primary-to-secondary pb-10">
                            <div class="container-xl px-4">
                                <div class="page-header-content pt-4">
                                    <div class="row align-items-center justify-content-between">
                                        <div class="col-auto mt-4">
                                            <h1 class="page-header-title">
                                                <div class="page-header-icon"><i class="ri-football-line"></i></div>
                                                Series List
                                            </h1>
                                            {/* <!-- <div class="page-header-subtitle">Example dashboard overview and content summary</div> --> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </header>
                        {/* <!-- Main page content-->
        <!-- Example Colored Cards for Dashboard Demo--> */}
                        <div class="container-xl px-4 mt-n10">

                            <div class="card mb-4">

                                <div class="card-body">
                                    <table id="datatablesSimple">
                                        <thead>
                                            <tr>
                                                <th>#</th>
                                                <th>Series Name</th>
                                                <th>Series Short Name</th>
                                                <th>Status</th>
                                                <th>Actions</th>
                                            </tr>
                                        </thead>
                                        <tfoot>
                                            <tr>
                                                <th>#</th>
                                                <th>Series Name</th>
                                                <th>Series Short Name</th>
                                                <th>Status</th>
                                                <th>Actions</th>
                                            </tr>
                                        </tfoot>
                                        <tbody>
                                            {currentItems ? currentItems?.map((data, index) => {
                                                        let objIndex;
                                                        objIndex = seriesList.findIndex(check => check?._id == data._id);
                                                return (
                                                    <tr>
                                                        <td>{objIndex + 1}</td>
                                                        <td>{data?.SeriesName}</td>
                                                        <td>{data?.abbr}</td>
                                                        <td>{data?.is_active === 1 ? <button type="btn" class="btn badge bg-success text-white rounded-pill" onClick={() => MatcheStatus(data?.cid, '0')}>Active</button> : <button type="btn" class="btn badge bg-danger text-white rounded-pill"
                                                            onClick={() => MatcheStatus(data?.cid, '1')}>Inactive</button>}</td>

                                                        <td>
                                                            {/* <button type="btn" class="btn badge bg-primary   text-white rounded-pill me-2" onClick={() => { MatchesSeries(data?.cid) }} data-bs-toggle="modal" data-bs-target="#editCategory2">Matches</button> */}
                                                            <button type="btn" class="btn badge bg-warning text-white rounded-pill mt-2"
                                                                data-bs-toggle="modal" data-bs-target="#editCategory"
                                                                onClick={() => { handleEdit(data) }} >Update Short Name</button>
                                                  
                                                        </td>
                                                    </tr>
                                                )
                                            }) : ''}

                                        </tbody>
                                    </table>
                                          {/* custom pagination */}
                                          <ReactPaginate
                                        pageCount={pageCount}
                                        pageRangeDisplayed={3}
                                        marginPagesDisplayed={2}
                                        onPageChange={handlePageChange}
                                        containerClassName={'customPagination'}
                                        activeClassName={'active'}
                                    />
                                </div>
                            </div>
                        </div>




                    </main>
                </div>
            <div class="modal" id="editCategory" tabIndex="-1" aria-labelledby="editwallrtlmodalTitle" style={{ display: 'none' }} aria-hidden="true">
                <div class="modal-dialog  alert_modal modal-dialog-centered" role="document">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="editwallrtlmodalTitle">Edit Details</h5>
                            <button class="btn-close" type="button" data-bs-dismiss="modal" aria-label="Close"></button>
                            <hr />
                        </div>
                        <div class="modal-body">

                            <form>
                                <div class="form-group  mb-3 position-relative ">
                                    <label for="category-name">Short Name <span class="required">*</span></label>
                                    <input type="text" name="category_name" maxLength="25" class="form-control" placeholder="Short Name" id="category-name"
                                        onChange={(e) => { setshortName(e.target.value) }}
                                        value={shortName}
                                    />
                                </div>

                                <div class="form-group  position-relative mt-4">
                                    <button data-bs-dismiss="modal" class="btn btn-primary   btn-block w-100" type="button" onClick={() => { SeriesShrt(userId, shortName) }} >Update</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal" id="editCategory2" tabIndex="-1" aria-labelledby="editwallrtlmodalTitle" style={{ display: 'none' }} aria-hidden="true">
                <div class="modal-dialog  alert_modal modal-lg" role="document">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="editwallrtlmodalTitle">Edit Details</h5>
                            <button class="btn-close" type="button" data-bs-dismiss="modal" aria-label="Close"></button>
                            <hr />
                        </div>
                        <div class="modal-body">
                            <div class="card-body">
                                <table class="table table-bordered" >
                                    <thead>
                                        <tr>
                                            <th>Match</th>
                                            <th>Date</th>
                                            {/* <th>Time</th> */}
                                            <th>Status</th>

                                        </tr>
                                    </thead>

                                    <tbody>
                                        {matchseries ? matchseries?.map((data, index) => {
                                            let date = data?.Date ?   data?.Date.split(" "):'00 00'

                                            const handleDate = (e, id) => {
                                                setmatchId(id)
                                                setdate(e.target.value)
                                            }
                                            const handleTime = (e, id) => {
                                                setmatchId(id)
                                                settime(e.target.value)
                                            }

                                            return (
                                                <tr>
                                                    <td>{data?.title}</td>
                                                    <td> <Moment date={data?.date_start_ist} format="YYYY/MM/DD : h:mm A" /></td>
                                                    {/* <td>
                                                        <div className='input-group' >
                                                            <input type="date" name="category_name" maxLength="25" class="form-control" placeholder="Full Date" id="category-name"
                                                              
                                                                onChange={(e) => { handleDate(e, data?.MatchId) }}
                                                                value={dates && matchId == data?.MatchId ? dates : date[0]}
                                                            /><button type="btn" class="btn bg-primary text-white " onClick={() => { MatchDate(matchseries?._id, data?.MatchId, `${(dates ? dates.toString() : date[0].toString()) + " " + (time ? time.toString() : date[1].toString())}`) }} >Update</button>
                                                        </div>
                                                        <hr />
                                                        <div className='input-group' >
                                                            <input type="text" name="category_name" maxLength="25" class="form-control" placeholder="Full Time" id="category-name"
                                                               
                                                                onChange={(e) => { handleTime(e, data?.MatchId) }}
                                                                value={time && matchId == data?.MatchId ? time : date[1]}
                                                            />
                                                            <button type="btn" class="btn   bg-primary text-white " onClick={() => { MatchDate(matchseries?._id, data?.MatchId, `${(dates ? dates.toString() : date[0].toString()) + " " + (time ? time.toString() : date[1].toString())}`) }}>Update</button>
                                                        </div>
                                                    </td> */}
                                                    <td>{data?.is_active === 1 ? <button type="btn" class="btn badge bg-success text-white rounded-pill"
                                                        onClick={() => { MatchEdit( data?.match_id, 0) }}
                                                    >Active</button> : <button type="btn" class="btn badge bg-danger text-white rounded-pill"
                                                        onClick={() => { MatchEdit( data?.match_id, 1) }}
                                                    >Inactive</button>}</td>



                                                </tr>
                                            )
                                        }) : ''}

                                    </tbody>
                                </table>
                                
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default SeriesContest
