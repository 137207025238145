import React, { useEffect, useState } from 'react'
import Moment from 'react-moment'
import ReactPaginate from 'react-paginate'
import Swal from 'sweetalert2'
import { ApiConfig } from '../../api/apiConfig/ApiConfig'
import AuthService from '../../api/services/HomeService'
import { alertErrorMessage, alertSuccessMessage } from '../../Components/CustomAlertMessage'
import LoaderHelper from '../../Components/Loading/LoaderHelper'

const SubAdminList = () => {
    const [subAdmin, setsubAdmin] = useState([]);
    const [fName, setfName] = useState('');
    const [lName, setlName] = useState('');
    const [email, setemail] = useState('');
    const [number, setnumber] = useState('');
    const [dob, setdob] = useState();
    const [gender, setgender] = useState();
    const [permission, setpermission] = useState([]);
    const [profilePic, setprofilePic] = useState();
    const [userId, setuserId] = useState();
    let availablePermissions = ['Users', 'KYCRequest', 'CategoryManager', 'ContestManager', 'CricketModules', 'PaymentMethod', 'TDS', 'Transaction', 'WalletManager', 'Earning', 'PaymentOffers', 'BannerManager', 'WithdrawPending', 'WithdrawConfirmed', 'Notification', 'ContentManager', 'Settings', 'EmailTemplates',]
    useEffect(() => {
        subAdminList()
    }, []);


    const subAdminList = async () => {
        LoaderHelper.loaderStatus(true);
        await AuthService.getSubadmin().then((result) => {
            if (result?.success) {
                try {
                    LoaderHelper.loaderStatus(false);
                    setsubAdmin(result?.data.reverse())
                } catch {
                    LoaderHelper.loaderStatus(false);
                }
            } else {
                LoaderHelper.loaderStatus(false);
                alertErrorMessage(result.message);
            }
        })
    };


    const deleteSubadmin = async (id) => {
        await Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!  <i className="fa fa-trash" aria-hidden="true"></i>'
        }).then(async (result) => {
            if (result.isConfirmed) {
                await AuthService.deleteSubadmin(id).then((result) => {
                    if (result?.success) {
                        try {
                            subAdminList()
                        } catch {

                        }
                    } else {
                        alertErrorMessage(result?.message)
                    }
                })
            }
        })

    };

    const EditSubadmin = async (userId, fName, lName, email, dob, gender, number, permission, profilePic) => {
        var formData = new FormData();
        formData.append('_id', userId);
        formData.append('FirstName', fName);
        formData.append('LastName', lName);
        formData.append('Email', email);
        formData.append('MobileNo', number);
        formData.append('dob', dob);
        formData.append('Gender', gender);
        formData.append('permissions', permission);
        formData.append('imagepath', profilePic);

        await AuthService.EditSubadmin(formData).then((result) => {
            if (result?.success) {
                try {
                    alertSuccessMessage('Subadmin Updated successfully')
                    subAdminList()
                } catch {
                    console.log(result?.msg);
                }
            } else {
                alertErrorMessage(result?.message)
                console.log('Something went wrong');
            }
        })
    };

    const Subadminstatus = async (id, status) => {
        await AuthService.Subadminstatus(id, status).then((result) => {
            if (result?.success) {
                try {
                    subAdminList()
                } catch {
                }
            } else {

            }
        })
    };

    const handleEditDetails = (data) => {
        setuserId(data?._id)
        setfName(data?.FirstName)
        setlName(data?.LastName)
        setemail(data?.Email)
        setnumber(data?.MobileNo)
        setgender(data?.Gender)
        setdob(data?.dob)
        setpermission(data?.permissions[0] ? data?.permissions[0].split(',') : '')

    }
    const filterPermission = (data) => {
        let filterd = permission?.filter((data1) => {
            return data1 !== data
        })
        setpermission(filterd)
    }


    /* Custom Pagination */
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(5);
    const handlePageChange = ({ selected }) => {
        setCurrentPage(selected + 1);
    };
    const pageCount = Math.ceil(subAdmin.length / itemsPerPage);
    const currentItems = subAdmin.slice(
        (currentPage - 1) * itemsPerPage,
        currentPage * itemsPerPage
    );
    // Custom Pagination Ends
    const handlePermission = (e) => {
        if (e.target.checked) {
            setpermission([...permission, e.target.value])
        } else {
            filterPermission(e.target.value)
        }
    };
    return (
        <>


            <div id="layoutSidenav_content">
                <main>
                    <header className="page-header page-header-dark bg-gradient-primary-to-secondary pb-10">
                        <div className="container-xl px-4">
                            <div className="page-header-content pt-4">
                                <div className="row align-items-center justify-content-between">
                                    <div className="col-auto mt-4">
                                        <h1 className="page-header-title">
                                            <div className="page-header-icon"><i data-feather="users"></i></div>
                                            Sub Admin
                                        </h1>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </header>
                    <div className="container-xl px-4 mt-n10">
                        <div className="card mb-4">
                            <div className="card-body">
                                <table id="datatablesSimple">
                                    <thead>
                                        <tr>
                                            <th>#</th>
                                            <th>Registration Date</th>
                                            <th>Name</th>
                                            <th>Email</th>
                                            <th>Image</th>
                                            <th>Phone Number</th>
                                            <th>Gender </th>
                                            <th>DOB</th>
                                            <th>Status</th>
                                            <th>Actions</th>
                                        </tr>
                                    </thead>
                                    <tfoot>
                                        <tr>
                                            <th>#</th>
                                            <th>Registration Date</th>
                                            <th>Name</th>
                                            <th>Email</th>
                                            <th>Image</th>
                                            <th>Phone Number</th>
                                            <th>Gender </th>
                                            <th>DOB</th>
                                            <th>Status</th>
                                            <th>Actions</th>
                                        </tr>
                                    </tfoot>
                                    <tbody>
                                        {currentItems ? currentItems?.map((data, index) => {
                                            let objIndex;
                                            objIndex = subAdmin.findIndex(check => check?._id == data._id);
                                            return (
                                                <tr key={index} >
                                                    <td>{objIndex + 1}</td>
                                                    <td><Moment date={data?.createdAt} format="YYYY/MM/DD HH:mm" /></td>
                                                    <td>{data?.FirstName}</td>
                                                    <td>{data?.Email}</td>
                                                    <td className="text-start"> <img src={`${ApiConfig?.appUrl + 'uploads/' + data?.imagepath}`} hight="150px" width="60px" alt="" /> </td>
                                                    <td>{data?.MobileNo}</td>
                                                    <td>{data?.Gender}</td>
                                                    <td>{data?.dob}</td>
                                                    {data?.active === '1' ? <td><button type="btn" className="btn badge bg-success text-white rounded-pill" onClick={() => { Subadminstatus(data?._id, "0") }} >Active</button></td> : <td><button type="btn" className="btn badge bg-danger text-white rounded-pill" onClick={() => { Subadminstatus(data?._id, "1") }} >Inactive</button></td>}
                                                    <td>
                                                        <div className="table_action_group">
                                                            <button type="btn" className="btn badge bg-success text-white rounded-pill" onClick={() => { handleEditDetails(data) }}
                                                                data-bs-toggle="modal" data-bs-target="#editAdmin"
                                                            >Edit</button >
                                                            <button type="btn" className="btn badge bg-danger text-white rounded-pill" onClick={() => { deleteSubadmin(data?._id) }}>Delete</button>
                                                        </div>
                                                    </td>
                                                </tr>

                                            )
                                        }) : ''}
                                    </tbody>
                                </table>
                                <ReactPaginate
                                    pageCount={pageCount}
                                    pageRangeDisplayed={3}
                                    marginPagesDisplayed={2}
                                    onPageChange={handlePageChange}
                                    containerClassName={'customPagination'}
                                    activeClassName={'active'} />
                            </div>
                        </div>
                    </div>
                </main>
            </div>
            <div className="modal" id="editAdmin" tabIndex="-1" aria-labelledby="editwallrtlmodalTitle" style={{ display: 'none' }} aria-hidden="true">
                <div className="modal-dialog  alert_modal modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="editwallrtlmodalTitle">Edit User Details</h5>
                            <button className="btn-close" type="button" data-bs-dismiss="modal" aria-label="Close"></button>
                            <hr />
                        </div>
                        <div className="modal-body">

                            <form>
                                <div className="form-group  mb-3 position-relative ">
                                    <label className="small mb-1"> Fisrt Name </label>
                                    <input type="text" name="first_name" maxLength="30" className="form-control" placeholder="First Name" id="first-name"
                                        value={fName} onChange={(e) => { setfName(e.target.value) }}
                                    />
                                </div>
                                <div className="form-group  mb-3 position-relative ">
                                    <label htmlFor="first-name">Last Name </label>
                                    <input type="text" name="first_name" maxLength="30" className="form-control" placeholder="First Name" id="first-name" value={lName} onChange={(e) => { setlName(e.target.value) }} />
                                </div>
                                <div className="form-group  mb-3 position-relative ">
                                    <label htmlFor="email">Email</label>
                                    <input type="email" name="email" className="form-control" placeholder="E-Mail Address" maxLength="255" id="email" value={email} onChange={(e) => { setemail(e.target.value) }} />
                                </div>
                                <div className="form-group  mb-3 position-relative ">
                                    <label htmlFor="date-of-bith">Date of Birth</label>
                                    <input type="date" name="date_of_bith" className="form-control DOB" placeholder="Date of Birth" maxLength="50" id="date-of-bith" value={dob} onChange={(e) => { setdob(e.target.value) }} />
                                </div>
                                <div className="form-group  mb-3 position-relative ">
                                    <label htmlFor="gender">Gender</label>
                                    <select name="gender" className="form-control form-select " id="gender" value={gender} onChange={(e) => { setgender(e.target.value) }} defaultValue='Select Gender'>
                                        <option value="Male">Male</option>
                                        <option value="Female">Female</option>
                                    </select>
                                </div>
                                <div className="form-group  mb-3 position-relative ">
                                    <label htmlFor="phone">Phone Number</label>
                                    <input type="number" name="phone" className="form-control" placeholder="Phone Number" maxLength="20" id="phone" value={number} onChange={(e) => { setnumber(e.target.value) }} />
                                </div>
                                <div className="form-group">
                                    <div className="input select">
                                        <label htmlFor="gender">Permission <span className="required">*</span> </label>

                                        <div className='d-flex mx-2'>
                                            <div>
                                                {availablePermissions?.map((data) => {
                                                    return (
                                                        <>
                                                            <input type="checkbox" value={data} id={data} checked={permission?.includes(data) || permission[0] === 'all'} onChange={handlePermission} /> <label for={data}>{data}</label> <br />
                                                        </>
                                                    )
                                                })}

                                                {/* <input type="checkbox" value="MyNfts" id='MyNfts' onChange={handlePermission} /><label for="MyNfts">My Nft's</label><br />
                                                <input type="checkbox" value="ReportedCollection" id='ReportedCollection' onChange={handlePermission} /><label for="ReportedCollection">Reported Collection</label><br />
                                                <input type="checkbox" value="Users" id='Users' onChange={handlePermission} /><label for="Users">Users</label><br />
                                                <input type="checkbox" value="KYCRequest" id='KYCRequest' onChange={handlePermission} /> <label for="KYCRequest">KYC Request</label><br />
                                                <input type="checkbox" value="CategoryManager" id='CategoryManager' onChange={handlePermission} /> <label for="CategoryManager">Category Manager</label><br />
                                                <input type="checkbox" value="ContestManager" id='ContestManager' onChange={handlePermission} /><label for="ContestManager">Contest Manager</label><br />
                                                <input type="checkbox" value="CricketModules" id='CricketModules' onChange={handlePermission} /><label for="CricketModules"> Cricket Modules</label><br />
                                                <input type="checkbox" value="PaymentMethod" id='PaymentMethod' onChange={handlePermission} /><label for="PaymentMethod">Payment Method</label><br />
                                                <input type="checkbox" value="TDS" id='TDS' onChange={handlePermission} /><label for="TDS">TDS</label><br />
                                                <input type="checkbox" value="Transaction" id='Transaction' onChange={handlePermission} /><label for="Transaction">Transaction</label><br /> */}
                                            </div>
                                            {/* <div className=' mx-5'>
                                                <input type="checkbox" value="WalletManager" id='WalletManager' onChange={handlePermission} /><label for="WalletManager">Wallet Manager</label><br />
                                                <input type="checkbox" value="Earning" id='Earning' onChange={handlePermission} /><label for="Earning">Earning</label><br />
                                                <input type="checkbox" value="PaymentOffers" id='PaymentOffers' onChange={handlePermission} /><label for="PaymentOffers">Payment Offers</label><br />
                                                <input type="checkbox" value="BannerManager" id='BannerManager' onChange={handlePermission} /><label for="BannerManager">Banner Manager</label><br />
                                                <input type="checkbox" value="WithdrawPending" id='WithdrawPending' onChange={handlePermission} /><label for="WithdrawPending"> Withdraw (Pending)</label><br />
                                                <input type="checkbox" value="WithdrawConfirmed" id='WithdrawConfirmed' onChange={handlePermission} /><label for="WithdrawConfirmed">Withdraw (Confirmed)</label><br />
                                                <input type="checkbox" value="Notification" id='Notification' onChange={handlePermission} /><label for="Notification">Notification</label><br />
                                                <input type="checkbox" value="ContentManager" id='ContentManager' onChange={handlePermission} /><label for="ContentManager">Content Manager</label><br />
                                                <input type="checkbox" value="Settings" id='Settings' onChange={handlePermission} /><label for="Settings">Settings</label><br />
                                                <input type="checkbox" value="EmailTemplates" id='EmailTemplates' onChange={handlePermission} /><label for="EmailTemplates"> Email Templates</label><br />
                                            </div> */}
                                        </div>
                                    </div>
                                </div>
                                <div className="form-group  mb-3 position-relative ">
                                    <label htmlFor="profile-picture">Profile Picture</label>
                                    <div className="input file">
                                        <input type="file" name="image" className="form-control" accept="image/*" id="image" onChange={(e) => { setprofilePic(e.target.files[0]) }} />
                                    </div>
                                </div>
                                <div className="form-group  position-relative mt-4">
                                    <button data-bs-dismiss="modal" className="btn btn-primary   btn-block w-100" type="button" onClick={() => { EditSubadmin(userId, fName, lName, email, dob, gender, number, permission, profilePic) }} >Submit Details</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}

export default SubAdminList
