import React, { useEffect, useRef, useState } from 'react'
import AuthService from '../../api/services/HomeService';
import { alertErrorMessage, alertSuccessMessage } from '../../Components/CustomAlertMessage';
import LoaderHelper from '../../Components/Loading/LoaderHelper';
import { ApiConfig } from '../../api/apiConfig/ApiConfig';
import 'select2/dist/css/select2.min.css';
import 'select2';
import { $ } from 'react-jquery-plugin';

const ManagePlayers = () => {
    const [seriesList, setseriesList] = useState();
    const [cid, setcid] = useState();
    const [teamsList, setteamsList] = useState();
    const [players, setplayers] = useState();
    const [players2, setplayers2] = useState();
    const [playerRating, setplayerRating] = useState();
    const [playerRole, setplayerRole] = useState();
    const [playerImage, setplayerImage] = useState();
    const [pid, setpid] = useState();
    const [tid, settid] = useState();
    useEffect
        (() => {
            GetSeriesList()
        }, []);

    const GetSeriesList = async () => {
        LoaderHelper.loaderStatus(true);
        await AuthService.GetSeriesList().then((result) => {
            if (result?.success) {
                try {
                    LoaderHelper.loaderStatus(false);
                    setseriesList(result?.data)
                    GetTeamsList(result?.data?.[0]?.cid)
                } catch {
                    LoaderHelper.loaderStatus(false);
                    alertErrorMessage(result.message);
                }
            } else {
                LoaderHelper.loaderStatus(false);
                alertErrorMessage(result.message);
            }
        })
    };
    const GetTeamsList = async (id) => {
        setcid(id)
        LoaderHelper.loaderStatus(true);
        await AuthService.GetTeamsList(id).then((result) => {
            if (result?.success) {
                try {
                    LoaderHelper.loaderStatus(false);
                    setteamsList(result?.data?.[0]?.teams)
                    Getteamplayer(result?.data?.[0]?.teams[0]?.tid,id)
                } catch {
                    LoaderHelper.loaderStatus(false);
                    alertErrorMessage(result.message);
                }
            } else {
                LoaderHelper.loaderStatus(false);
                alertErrorMessage(result.message);
            }
        })
    };
    const Getteamplayer = async (id,cid) => {
        settid(id)
        LoaderHelper.loaderStatus(true);
        await AuthService.Getteamplayer(id,cid).then((result) => {
            if (result?.success) {
                try {
                    LoaderHelper.loaderStatus(false);
                    setplayers(result?.data)
                    setplayers2(result?.data?.players)
                } catch {
                    LoaderHelper.loaderStatus(false);
                    alertErrorMessage(result.message);
                }
            } else {
                LoaderHelper.loaderStatus(false);
                alertErrorMessage(result.message);
            }
        })
    };

    const editplayer = async (team_id, pid, playerRating, playerRole, playerImage) => {
        await AuthService.editplayer(team_id, pid, playerRating, playerRole, playerImage).then((result) => {
            if (result?.success) {
                try {
                    LoaderHelper.loaderStatus(false);
                    alertSuccessMessage('Player Edited Successfully!!')
                    setplayerImage('');
                    Getteamplayer(tid,cid)

                } catch {
                    LoaderHelper.loaderStatus(false);
                    alertErrorMessage(result.message);
                }
            } else {
                LoaderHelper.loaderStatus(false);
                alertErrorMessage(result.message);
            }
        })
    };
    const upload = async (playerImage) => {
        LoaderHelper.loaderStatus(true);
        var formData = new FormData();
        formData.append('file', playerImage);
        await AuthService.upload(formData).then((result) => {
            if (result?.success) {
                try {
                    LoaderHelper.loaderStatus(false);
                    setplayerImage(result?.data)

                } catch {
                    LoaderHelper.loaderStatus(false);
                    alertErrorMessage(result.message);
                }
            } else {
                LoaderHelper.loaderStatus(false);
                alertErrorMessage(result.message);
            }
        })
    };
    const handleSearch = (e) => {
        let arr = players?.players
        let filterd = arr?.filter((data) => {
            return data?.title?.toLowerCase().includes(e.target.value?.toLowerCase())
        })
        setplayers2(filterd)

    }
    const handleEditDetails = (data) => {
        setplayerRating(data?.fantasy_player_rating)
        setplayerRole(data?.playing_role)
        setpid(data?.pid)
    }

    // ****** Onchange Function For Selectbox Dropdown *********//
    const selectRef = useRef(null);
    const selectRef2 = useRef(null);

    useEffect(() => {
        $(selectRef.current).select2();
        $(selectRef.current).on('select2:select', (e) => {
            const selectedValue = e.params.data.id;
            GetTeamsList(selectedValue);
        });
        return () => {
            $(selectRef.current).off('select2:select');
        };
    }, []);

    useEffect(() => {
        $(selectRef2.current).select2();
        $(selectRef2.current).on('select2:select', (e) => {
            const selectedValue = e.params.data.id;
            Getteamplayer(selectedValue,cid);
        });
        return () => {
            $(selectRef2.current).off('select2:select');
        };
    }, [teamsList]);

    return (
        <>

            <div id="layoutSidenav_content">
                <main>
                    <header class="page-header page-header-dark bg-gradient-primary-to-secondary pb-10">
                        <div class="container-xl px-4">
                            <div class="page-header-content pt-4">
                                <div class="row align-items-center justify-content-between">
                                    <div class="col-auto mt-4">
                                        <h1 class="page-header-title">
                                            <div class="page-header-icon"><i class="ri-football-line"></i></div>
                                            Players List
                                        </h1>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </header>
                    <div class="container-xl px-4 mt-n10">
                        <div class="card mb-4">
                            <div class="card-body">
                                <div class=" filter_bar mb-4 mb-md-5">
                                    <form method="get" accept-charset="utf-8" novalidate="novalidate" class="form-inline search_form" action="/admin/users/referraldata">
                                        <div class="row gx-2 align-items-center">
                                            <div class="form-group  mb-0 col">
                                                <div class="input text">

                                                    <select name="type" ref={selectRef} class="form-control py-3 form-select team_type" onChange={(e) => { GetTeamsList(e.target.value) }} defaultValue={seriesList?.[0]?.SeriesName}>

                                                        {seriesList ? seriesList?.map((data) => {
                                                            return (
                                                                <option value={data?.cid}>{data?.SeriesName}</option>
                                                            )
                                                        }) : ''}
                                                    </select>

                                                </div>
                                            </div>
                                            <div class="form-group  mb-0 col">
                                                <div class="input text">

                                                    <select ref={selectRef2} name="team" class="form-control  form-select team_name" >
                                                        {teamsList ? teamsList?.map((data) => {
                                                            return (
                                                                <option value={data?.tid}>{data?.title}</option>
                                                            )
                                                        }) : ''}
                                                    </select>

                                                </div>
                                            </div>
                                            <div class="form-group  mb-0 col">
                                                <div class="input text">
                                                    <input type="text" name="player_name" class="form-control player_name" placeholder="Search Player Name" empty="Select Team" id="player-name" onChange={(e) => { handleSearch(e) }} />
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>


                                <table id="datatablesSimple">
                                    <thead>
                                        <tr>
                                            <th>#</th>
                                            <th>Team</th>
                                            <th>Name</th>
                                            <th>Role </th>
                                            <th>Rating</th>
                                            <th>Nationality</th>
                                            <th>Type</th>
                                            <th>Action</th>

                                        </tr>
                                    </thead>
                                    <tfoot>
                                        <tr>
                                            <th>#</th>
                                            <th>Team</th>
                                            <th>Name</th>
                                            <th>Role </th>
                                            <th>Rating</th>
                                            <th>Nationality</th>
                                            <th>Type</th>
                                            <th>Action</th>
                                        </tr>
                                    </tfoot>
                                    <tbody>
                                        {players2 ? players2.map((data, index) => {
                                            return (
                                                <tr>
                                                    <td>{index + 1}</td>
                                                    <td>
                                                        <div className='vs_team' >
                                                            <img src={players?.team?.thumb_url} width="30" height="30" alt="" />
                                                            <span>{players?.title}</span>
                                                        </div>
                                                    </td>
                                                    <td><div className='vs_team' >
                                                        {data?.logo_url ? <img src={`${ApiConfig?.appUrl2}${data?.logo_url}`} width="30" height="30" style={{ borderRadius: "50%" }} alt="" /> : ''}

                                                        <span>{data?.title}</span>
                                                    </div>
                                                    </td>
                                                    {/* <td class="text-center">  </td> */}
                                                    <td>{data?.playing_role}</td>
                                                    <td>{data?.fantasy_player_rating}</td>
                                                    <td>{data?.nationality}</td>
                                                    <td>{players?.team?.type}</td>
                                                    <td>
                                                        <button class="btn btn-datatable btn-icon btn-transparent-dark me-2 p-3" title="Edit User" data-bs-toggle="modal" data-bs-target="#editCategory" onClick={() => { handleEditDetails(data); setplayerImage(''); }} ><i class="fi-xl ri-edit-box-line"></i>
                                                        </button>
                                                        {/* <button class="btn btn-datatable btn-icon btn-transparent-dark p-3" title="View User"><i class="ri-eye-line"></i></button> */}
                                                        {/* <button class="btn btn-datatable btn-icon btn-transparent-dark p-3" title="Delete"><i class="ri-delete-bin-line"></i></button> */}
                                                    </td>
                                                </tr>
                                            )
                                        }) : ''}

                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>




                </main>
            </div>

            <div class="modal" id="editCategory" tabIndex="-1" aria-labelledby="editwallrtlmodalTitle" style={{ display: 'none' }} aria-hidden="true">
                <div class="modal-dialog  alert_modal modal-dialog-centered" role="document">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="editwallrtlmodalTitle">Edit Player Details</h5>
                            <button class="btn-close" type="button" data-bs-dismiss="modal" aria-label="Close"></button>
                            <hr />
                        </div>
                        <div class="modal-body">

                            <form>

                                <div class="form-group  mb-3 position-relative ">
                                    <label for="description">Player Rating</label>
                                    <input type="text" name="description" maxLength="150" class="form-control" placeholder="Player Rating" id="description" value={playerRating} onChange={(e) => { setplayerRating(e.target.value) }} />
                                </div>
                                <div class="form-group  mb-3 position-relative ">
                                    <label for="sequence">Player Role</label>
                                    <select name='category' value={playerRole} onChange={(e) => { setplayerRole(e.target.value) }}
                                        className='form-select'  >
                                        <option selected disabled>{playerRole}</option>
                                        {playerRole !== 'wk' && <option value='wk'>WicketKeeper</option>}
                                        {playerRole !== 'all' && <option value='all' >AllRounder</option>}
                                        {playerRole !== 'bat' && <option value='bat'>Batsman</option>}
                                        {playerRole !== 'bowl' && <option value='bowl'>Bowler</option>}
                                    </select>
                                    {/* <input type="text" name="sequence" maxLength="5" class="form-control" placeholder="Player Role" id="sequence" value={playerRole} onChange={(e) => { setplayerRole(e.target.value) }} /> */}
                                </div>
                                <div class="form-group  mb-3 position-relative ">
                                    <label for="sequence">Player Image</label>
                                    <input type="file" class="form-control" id="sequence" onChange={(e) => { upload(e.target.files[0]) }} />
                                </div>
                                <div class="form-group  position-relative mt-4">
                                    <button data-bs-dismiss="modal" class="btn btn-primary   btn-block w-100" type="button" onClick={() => { editplayer(players?.team_id, pid, playerRating, playerRole, playerImage) }} >Submit Details</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}

export default ManagePlayers
