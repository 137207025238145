import React, { useEffect, useState } from 'react'
import LoaderHelper from '../../Components/Loading/LoaderHelper';
import { alertErrorMessage, alertSuccessMessage } from '../../Components/CustomAlertMessage';
import AuthService from '../../api/services/HomeService';
import Moment from 'react-moment';
import { ApiConfig } from '../../api/apiConfig/ApiConfig';

const BankRequest = () => {
    const [bank, setbank] = useState([]);
    const [accNumber, setaccNumber] = useState();
    const [ifsc, setifsc] = useState();
    const [branch, setbranch] = useState();
    const [bankName, setbankName] = useState();
    const [bankImage, setbankImage] = useState();
    const [bankImage2, setbankImage2] = useState();
    const [bankStatus, setbankStatus] = useState();
    const [bankId, setbankId] = useState();
    const [userId, setuserId] = useState("");
    const [skip, setSkip] = useState(0);
    const [listType, setListType] = useState(1);
    const [lastLength, setLastLength] = useState(0);

    useEffect(() => {
        listBank(0, 1)
    }, []);
    const listBank = async (skip, type) => {
        LoaderHelper.loaderStatus(true);
        await AuthService.listBank(skip, 20, type).then((result) => {
            if (result?.success) {
                LoaderHelper.loaderStatus(false);
                try {
                    setLastLength(result?.no_of_user)
                    setListType(type)
                    setSkip(skip);
                    setbank(result?.data)
                } catch {
                    alertErrorMessage(result.message);
                }
            } else {
                LoaderHelper.loaderStatus(false);
                alertErrorMessage(result.message);
            }
        });
    };

    const handleBankImput = (e) => {
        switch (e.target.name) {
            case "bankNumber":
                setaccNumber(e.target.value);
                break;
            case "bankCode":
                setifsc(e.target.value);
                break;
            case "branch":
                setbranch(e.target.value);
                break;
            case "bakName":
                setbankName(e.target.value);
                break;
            case "bankImage":
                setbankImage(e.target.files[0]);
                setbankImage2(URL.createObjectURL(e.target.files[0]));
                break;

            default:
                break;
        }
    };

    const Viewbankdetails = async (id) => {
        setuserId(id);
        setaccNumber("");
        setbankName("");
        setbranch("");
        setifsc("");
        setbankStatus("");
        setbankImage();
        setbankImage2();
        LoaderHelper.loaderStatus(true)
        await AuthService.Viewbankdetails(id).then((result) => {
            if (result?.success) {
                LoaderHelper.loaderStatus(false)
                try {
                    setaccNumber(result?.data[0]?.account_number);
                    setbankName(result?.data[0]?.bank_name);
                    setbranch(result?.data[0]?.branch_name);
                    setifsc(result?.data[0]?.ifsc);
                    setbankStatus(result?.data[0]?.bank_verified);
                    setbankImage(result?.data[0]?.Bankimagepath);
                    setbankId(result?.data[0]?._id);
                } catch { }
            } else {
                LoaderHelper.loaderStatus(false)
            }
        });
    };

    const verifyBank = async (id, status, userid) => {
        await AuthService.verifyBank(id, status, userid).then((result) => {
            if (result?.success) {
                try {
                    alertSuccessMessage("Bank Status Updated Successfully!!");
                    listBank(skip, listType)

                } catch {
                    alertErrorMessage(result.message);
                }
            } else {
                // alertErrorMessage(result.message);
            }
        });
    };

    const editbnkdtl = async (accNumber, ifsc, branch, bankName, bankImage) => {
        var formData = new FormData();
        formData.append("_id", bankId);
        formData.append("UserId", userId);
        formData.append("AccountNumber", accNumber);
        formData.append("ifsc", ifsc);
        formData.append("Branch", branch);
        formData.append("BankName", bankName);
        formData.append("Bankimagepath", bankImage);
        formData.append("bankverified", '0');
        await AuthService.editbnkdtl(formData).then((result) => {
            if (result?.success) {
                try {
                    alertSuccessMessage('Bank Details Updated Successfully!!');
                    Viewbankdetails(userId)
                } catch {
                    alertErrorMessage(result?.message);
                }
            } else {
                alertErrorMessage(result?.message);
                console.log("Something went wrong");
            }
        });
    };
    const dltbankdetails = async (id, userId) => {
        await AuthService.dltbankdetails(id, userId).then((result) => {
            if (result?.success) {
                try {
                    alertSuccessMessage("Bank Account Deleted Successfully!!");
                    setaccNumber("");
                    setifsc("");
                    setbranch("");
                    setbankName("");
                    setbankImage();
                    setbankStatus('');
                    listBank(skip, listType)

                } catch {
                    alertErrorMessage(result.message);
                }
            } else {
                // alertErrorMessage(result.message);
            }
        });
    };
    const filter = (e) => {
        let uncheck = document.getElementById("pending");
        uncheck.checked = false
        if (e.target.checked) {
            listBank(0, 2)
        } else {
            listBank(0, 1)
        }
    };
    const filter2 = (e) => {
        let uncheck = document.getElementById("unverified");
        uncheck.checked = false
        if (e.target.checked) {
            listBank(0, 0)
        } else {
            listBank(0, 1)
        }
    };
    return (
        <>
            <div id="layoutSidenav_content">
                <main>
                    <header class="page-header page-header-dark bg-gradient-primary-to-secondary pb-10">
                        <div class="container-xl px-4">
                            <div class="page-header-content pt-4">
                                <div class="row align-items-center justify-content-between">
                                    <div class="col-auto mt-4">
                                        <h1 class="page-header-title">
                                            <div class="page-header-icon"><i class="ri-group-line"></i></div>
                                            Bank Request List
                                        </h1>
                                        {/* <!-- <div class="page-header-subtitle">Example dashboard overview and content summary</div> --> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </header>
                    {/* <!-- Main page content-->
            <!-- Example Colored Cards for Dashboard Demo--> */}
                    <div class="container-xl px-4 mt-n10">

                        <div class="card mb-4">

                            <div class="card-body">
                                <div class=" filter_bar mb-4 mb-md-5">
                                    <form method="get" accept-charset="utf-8" novalidate="novalidate" class="form-inline search_form" action="/admin/users/referraldata">
                                        <div class="row gx-2 align-items-center">

                                            <div class="form-group mb-0 col-sm-6 col-md-3">
                                                <div class=" form-check ms-2" >
                                                    <input class="form-check-input text-lg" type="checkbox" id="unverified" onChange={filter} />
                                                    <label class="form-check-label text-lg" for="unverified">
                                                        Show Pending Request
                                                    </label>
                                                </div>
                                            </div>
                                            <div class="form-group mb-0 col-sm-6 col-md-3">
                                                <div class=" form-check ms-2" >
                                                    <input class="form-check-input text-lg" type="checkbox" id="pending" onChange={filter2} />
                                                    <label class="form-check-label text-lg" for="pending">
                                                        Show Rejected List
                                                    </label>
                                                </div>
                                            </div>

                                        </div>
                                    </form>
                                </div>


                                <table id="datatablesSimple">
                                    <thead>
                                        <tr>
                                            <th>#</th>
                                            <th>Name on Bank</th>
                                            <th>Bank Name</th>
                                            <th>IFSC Code</th>
                                            <th>Bank Account Number</th>
                                            <th>Name on Aadhar</th>
                                            <th>Aadhar Number</th>
                                            <th>Aadhar Status</th>
                                            <th>Name On Pan</th>
                                            <th>Pan Number</th>
                                            <th>Pan Status</th>
                                            <th>Registration Date</th>
                                            <th>Status</th>
                                            <th>Actions</th>
                                        </tr>
                                    </thead>

                                    <tbody>
                                        {bank?.length > 0 ? bank?.map((data, index) => {
                                            return (
                                                <tr>
                                                    <td>{index + skip + 1}</td>
                                                    <td>{data?.name_in_Bank}</td>
                                                    <td>{data?.BankNmae}</td>
                                                    <td>{data?.ifsc}</td>
                                                    <td>{data?.account_number}</td>
                                                    <td>{data?.adharname}</td>
                                                    <td>{data?.adharnumber}</td>
                                                    <td>{data?.adharstatus == 2 ? <span className='text-warning'> Pending</span> : data?.adharstatus == 1 ? <span className='text-success'> Approved</span> : <span className='text-danger'>Rejected</span>}</td>
                                                    <td>{data?.NameOnPan}</td>
                                                    <td>{data?.PanNumber}</td>
                                                    <td>{data?.Panverified == 2 ? <span className='text-warning'> Pending</span> : data?.Panverified == 1 ? <span className='text-success'> Approved</span> : <span className='text-danger'>Rejected</span>}</td>
                                                    <td  > <Moment date={data?.Regestrationdate} format="YYYY/MM/DD" /> </td>
                                                    <td>{data?.bank_verified == 2 ?
                                                        <>
                                                            <button type="btn" class="btn badge bg-success text-white rounded-pill"
                                                                onClick={() => { verifyBank(data?._id, '1', data?.user_id) }}>Approve</button>

                                                            <button type="button" class="mt-2    btn badge bg-danger text-white rounded-pill"
                                                                onClick={() => { verifyBank(data?._id, '0', data?.user_id) }}> Not Approve</button>
                                                        </> : data?.bank_verified == 1 ? <p className='text-success text-center'>Approved</p> : data?.bank_verified == 0 ? <p className='text-danger text-center'>Not Approved</p> : ''

                                                    }</td>

                                                    <td>
                                                        <div class="table_action_group">
                                                            <button type="btn" class="btn badge bg-success text-white rounded-pill"

                                                                data-bs-toggle="modal"
                                                                data-bs-target="#editBank"
                                                                onClick={() => {
                                                                    Viewbankdetails(data?.user_id);
                                                                }}> View</button>

                                                            <button type="button" class="btn badge bg-danger text-white rounded-pill"
                                                                onClick={() => {
                                                                    dltbankdetails(data?._id, data?.user_id);
                                                                }}>Delete</button>
                                                        </div>
                                                    </td>
                                                </tr>
                                            )


                                        }) : ""}
                                    </tbody>
                                </table>

                                <div className="row d-flex justify-content-center">
                                    <button type="btn" class="btn badge bg-primary text-white rounded-pill mx-2 p-3 col-1" disabled={skip === 0} onClick={() => { listBank(0, listType) }}>{"<<<<"}</button>
                                    <button type="btn" class="btn badge bg-primary text-white rounded-pill mx-2 p-3  col-2" disabled={skip === 0} onClick={() => { listBank(skip - 20, listType) }}>{"<<Previous"}</button>
                                    <button type="btn" class="btn badge bg-primary text-white rounded-pill mx-2 p-3  col-2" disabled={bank?.length < 20} onClick={() => { listBank(skip + 20, listType) }}>{"Next>>"}</button>
                                    <button type="btn" class="btn badge bg-primary text-white rounded-pill mx-2 p-3  col-1" disabled={bank?.length < 20} onClick={() => { listBank(Math.floor(lastLength / 20) * 20, listType) }}>{">>>>"}</button>
                                </div>

                            </div>
                        </div>
                    </div>




                </main>
            </div>
            {/* Edit Bank Details */}

            <div
                class="modal"
                id="editBank"
                tabIndex="-1"
                aria-labelledby="editwallrtlmodalTitle"
                style={{ display: "none" }}
                aria-hidden="true"
            >
                <div
                    class="modal-dialog  alert_modal modal-dialog-centered"
                    role="document"
                >
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="editwallrtlmodalTitle">
                                Bank Details
                            </h5>
                            <button
                                class="btn-close"
                                type="button"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                            ></button>
                            <hr />
                        </div>
                        <div class="modal-body">
                            <form>
                                <div class="form-group  mb-3 position-relative ">
                                    <label class="small mb-1"> Account Number </label>
                                    <input
                                        type="number"
                                        name="bankNumber"
                                        maxLength="30"
                                        class="form-control"
                                        id="first-name"
                                        value={accNumber}
                                        onChange={(e) => handleBankImput(e)}
                                    />
                                </div>
                                <div class="form-group  mb-3 position-relative ">
                                    <label class="small mb-1"> IFSC Code </label>
                                    <input
                                        type="text"
                                        name="bankCode"
                                        maxLength="30"
                                        class="form-control"
                                        id="first-name"
                                        value={ifsc}
                                        onChange={(e) => handleBankImput(e)}
                                    />
                                </div>
                                <div class="form-group  mb-3 position-relative ">
                                    <label class="small mb-1"> Branch </label>
                                    <input
                                        type="text"
                                        name="branch"
                                        maxLength="30"
                                        class="form-control"
                                        id="first-name"
                                        value={branch}
                                        onChange={(e) => handleBankImput(e)}
                                    />
                                </div>
                                <div class="form-group  mb-3 position-relative ">
                                    <label class="small mb-1"> Bank Name </label>
                                    <input type="text" name="bakName" maxLength="30" class="form-control" id="first-name" value={bankName}
                                        onChange={(e) => handleBankImput(e)} />
                                </div>
                                <div class="form-group  mb-3 position-relative ">
                                    <div className="vs_team" style={{ alignItems: 'center' }}>
                                        <a href={ApiConfig?.appUrl + '/uploads/' + bankImage} target='_blank'>
                                            <img src={bankImage2 ? bankImage2 : `${ApiConfig?.appUrl + '/uploads/' + bankImage}`} width="50" height="50" alt="" /></a>
                                        <label class="small mb-1"></label>
                                        <input type="file" name="bankImage" maxLength="30" class="form-control" id="first-name" onChange={(e) => handleBankImput(e)} />
                                    </div>
                                </div>
                                <div class="form-group  mb-3 position-relative ">
                                    <label class="small mb-1"> Status </label>
                                    <input type="text" name="bankStatus" maxLength="30" class="form-control" id="first-name" disabled
                                        value={bankStatus === 1 ? 'Active' : bankStatus === 0 ? 'Inactive' : ''} />
                                </div>
                                <div class="form-group  position-relative mt-4">
                                    <button class="btn btn-primary   btn-block w-100" type="button"
                                        onClick={() => { editbnkdtl(accNumber, ifsc, branch, bankName, bankImage); }}>
                                        Edit Details
                                    </button>
                                </div>
                                <button class="btn btn-Danger btn-block w-100" type="button"
                                    onClick={() => { dltbankdetails(userId); }}>
                                    Delete
                                </button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default BankRequest
