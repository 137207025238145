import React from 'react'
import Header from '../Header'
import Sidebar from '../Sidebar'

const PaymentList = () => {
  return (
<>

    <div id="layoutSidenav_content">
        <main>
            <header class="page-header page-header-dark bg-gradient-primary-to-secondary pb-10">
                <div class="container-xl px-4">
                    <div class="page-header-content pt-4">
                        <div class="row align-items-center justify-content-between">
                            <div class="col-auto mt-4">
                                <h1 class="page-header-title">
                                    <div class="page-header-icon"><i class="ri-bank-card-line"></i></div>
                                  Payment details
                                </h1>
                                {/* <!-- <div class="page-header-subtitle">Example dashboard overview and content summary</div> --> */}
                            </div>
                        </div>
                    </div>
                </div>
            </header>
            {/* <!-- Main page content-->
            <!-- Example Colored Cards for Dashboard Demo--> */}
            <div class="container-xl px-4 mt-n10">

                <div class="row g-3">
                    <div class="col-xl-6">
                        <div class="card h-100">
                            <div class="card-body d-flex justify-content-start flex-column pt-5 ">
                                <div class="d-flex align-items-center justify-content-start mb-4 ">
                                    <div class="me-3"><span class="symbol-label"><img src="/assets/img/cashfree.png" class="h-75 align-self-center img-fluid" alt=""/></span></div>
                                    <h5 class="mb-0">Cashfree</h5>
                                </div>
                                <form>
                                    <div class="form-group row align-items-center">
                                        <div class="col-8" >
                                            <label class="small  me-2">Payment name :</label>
                                        </div>
                                        <div class="col-4" >
                                           <strong>----</strong>
                                        </div>
                                    </div>
                                    <div class="form-group row align-items-center">
                                        <div class="col-8" >
                                            <label class="small  me-2">Cashfree access key :</label>
                                        </div>
                                        <div class="col-4" >
                                           <strong>----</strong>
                                        </div>
                                    </div>
                                    <div class="form-group row align-items-center">
                                        <div class="col-8" >
                                            <label class="small  me-2">Cashfree secret key:</label>
                                        </div>
                                        <div class="col-4" >
                                           <strong>----</strong>
                                        </div>
                                    </div>
                                    <div class="form-group row align-items-center">
                                        <div class="col-8" >
                                            <label class="small  me-2">Cashfree access token :</label>
                                        </div>
                                        <div class="col-4" >
                                           <strong>----</strong>
                                        </div>
                                    </div>
                                    <button class="btn btn-danger   btn-block w-100 mt-2" type="button" >Inactive</button>
                                </form>
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-6">
                        <div class="card h-100">
                            <div class="card-body d-flex justify-content-start flex-column pt-5 ">
                                <div class="d-flex align-items-center justify-content-start mb-4 ">
                                    <div class="me-3"><span class="symbol-label"><img src="/assets/img/razorpay.png" class="h-75 align-self-center img-fluid" alt=""/></span></div>
                                    <h5 class="mb-0">Razorpay</h5>
                                </div>
                                <form>
                                    <div class="form-group row align-items-center">
                                        <div class="col-8" >
                                            <label class="small  me-2">Payment name :</label>
                                        </div>
                                        <div class="col-4" >
                                           <strong>----</strong>
                                        </div>
                                    </div>
                                    <div class="form-group row align-items-center">
                                        <div class="col-8" >
                                            <label class="small  me-2">Cashfree access key :</label>
                                        </div>
                                        <div class="col-4" >
                                           <strong>----</strong>
                                        </div>
                                    </div>
                                    <div class="form-group row align-items-center">
                                        <div class="col-8" >
                                            <label class="small  me-2">Cashfree secret key:</label>
                                        </div>
                                        <div class="col-4" >
                                           <strong>----</strong>
                                        </div>
                                    </div>
                                    <div class="form-group row align-items-center">
                                        <div class="col-8" >
                                            <label class="small  me-2">Cashfree access token :</label>
                                        </div>
                                        <div class="col-4" >
                                           <strong>----</strong>
                                        </div>
                                    </div>
                                    <button class="btn btn-danger   btn-block w-100 mt-2" type="button" >Inactive</button>
                                </form>
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-6">
                        <div class="card h-100">
                            <div class="card-body d-flex justify-content-start flex-column pt-5 ">
                                <div class="d-flex align-items-center justify-content-start mb-4 ">
                                    <div class="me-3"><span class="symbol-label"><img src="/assets/img/paytm.png" class="h-75 align-self-center img-fluid" alt=""/></span></div>
                                    <h5 class="mb-0">PayTm</h5>
                                </div>
                                <form>
                                    <div class="form-group row align-items-center">
                                        <div class="col-8" >
                                            <label class="small  me-2">Payment name :</label>
                                        </div>
                                        <div class="col-4" >
                                           <strong>---</strong>
                                        </div>
                                    </div>
                                    <div class="form-group row align-items-center">
                                        <div class="col-8" >
                                            <label class="small  me-2">Cashfree access key :</label>
                                        </div>
                                        <div class="col-4" >
                                           <strong> ---</strong>
                                        </div>
                                    </div>
                                    <div class="form-group row align-items-center">
                                        <div class="col-8" >
                                            <label class="small  me-2">Cashfree secret key:</label>
                                        </div>
                                        <div class="col-4" >
                                           <strong>---</strong>
                                        </div>
                                    </div>
                                    <div class="form-group row align-items-center">
                                        <div class="col-8" >
                                            <label class="small  me-2">Cashfree access token :</label>
                                        </div>
                                        <div class="col-4" >
                                           <strong>---</strong>
                                        </div>
                                    </div>
                                    <button class="btn btn-success   btn-block w-100 mt-2" type="button" >Active</button>
                                </form>
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-6">
                        <div class="card h-100">
                            <div class="card-body d-flex justify-content-start flex-column pt-5 ">
                                <div class="d-flex align-items-center justify-content-start mb-4 ">
                                    <div class="me-3"><span class="symbol-label"><img src="/assets/img/payu.png" class="bg-dark h-75 align-self-center img-fluid" alt=""/></span></div>
                                    <h5 class="mb-0">Payu Money</h5>
                                </div>
                                <form>
                                    <div class="form-group row align-items-center">
                                        <div class="col-8" >
                                            <label class="small  me-2">Payment name :</label>
                                        </div>
                                        <div class="col-4" >
                                           <strong>----</strong>
                                        </div>
                                    </div>
                                    <div class="form-group row align-items-center">
                                        <div class="col-8" >
                                            <label class="small  me-2">Cashfree access key :</label>
                                        </div>
                                        <div class="col-4" >
                                           <strong>----</strong>
                                        </div>
                                    </div>
                                    <div class="form-group row align-items-center">
                                        <div class="col-8" >
                                            <label class="small  me-2">Cashfree secret key:</label>
                                        </div>
                                        <div class="col-4" >
                                           <strong>----</strong>
                                        </div>
                                    </div>
                                    <div class="form-group row align-items-center">
                                        <div class="col-8" >
                                            <label class="small  me-2">Cashfree access token :</label>
                                        </div>
                                        <div class="col-4" >
                                           <strong>----</strong>
                                        </div>
                                    </div>
                                    <button class="btn btn-danger   btn-block w-100 mt-2" type="button" >Inactive</button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>



            </div>


                
           
        </main>
    </div>

</>
  )
}

export default PaymentList
