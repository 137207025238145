import React, { useEffect, useState } from 'react'
import AuthService from '../../../api/services/HomeService';
import LoaderHelper from '../../../Components/Loading/LoaderHelper';
import { alertErrorMessage, alertSuccessMessage } from '../../../Components/CustomAlertMessage';
import Moment from 'react-moment';


const ApprovedRequest = () => {
    const [panCard, setpanCard] = useState();

    useEffect(() => {
        listPan()
    }, []);

    const listPan = async () => {
        LoaderHelper.loaderStatus(true);
        await AuthService.listPan(1).then((result) => {
            if (result?.success) {
                LoaderHelper.loaderStatus(false);
                try {
                    setpanCard(result?.data)
                } catch {
                    alertErrorMessage(result.message);
                }
            } else {
                LoaderHelper.loaderStatus(false);
                alertErrorMessage(result.message);
            }
        });
    };

    const dltPandetails = async (id, userId) => {
        await AuthService.dltPandetails(id, userId).then((result) => {
            if (result?.success) {
                try {
                    alertSuccessMessage("Pan Deleted Successfully!!");
                    listPan()

                } catch {
                    alertErrorMessage(result.message);
                }
            } else {
                alertErrorMessage(result.message);
            }
        });
    };
    return (
        <div id="layoutSidenav_content">
            <main>
                <header class="page-header page-header-dark bg-gradient-primary-to-secondary pb-10">
                    <div class="container-xl px-4">
                        <div class="page-header-content pt-4">
                            <div class="row align-items-center justify-content-between">
                                <div class="col-auto mt-4">
                                    <h1 class="page-header-title">
                                        <div class="page-header-icon"><i class="ri-group-line"></i></div>
                                        Approved Pancard Request
                                    </h1>
                                </div>
                            </div>
                        </div>
                    </div>
                </header>
                <div class="container-xl px-4 mt-n10">
                    <div class="card mb-4">
                        <div class="card-body">

                            <table id="datatablesSimple">
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>Name</th>
                                        <th>Pan Number</th>
                                        <th>Phone Number</th>
                                        <th>Registration Date</th>
                                        <th>Status</th>
                                    </tr>
                                </thead>
                                <tfoot>
                                    <tr>
                                        <th>#</th>
                                        <th>Name</th>
                                        <th>Pan Number</th>
                                        <th>Phone Number</th>
                                        <th>Registration Date</th>
                                        <th>Status</th>
                                    </tr>
                                </tfoot>
                                <tbody>
                                    {panCard ? panCard?.map((data, index) => {
                                        return (
                                            <tr key={index}>
                                                <td>{index + 1}</td>
                                                <td>{data?.NameOnPan}</td>
                                                <td>{data?.pan_number}</td>
                                                <td>{data?.data?.mobile_number}</td>
                                                <td> <Moment date={data?.createdAt} format="YYYY/MM/DD" /> </td>

                                                <td>
                                                    <button type="button" class="mt-2 btn badge text-white rounded-pill">
                                                        {
                                                            data?.pan_verified == 1 ? <p className='text-success text-center'>Approved</p> : data?.pan_verified == 2 ? <p className='text-warning text-center'>Pending</p> : data?.pan_verified == 3 ? <p className='text-danger text-center'>Reject</p> : ''
                                                        }
                                                    </button>

                                                    <button type="button" class="btn badge bg-danger text-white rounded-pill"
                                                        onClick={() => {
                                                            dltPandetails(data?._id, data?.UserId);
                                                        }}>Delete</button>
                                                </td>
                                            </tr>
                                        )
                                    }) : ''}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>

            </main>
        </div>
    )
}

export default ApprovedRequest
