import React from 'react'
import { useNavigate } from 'react-router-dom';

const Header = () => {
    const navigate = useNavigate()

    const logOut = () => {
        sessionStorage.clear();
        navigate("/");
        window.location.reload()

    }
    const Email = sessionStorage.getItem('Email')

    return (
        <nav className="topnav navbar navbar-expand shadow justify-content-between justify-content-sm-start navbar-light bg-white" id="sidenavAccordion">
            <button className="btn btn-icon btn-transparent-dark order-1 order-lg-0 me-2 ms-lg-2 me-lg-0" id="sidebarToggle"><i className="ri-menu-4-line"></i></button>
            <a className="navbar-brand pe-3 ps-4 ps-lg-3" href="/Dashboard">
                <img alt='' src="/img/logo.png" className="img-fluid" />
            </a>
            <ul className="navbar-nav align-items-center ms-auto">
                <li className="nav-item dropdown no-caret d-none d-sm-block me-3 dropdown-notifications"></li>
                <li className="nav-item dropdown no-caret dropdown-user me-3 me-lg-4">
                    <a className="btn btn-icon btn-transparent-dark dropdown-toggle" id="navbarDropdownUserImage" href="/" role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><img alt='' className="img-fluid" src="/assets/img/dummy.png" /></a>
                    <div className="dropdown-menu dropdown-menu-end border-0 shadow animated--fade-in-up" aria-labelledby="navbarDropdownUserImage">
                        <h6 className="dropdown-header d-flex align-items-center">
                            <img className="dropdown-user-img" src="/assets/img/dummy.png" alt='' />
                            <div className="dropdown-user-details">
                                <div className="dropdown-user-details-name">User</div>
                                <div className="dropdown-user-details-email"><a href="#/" className="__cf_email__" >{Email}</a>
                                </div>
                            </div>
                        </h6>
                        <div className="dropdown-divider"></div>
                        <a className="dropdown-item" href="#/" onClick={logOut}>
                            <div className="dropdown-item-icon"><i className="ri-shut-down-line"></i></div>
                            Logout
                        </a>
                    </div>
                </li>
            </ul>
        </nav>
    )
}

export default Header
