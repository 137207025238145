import React, { useEffect, useState } from 'react'
import ReactPaginate from 'react-paginate';
import AuthService from '../../api/services/HomeService';
import { alertErrorMessage, alertSuccessMessage, alertWarningMessage } from '../../Components/CustomAlertMessage';
import Header from '../Header'
import Sidebar from '../Sidebar'

const PushNotification = () => {
    const [title, settitle] = useState();
    const [notification, setnotification] = useState();
    const [notificationList, setnotificationList] = useState([]);
    const [userId, setuserId] = useState();

    useEffect(() => {
        getNotificationList()
    }, []);




    const getNotificationList = async () => {

        await AuthService.getNotificationList().then((result) => {
            if (result?.success) {
                try {
                    setnotificationList(result?.data)

                } catch {
                    alertErrorMessage();
                }
            } else {
                alertErrorMessage(result?.message);
            }
        })
    };
    const addNotification = async (title, notification) => {
        if(title && notification ){
        await AuthService.AddNotification(title, notification).then((result) => {
            if (result?.success) {
                try {
                    alertSuccessMessage('Notification Added!!');
                    setnotification('')
                    settitle('')
                    getNotificationList()

                } catch {
                    alertErrorMessage();
                }
            } else {
                alertErrorMessage(result?.message);
            }
        })}
        else{
            alertWarningMessage('Please all required fields')
        }
    };
    const EditNotification = async (id,title, notification) => {

        await AuthService.EditNotification(id,title, notification).then((result) => {
            if (result?.success) {
                try {
                    setnotification('')
                    settitle('')
                    getNotificationList()

                } catch {
                    alertErrorMessage();
                }
            } else {
                alertErrorMessage(result?.message);
            }
        })
    };
    const handleEditDetails = (data) => {
        setuserId(data?._id)
        setnotification(data?.message)
        settitle(data?.Title)
    }
            /* custom pagination */

            const [currentPage, setCurrentPage] = useState(1);
            const [itemsPerPage, setItemsPerPage] = useState(5);
        
        
            const handlePageChange = ({ selected }) => {
                setCurrentPage(selected + 1);
            };
        
            const pageCount = Math.ceil(notificationList.length / itemsPerPage);
            const currentItems = notificationList.slice(
                (currentPage - 1) * itemsPerPage,
                currentPage * itemsPerPage
            );
            // custom paginatio ends
    return (
        <>

                <div id="layoutSidenav_content">
                    <main>
                        <header class="page-header page-header-dark bg-gradient-primary-to-secondary pb-10">
                            <div class="container-xl px-4">
                                <div class="page-header-content pt-4">
                                    <div class="row align-items-center justify-content-between">
                                        <div class="col-auto mt-4">
                                            <h1 class="page-header-title">
                                                <div class="page-header-icon"><i class="ri-notification-3-line"></i></div>
                                                Send Notifications
                                            </h1>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </header>
                        {/* <!-- Main page content--> */}
                        <div class="container-xl px-4 mt-n10">
                            <div class="card mb-4">
                                <div class="card-header">
                                    <h3 class="card-title mb-0">Enter Details</h3> </div>

                                <form method="post" accept-charset="utf-8" novalidate="novalidate" id="notificationForm" action="">

                                    <div class="row">
                                        <div class="col-md-12">
                                            <div class="card-body">
                                                <div class="form-group">
                                                    <div class="input Number">
                                                        <div class="input text required"><label for="title">Title <span class="required">*</span></label><input type="text" name="title" class="form-control" placeholder="Notification Title" required="required" maxLength="100" id="title" value={title} onChange={(e) => { settitle(e.target.value) }} /></div>
                                                    </div>
                                                </div>
                                                <div class="form-group">
                                                    <div class="input text">
                                                        <div class="form-group">
                                                            <label>Notification <span class="required">*</span></label>
                                                            <div class="input textarea required">
                                                                <textarea name="notification" class="form-control" rows="3" required="required" maxLength="255" id="notification" value={notification} onChange={(e) => { setnotification(e.target.value) }}></textarea>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="card-footer">
                                        <button type="button" class="btn btn-primary submit" onClick={() => { addNotification(title, notification) }}>Send Notification</button>
                                    </div>
                                </form>

                            </div>
                            <div class="card mb-4">

                                <div class="card-body">

                                    <table id="datatablesSimple">
                                        <thead>
                                            <tr>
                                                <th>#</th>
                                                <th>Title</th>
                                                <th>Notification</th>
                                                <th>Action</th>
                                            </tr>
                                        </thead>
                                        <tfoot>
                                            <tr>
                                                <th>#</th>
                                                <th>Title</th>
                                                <th>Notification</th>
                                                <th>Action</th>
                                            </tr>
                                        </tfoot>
                                        <tbody>
                                            {currentItems ? currentItems?.map((data, index) => {
                                                   let objIndex;
                                                   objIndex = notificationList.findIndex(check => check?._id == data._id);
                                                return (
                                                    <tr>
                                                        <td>{objIndex + 1}</td>
                                                        <td>{data?.Title}</td>
                                                        <td>{data?.message}</td>
                                                        <td>
                                                            <div class="table_action_group">
                                                                <button type="btn" class="btn badge bg-success text-white rounded-pill"
                                                                    data-bs-toggle="modal" data-bs-target="#editNotification"
                                                                    onClick={() => { handleEditDetails(data) }}
                                                                >Edit</button>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                )
                                            }) : ''}

                                        </tbody>
                                    </table>
                                    <ReactPaginate
                                        pageCount={pageCount}
                                        pageRangeDisplayed={3}
                                        marginPagesDisplayed={2}
                                        onPageChange={handlePageChange}
                                        containerClassName={'customPagination'}
                                        activeClassName={'active'}
                                    />
                                </div>
                            </div>



                        </div>
                    </main>
                </div>

            <div class="modal" id="editNotification" tabIndex="-1" aria-labelledby="editwallrtlmodalTitle" style={{ display: 'none' }} aria-hidden="true">
                <div class="modal-dialog  alert_modal modal-dialog-centered" role="document">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="editwallrtlmodalTitle">Edit User Details</h5>
                            <button class="btn-close" type="button" data-bs-dismiss="modal" aria-label="Close"></button>
                            <hr />
                        </div>
                        <div class="modal-body">

                            <form>

                                <div class="form-group  mb-3 position-relative ">
                                    <label for="title">Title </label><input type="text" name="title" class="form-control" placeholder="Notification Title" required="required" maxLength="100" id="title" value={title} onChange={(e) => { settitle(e.target.value) }} />

                                </div>

                                <div class="form-group  mb-3 position-relative ">
                                    <label>Notification </label>
                                    <div class="input textarea required">
                                        <textarea name="notification" class="form-control" rows="3" required="required" maxLength="255" id="notification" value={notification} onChange={(e) => { setnotification(e.target.value) }}></textarea>
                                    </div>

                                </div>

                                <div class="form-group  position-relative mt-4">
                                    <button data-bs-dismiss="modal" class="btn btn-primary   btn-block w-100" type="button" onClick={()=>{EditNotification(userId,title, notification)}} >Submit Details</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default PushNotification
