import React, { useEffect, useState } from 'react'
import Moment from 'react-moment';
import ReactPaginate from 'react-paginate';
import AuthService from '../../api/services/HomeService';
import { alertErrorMessage, alertSuccessMessage, alertWarningMessage } from '../../Components/CustomAlertMessage';
import Header from '../Header'
import Sidebar from '../Sidebar'

const EmailNotification = () => {
    const [email, setemail] = useState('');
    const [title, settitle] = useState('');
const [message, setmessage] = useState('');
const [userId, setuserId] = useState();
const [notificationList, setnotificationList] = useState([]);

useEffect(() => {
    
    getNotificationList()

}, []);

const addNotification = async (email,title,message) => {
    if(email && title && message){

    await AuthService.EmailNotification(email,title,message).then((result) => {
        if (result?.success) {
            try {
                alertSuccessMessage('Notification Added!!');
                setemail('')
                setmessage('')
                settitle('')
                getNotificationList()

            } catch {
                alertErrorMessage();
            }
        } else {
            alertErrorMessage(result?.message);
        }
    }) }
    else{
        alertWarningMessage('Please fill all required fields')
    }
};

const getNotificationList = async () => {

    await AuthService.getEmailNotificationList().then((result) => {
        if (result?.success) {
            try {
                setnotificationList(result?.data)

            } catch {
                alertErrorMessage();
            }
        } else {
            alertErrorMessage(result?.message);
        }
    })
};
const handleEditDetails = (data) => {
    setuserId(data?._id)
   setemail(data?.Email)
   settitle(data?.Subject)
   setmessage(data?.message)
};
const EditNotification = async (id,email,title,message) => {

    await AuthService.EditEmailNoti(id,email,title,message).then((result) => {
        if (result?.success) {
            try {
                setemail('')
                setmessage('')
                settitle('')
                getNotificationList()

            } catch {
                alertErrorMessage();
            }
        } else {
            alertErrorMessage(result?.message);
        }
    })
};
const [currentPage, setCurrentPage] = useState(1);
const [itemsPerPage, setItemsPerPage] = useState(5);


const handlePageChange = ({ selected }) => {
    setCurrentPage(selected + 1);
};

const pageCount = Math.ceil(notificationList.length / itemsPerPage);
const currentItems = notificationList.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
);
// custom paginatio ends
  return (
<>

                <div id="layoutSidenav_content">
                    <main>
                        <header class="page-header page-header-dark bg-gradient-primary-to-secondary pb-10">
                            <div class="container-xl px-4">
                                <div class="page-header-content pt-4">
                                    <div class="row align-items-center justify-content-between">
                                        <div class="col-auto mt-4">
                                            <h1 class="page-header-title">
                                                <div class="page-header-icon"><i class="ri-notification-3-line"></i></div>
                                               Send & Sent List Email
                                            </h1>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </header>
                        {/* <!-- Main page content--> */}
                        <div class="container-xl px-4 mt-n10">
                            <div class="card mb-4">
                                <div class="card-header">
                                    <h3 class="card-title mb-0">Enter Details</h3> </div>
                                   
                                  <form method="post" accept-charset="utf-8" novalidate="novalidate" id="notificationForm" action="">
                                    
                                    <div class="row">
                                        <div class="col-md-12">
                                            <div class="card-body">
                                                <div class="form-group">
                                                    <div class="input email">
                                                        <label for="email">To <span class="required">*</span></label>
                                                        <input type="email" name="email" class="form-control" placeholder="email" maxLength="255" id="email" onChange={(e)=>{setemail(e.target.value)}} value={email}/>
                                                    </div>
                                                </div>
                                                <div class="form-group">
                                                    <div class="input text">
                                                        <label for="subject">Subject <span class="required">*</span></label>
                                                        <input type="text" name="subject" class="form-control" placeholder="subject" maxLength="20" id="subject"onChange={(e)=>{settitle(e.target.value)}} value={title}/>
                                                    </div>
                                                </div>
                                                <div class="form-group">
                                                    <label>Message <span class="required">*</span></label>
                                                    <div class="input textarea">
                                                        <textarea name="message" class="form-control" rows="3" maxLength="20" id="message"onChange={(e)=>{setmessage(e.target.value)}} value={message} ></textarea>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="card-footer">
                                        <button type="button" class="btn btn-primary submit" onClick={() => { addNotification(email,title,message) }}>Send Email</button>
                                    </div>
                                </form>

                            </div>
                            <div class="card mb-4">
                           
                                <div class="card-body">
                                  
                                    <table id="datatablesSimple">
                                        <thead>
                                            <tr>
                                                <th>#</th>
                                                <th>To</th>
                                                <th>Subject</th>
                                                <th>Message</th>
                                                <th>Date</th>
                                            </tr>
                                        </thead>
                                        <tfoot>
                                            <tr>
                                                <th>#</th>
                                                <th>To</th>
                                                <th>Subject</th>
                                                <th>Message</th>
                                                <th>Date</th>
                                            </tr>
                                        </tfoot>
                                        <tbody>
                                        {currentItems ? currentItems?.map((data, index) => {
                                             let objIndex;
                                             objIndex = notificationList.findIndex(check => check?._id == data._id);
                                                return (
                                                    <tr>
                                                        <td>{objIndex + 1}</td>
                                                        <td>{data?.Email}</td>
                                                        <td>{data?.Subject}</td>
                                                        <td>{data?.message}</td>
                                                        <td><Moment date={data?.createdAt} format="YYYY/MM/DD HH:mm" /></td>
                                                        <td>
                                                            <div class="table_action_group">
                                                                <button type="btn" class="btn badge bg-success text-white rounded-pill"
                                                                    data-bs-toggle="modal" data-bs-target="#editNotification"
                                                                    onClick={() => { handleEditDetails(data) }}
                                                                >Edit</button>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                )
                                            }) : ''}
                                        </tbody>
                                    </table>
                                    <ReactPaginate
                                        pageCount={pageCount}
                                        pageRangeDisplayed={3}
                                        marginPagesDisplayed={2}
                                        onPageChange={handlePageChange}
                                        containerClassName={'customPagination'}
                                        activeClassName={'active'}
                                    />
                                </div>
                            </div>



                        </div>
                    </main>
                </div>

        <div class="modal" id="editNotification" tabIndex="-1" aria-labelledby="editwallrtlmodalTitle" style={{ display: 'none' }} aria-hidden="true">
                <div class="modal-dialog  alert_modal modal-dialog-centered" role="document">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="editwallrtlmodalTitle">Edit User Details</h5>
                            <button class="btn-close" type="button" data-bs-dismiss="modal" aria-label="Close"></button>
                            <hr />
                        </div>
                        <div class="modal-body">

                        <form method="post" accept-charset="utf-8" novalidate="novalidate" id="notificationForm" action="">
                                    
                                    <div class="row">
                                        <div class="col-md-12">
                                            <div class="card-body">
                                                <div class="form-group">
                                                    <div class="input email">
                                                        <label for="email">To </label>
                                                        <input type="email" name="email" class="form-control" placeholder="email" maxLength="255" id="email" onChange={(e)=>{setemail(e.target.value)}} value={email}/>
                                                    </div>
                                                </div>
                                                <div class="form-group">
                                                    <div class="input text">
                                                        <label for="subject">Subject </label>
                                                        <input type="text" name="subject" class="form-control" placeholder="subject" maxLength="20" id="subject"onChange={(e)=>{settitle(e.target.value)}} value={title}/>
                                                    </div>
                                                </div>
                                                <div class="form-group">
                                                    <label>Message</label>
                                                    <div class="input textarea">
                                                        <textarea name="message" class="form-control" rows="3" maxLength="20" id="message"onChange={(e)=>{setmessage(e.target.value)}} value={message} ></textarea>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="card-footer">
                     
                                    <div class="form-group  position-relative mt-4">
                                    <button data-bs-dismiss="modal" class="btn btn-primary   btn-block w-100" type="button" onClick={()=>{EditNotification(userId,email, title,message)}} >Submit Details</button>
                                    </div>
                                    </div>
                                </form>
                        </div>
                    </div>
                </div>
            </div>
</>
  )
}

export default EmailNotification
