import React, { useEffect, useState } from "react";
import Moment from "react-moment";
import AuthService from "../../api/services/HomeService";
import { alertErrorMessage, alertSuccessMessage, alertWarningMessage, } from "../../Components/CustomAlertMessage";
import LoaderHelper from "../../Components/Loading/LoaderHelper";
import { ApiConfig } from "../../api/apiConfig/ApiConfig";

const UsersList = () => {
  const [userList, setuserList] = useState([]);
  const [userList2, setuserList2] = useState([]);
  const [fName, setfName] = useState("");
  const [lName, setlName] = useState("");
  const [email, setemail] = useState("");
  const [userName, setuserName] = useState("");
  const [userId, setuserId] = useState("");
  const [skip, setSkip] = useState(0);

  const [panNumber, setpanNumber] = useState("");
  const [nameOnPan, setnameOnPan] = useState("");
  const [panDob, setpanDob] = useState("");
  const [panImage, setpanImage] = useState();
  const [panImage2, setpanImage2] = useState();
  const [panState, setpanState] = useState("");
  const [panStatus, setpanStatus] = useState("");
  const [panID, setpanID] = useState('');

  const [accNumber, setaccNumber] = useState('');
  const [ifsc, setifsc] = useState('');
  const [branch, setbranch] = useState('');
  const [bankName, setbankName] = useState('');
  const [bankImage, setbankImage] = useState();
  const [bankImage2, setbankImage2] = useState();
  const [bankStatus, setbankStatus] = useState('');
  const [bankId, setbankId] = useState('');
  const [lastLength, setLastLength] = useState(0);



  useEffect(() => {
    UserList(0);
  }, []);

  const handlePanInput = (e) => {
    switch (e.target.name) {
      case "panNumber":
        setpanNumber(e.target.value);
        break;
      case "panState":
        setpanState(e.target.value);
        break;
      case "panImage":
        setpanImage(e.target.files[0]);
        setpanImage2(URL.createObjectURL(e.target.files[0]));
        break;
      case "panDob":
        setpanDob(e.target.value);
        break;
      case "panName":
        setnameOnPan(e.target.value);
        break;

      default:
        break;
    }
  };
  const handleBankImput = (e) => {
    switch (e.target.name) {
      case "bankNumber":
        setaccNumber(e.target.value);
        break;
      case "bankCode":
        setifsc(e.target.value);
        break;
      case "branch":
        setbranch(e.target.value);
        break;
      case "bakName":
        setbankName(e.target.value);
        break;
      case "bankImage":
        setbankImage(e.target.files[0]);
        setbankImage2(URL.createObjectURL(e.target.files[0]));
        break;

      default:
        break;
    }
  };

  const UserList = async (skip, today) => {
    LoaderHelper.loaderStatus(true);
    await AuthService.UserList(skip, 20, today).then((result) => {
      if (result?.success) {
        LoaderHelper.loaderStatus(false);
        try {
          setLastLength(result?.no_of_user)
          setSkip(skip)
          setuserList(result?.data?.reverse());
          setuserList2(result?.data);
        } catch {
          alertErrorMessage(result.message);
        }
      } else {
        LoaderHelper.loaderStatus(false);
        alertErrorMessage(result.message);
      }
    });
  };

  const UserStatus = async (status, id) => {
    await AuthService.UserStatus(status, id).then((result) => {
      if (result?.success) {
        try {
          UserList(skip);
        } catch {
          alertErrorMessage(result.message);
        }
      } else {
        alertErrorMessage(result.message);
      }
    });
  };

  const editUser = async (userId, fName, lName, email, userName) => {
    await AuthService.editUser(userId, fName, lName, email, userName).then((result) => {
      if (result?.success) {
        try {
          alertSuccessMessage('User Data Updated Successfully!!')
          UserList(skip);
        } catch {
          alertErrorMessage(result.message);
        }
      } else {
        alertErrorMessage(result.message);
      }
    });
  };


  const AddBankacc = async (accNumber, ifsc, branch, bankName, bankImage) => {
    var formData = new FormData();
    formData.append("UserId", userId);
    formData.append("AccountNumber", accNumber);
    formData.append("ifsc", ifsc);
    formData.append("Branch", branch);
    formData.append("BankName", bankName);
    formData.append("Bankimagepath", bankImage);
    formData.append("bankverified", '1');
    if (accNumber && ifsc && branch && bankName && bankImage) {
      LoaderHelper.loaderStatus(true)
      await AuthService.AddBankacc(formData).then((result) => {
        if (result?.success) {
          LoaderHelper.loaderStatus(false)
          try {
            Viewbankdetails(userId)
            alertSuccessMessage('Bank Details Added Successfully!!');

          } catch {
            alertErrorMessage(result?.message);
          }
        } else {
          LoaderHelper.loaderStatus(false)
          alertErrorMessage(result?.message);
        }
      })
    }
    else {
      alertWarningMessage('Please fill all required details')
    }
  };
  const AddPan = async (panNumber, nameOnPan, panDob, panImage, panState) => {
    var formData = new FormData();
    formData.append("UserId", userId);
    formData.append("PanNumber", panNumber);
    formData.append("NameOnPan", nameOnPan);
    formData.append("dob", panDob);
    formData.append("Panimagepath", panImage);
    formData.append("State", panState);
    formData.append("panverified", '1');
    if (panNumber && nameOnPan && panDob && panImage && panState) {
      await AuthService.AddPan(formData).then((result) => {
        if (result?.success) {
          try {
            ViewPANdetails(userId)
            alertSuccessMessage('Pan Details Added Successfully!!');

          } catch {
            alertErrorMessage(result?.message);
          }
        } else {
          alertErrorMessage(result?.message);
        }
      })
    }
    else {
      alertWarningMessage('Please fill all required fileds')
    }
  };
  const editPANdtl = async (panNumber, nameOnPan, panDob, panImage, panState) => {
    var formData = new FormData();
    formData.append("_id", panID);
    formData.append("UserId", userId);
    formData.append("PanNumber", panNumber);
    formData.append("NameOnPan", nameOnPan);
    formData.append("dob", panDob);
    formData.append("Panimagepath", panImage);
    formData.append("State", panState);
    await AuthService.editPANdtl(formData).then((result) => {
      if (result?.success) {
        try {
          alertSuccessMessage('Pan Details Updated Successfully!!');
          ViewPANdetails(userId)
        } catch {
          alertErrorMessage(result?.message);
        }
      } else {
        alertErrorMessage(result?.message);
      }
    });
  };
  const editbnkdtl = async (accNumber, ifsc, branch, bankName, bankImage) => {
    var formData = new FormData();
    formData.append("_id", bankId);
    formData.append("UserId", userId);
    formData.append("AccountNumber", accNumber);
    formData.append("ifsc", ifsc);
    formData.append("Branch", branch);
    formData.append("BankName", bankName);
    formData.append("Bankimagepath", bankImage);
    formData.append("bankverified", '0');

    await AuthService.editbnkdtl(formData).then((result) => {
      if (result?.success) {
        try {
          alertSuccessMessage('Bank Details Updated Successfully!!');
          Viewbankdetails(userId)
        } catch {
          alertErrorMessage(result?.message);
        }
      } else {
        alertErrorMessage(result?.message);
      }
    });
  };

  const ViewPANdetails = async (id) => {
    setuserId(id);
    setpanNumber('')
    setnameOnPan('')
    setpanState('')
    setpanDob('')
    setpanStatus('')
    setpanImage()
    setpanImage2()

    LoaderHelper.loaderStatus(true)
    await AuthService.ViewPANdetails(id).then((result) => {
      if (result?.success) {
        LoaderHelper.loaderStatus(false)
        try {
          setpanNumber(result?.data?.[0]?.pan_number)
          setnameOnPan(result?.data?.[0]?.NameOnPan)
          setpanState(result?.data?.[0]?.State)
          setpanDob(result?.data?.[0]?.dob)
          setpanStatus(result?.data?.[0]?.pan_verified)
          setpanImage(result?.data?.[0]?.Panimagepath)
          setpanID(result?.data?.[0]?._id)
        } catch {
          LoaderHelper.loaderStatus(false)
          alertErrorMessage(result.message);
        }
      } else {
      }
    });
  };

  const Viewbankdetails = async (id) => {
    setuserId(id);
    setaccNumber("");
    setbankName("");
    setbranch("");
    setifsc("");
    setbankStatus("");
    setbankImage();
    setbankImage2();
    LoaderHelper.loaderStatus(true)
    await AuthService.Viewbankdetails(id).then((result) => {
      if (result?.success) {
        LoaderHelper.loaderStatus(false)
        try {
          setaccNumber(result?.data[0]?.account_number);
          setbankName(result?.data[0]?.bank_name);
          setbranch(result?.data[0]?.branch_name);
          setifsc(result?.data[0]?.ifsc);
          setbankStatus(result?.data[0]?.bank_verified);
          setbankImage(result?.data[0]?.Bankimagepath);
          setbankId(result?.data[0]?._id);
        } catch { }
      } else {
        LoaderHelper.loaderStatus(false)
      }
    });
  };

  const dltbankdetails = async (id) => {
    await AuthService.dltbankdetails(id).then((result) => {
      if (result?.success) {
        try {
          alertSuccessMessage("Bank Account Deleted Successfully!!");
          setaccNumber("");
          setifsc("");
          setbranch("");
          setbankName("");
          setbankImage();
          setbankStatus('');
        } catch {
          alertErrorMessage(result.message);
        }
      } else {
        // alertErrorMessage(result.message);
      }
    });
  };
  const dltPandetails = async (id) => {
    await AuthService.dltPandetails(id).then((result) => {
      if (result?.success) {
        try {
          alertSuccessMessage("Pan Deleted Successfully!!");
          setpanNumber("");
          setnameOnPan("");
          setpanDob("");
          setpanState("");
          setpanImage();
          setpanStatus();
        } catch {
          alertErrorMessage(result.message);
        }
      } else {
        // alertErrorMessage(result.message);
      }
    });
  };


  /* custom pagination */
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(20);
  const handlePageChange = ({ selected }) => {
    setCurrentPage(selected + 1);
  };

  const pageCount = Math.ceil(userList.length / itemsPerPage);
  const currentItems = userList.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );
  // custom paginatio ends

  const handleSearch = (e) => {
    let searchQuery = e.target.value?.toLowerCase();
    let filteredArr = userList2?.filter(item => item?.full_name?.toLowerCase()?.includes(searchQuery) || item?.mobile_number?.includes(searchQuery) || item?.email?.toLowerCase()?.includes(searchQuery));
    setuserList(filteredArr);
  };


  return (
    <>
      <div id="layoutSidenav_content">
        <main>
          <header className="page-header page-header-dark bg-gradient-primary-to-secondary pb-10">
            <div className="container-xl px-4">
              <div className="page-header-content pt-4">
                <div className="row align-items-center justify-content-between">
                  <div className="col-auto mt-4">
                    <h1 className="page-header-title">
                      <div className="page-header-icon">
                        <i className="ri-group-line"></i>
                      </div>
                      Users List
                    </h1>
                  </div>
                </div>
              </div>
            </div>
          </header>
          <div className="container-xl px-4 mt-n10">
            <div className="card mb-4">
              <div className="card-body">
                <div class=" filter_bar mb-4 mb-md-5">
                  <form method="get" accept-charset="utf-8" novalidate="novalidate" class="form-inline search_form" action="/admin/users/referraldata">
                    <div class="row gx-2 align-items-center">

                      <div class="form-group mb-0 col-sm-6 col-md-3">
                        <div class=" form-check ms-2" >
                          <input class="form-check-input text-lg" type="checkbox" id="unverified" onChange={(e) => { UserList(0, e.target.checked) }} />
                          <label class="form-check-label text-lg" for="unverified">
                            Show today registerd user
                          </label>
                        </div>
                      </div>


                    </div>
                  </form>
                </div>
                <div class=" filter_bar mb-2mb-md-5">
                  <div class="row gx-2 align-items-center">
                    <div class="form-group  mb-0 col-5">
                      <div class="input text">
                        <input type="text" class="form-control" placeholder="Search here..." onChange={handleSearch} />
                      </div>
                    </div>
                  </div>
                </div>
                <table id="datatablesSimple">
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Registration Date</th>
                      <th>Name</th>
                      <th>Email</th>
                      <th>Mobile</th>
                      <th>Aadhar</th>
                      <th>DL</th>
                      <th>PAN</th>
                      <th>Voter</th>
                      <th>UPI</th>
                      <th>Bank</th>
                      <th>Total Balance</th>
                      <th>Total Withdrawal</th>
                      <th>Total Deposit</th>
                      <th>Winning Amount</th>
                      <th>Status</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tfoot>
                    <tr>
                      <th>#</th>
                      <th>Registration Date</th>
                      <th>Name</th>
                      <th>Email</th>
                      <th>Mobile</th>
                      <th>Aadhar</th>
                      <th>DL</th>
                      <th>PAN</th>
                      <th>Voter</th>
                      <th>UPI</th>
                      <th>Bank</th>
                      <th>Total Balance</th>
                      <th>Total Withdrawal</th>
                      <th>Total Deposit</th>
                      <th>Winning Amount</th>
                      <th>Status</th>
                      <th>Actions</th>
                    </tr>
                  </tfoot>
                  <tbody>
                    {currentItems
                      ? currentItems?.map((data, index) => {
                        let objIndex;
                        objIndex = userList.findIndex(check => check?._id == data._id);
                        return (
                          <tr key={index}>
                            <td>{skip + index + 1}</td>
                            <td><Moment date={data?.createdAt} format="YYYY/MM/DD HH:mm" /></td>
                            <td>{data?.full_name}</td>
                            <td>{data?.email}</td>
                            <td>{data?.mobile_number}</td>
                            <td>{data?.adhar_verified === 1 ? 'Approved' : data?.adhar_verified === 2 ? 'Pending' : data?.adhar_verified === 3 ? 'Reject' : 'Not Submitted'}</td>
                            <td>{data?.dl_verified === 1 ? 'Approved' : data?.dl_verified === 2 ? 'Pending' : data?.dl_verified === 3 ? 'Reject' : 'Not Submitted'}</td>
                            <td>{data?.pan_verified === 1 ? 'Approved' : data?.pan_verified === 2 ? 'Pending' : data?.pan_verified === 3 ? 'Reject' : 'Not Submitted'}</td>
                            <td>{data?.voter_verified === 1 ? 'Approved' : data?.voter_verified === 2 ? 'Pending' : data?.voter_verified === 3 ? 'Reject' : 'Not Submitted'}</td>
                            <td>{data?.upi_verified === 1 ? 'Approved' : data?.upi_verified === 2 ? 'Pending' : data?.upi_verified === 3 ? 'Reject' : 'Not Submitted'}</td>
                            <td>{data?.bank_verified === 1 ? 'Approved' : data?.bank_verified === 2 ? 'Pending' : data?.bank_verified === 3 ? 'Reject' : 'Not Submitted'}</td>
                            <td>{parseFloat(data?.total_balance?.toFixed(3))}</td>
                            <td>{parseFloat(data?.total_withdrawl?.toFixed(3))}</td>
                            <td>{parseFloat(data?.totaldeposit?.toFixed(3))}</td>
                            <td>{parseFloat(data?.winning_amount?.toFixed(3))}</td>
                            {data?.status === "Active" ?
                              <td>
                                <button type="btn" className="btn badge bg-success text-white rounded-pill" onClick={() => { UserStatus("Inactive", data?._id); }}>
                                  {data?.status}
                                </button>
                              </td>
                              :
                              <td>
                                <button type="btn" className="btn badge bg-danger text-white rounded-pill" onClick={() => { UserStatus("Active", data?._id); }}>
                                  {data?.status}
                                </button>
                              </td>
                            }
                            <td>
                              <div className="table_action_group">
                                {/* <button type="btn" className="btn badge bg-success text-white rounded-pill" data-bs-toggle="modal" data-bs-target="#editUser" onClick={() => { handleEditDetails(data); }} 
                                >Edit / View
                                </button> */}
                                <a href={`/UserDetails/${data?._id}`} type="btn" className="btn badge bg-success text-white rounded-pill">
                                  View Details
                                </a>
                                <button type="btn" className="btn badge bg-secondary text-white rounded-pill" data-bs-toggle="modal" data-bs-target="#editBank" onClick={() => { Viewbankdetails(data?._id); }}>
                                  Bank
                                </button>
                                <button type="btn" className="btn badge bg-dark text-white rounded-pill" data-bs-toggle="modal" data-bs-target="#editPancard" onClick={() => { ViewPANdetails(data?.id) }}>
                                  Pan Card
                                </button>
                              </div>
                            </td>
                          </tr>
                        );
                      })
                      : <h6 className="ifnoData">  No Data Available </h6>}
                  </tbody>
                </table>
                <div className="row d-flex justify-content-center">
                  <button type="btn" class="btn badge bg-primary text-white rounded-pill mx-2 p-3 col-1" disabled={skip === 0} onClick={() => { UserList(0) }}>{"<<<<"}</button>
                  <button type="btn" class="btn badge bg-primary text-white rounded-pill mx-2 p-3  col-2" disabled={skip === 0} onClick={() => { UserList(skip - 20) }}>{"<<Previous"}</button>
                  <button type="btn" class="btn badge bg-primary text-white rounded-pill mx-2 p-3  col-2" disabled={userList2?.length < 20} onClick={() => { UserList(skip + 20) }}>{"Next>>"}</button>
                  <button type="btn" class="btn badge bg-primary text-white rounded-pill mx-2 p-2  col-1" disabled={userList2?.length < 20} onClick={() => { UserList(Math.floor(lastLength / 20) * 20) }}>{">>>>"}</button>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>


      {/* Edit User Details */}
      <div className="modal" id="editUser" tabIndex="-1" aria-labelledby="editwallrtlmodalTitle" style={{ display: "none" }} aria-hidden="true">
        <div className="modal-dialog  alert_modal modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="editwallrtlmodalTitle">
                Edit User Details
              </h5>
              <button className="btn-close" type="button" data-bs-dismiss="modal" aria-label="Close"
              ></button>
              <hr />
            </div>
            <div className="modal-body">
              <form>
                <div className="form-group  mb-3 position-relative ">
                  <label className="small mb-1"> First Name </label>
                  <input type="text" name="first_name" maxLength="30" className="form-control" placeholder="First Name" id="first-name"
                    value={fName ? fName : ''} onChange={(e) => { setfName(e.target.value); }} />
                </div>
                <div className="form-group  mb-3 position-relative ">
                  <label htmlFor="first-name">Last Name </label>
                  <input type="text" name="first_name" maxLength="30" className="form-control" placeholder="First Name" id="first-name" value={lName ? lName : ''} onChange={(e) => { setlName(e.target.value); }} />
                </div>
                <div className="form-group  mb-3 position-relative ">
                  <label htmlFor="email">Email / Phone Number</label>
                  <input type="email" name="email" className="form-control" placeholder="Email / Phone Number" maxLength="255" id="email"
                    value={email ? email : ''} onChange={(e) => { setemail(e.target.value); }} />
                </div>
                <div className="form-group  mb-3 position-relative ">
                  <label htmlFor="userName">User Name</label>
                  <input type="text" name="userName" className="form-control" placeholder="User Name" id="userName" value={userName ? userName : ''} onChange={(e) => { setuserName(e.target.value); }} />
                </div>
                <div className="form-group  position-relative mt-4">
                  <button className="btn btn-primary   btn-block w-100" type="button" onClick={() => { editUser(userId, fName, lName, email, userName); }}>Submit Details
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>


      {/* Edit Pan Details */}
      <div className="modal" id="editPancard" tabIndex="-1" aria-labelledby="editwallrtlmodalTitle" style={{ display: "none" }} aria-hidden="true"     >
        <div className="modal-dialog  alert_modal modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="editwallrtlmodalTitle">
                Pan Details
              </h5>
              <button className="btn-close" type="button" data-bs-dismiss="modal" aria-label="Close"></button>
              <hr />
            </div>
            <div className="modal-body">
              <form>
                <div className="form-group  mb-3 position-relative ">
                  <label className="small mb-1"> Pan Number </label>
                  <input type="text" name="panNumber" maxLength="30" className="form-control" id="first-name" value={panNumber} onChange={(e) => handlePanInput(e)} />
                </div>
                <div className="form-group  mb-3 position-relative ">
                  <label className="small mb-1"> Name </label>
                  <input type="text" name="panName" maxLength="30" className="form-control" id="first-name" value={nameOnPan} onChange={(e) => handlePanInput(e)} />
                </div>
                <div className="form-group  mb-3 position-relative ">
                  <label className="small mb-1"> DOB </label>
                  <input type="date" name="panDob" maxLength="30" className="form-control" id="first-name" value={panDob} onChange={(e) => handlePanInput(e)} />
                </div>
                <div className="form-group  mb-3 position-relative ">
                  <div className="vs_team" style={{ alignItems: 'center' }}>
                    <a href={ApiConfig?.appUrl + '/uploads/' + panImage} target='_blank' rel="noreferrer" >
                      <img src={panImage2 ? panImage2 : `${ApiConfig?.appUrl + '/uploads/' + panImage}`} width="50" height="50" alt="" /></a>
                    <label className="small mb-1">  </label>
                    <input type="file" name="panImage" maxLength="30" className="form-control" id="first-name" onChange={(e) => handlePanInput(e)} />
                  </div>
                </div>
                <div className="form-group  mb-3 position-relative ">
                  <label className="small mb-1"> State </label>
                  <input type="text" name="panState" maxLength="30" className="form-control" id="first-name" value={panState} onChange={(e) => handlePanInput(e)} />
                </div>
                <div className="form-group  mb-3 position-relative ">
                  <label className="small mb-1"> Status </label>
                  <input type="text" name="panState" maxLength="30" className="form-control" id="first-name" disabled value={panStatus === 1 ? 'Active' : panStatus === 0 ? 'Inactive' : ''} />
                </div>
                <div className="form-group  position-relative mt-4">
                  {panStatus ? <button className="btn btn-primary   btn-block w-100" type="button" onClick={() => { editPANdtl(panNumber, nameOnPan, panDob, panImage, panState); }}>Edit Pan</button> :
                    <button className="btn btn-primary   btn-block w-100" type="button" onClick={() => { AddPan(panNumber, nameOnPan, panDob, panImage, panState); }} >Add Pan Details</button>}
                </div>
                <button className="btn btn-Danger btn-block w-100" type="button" onClick={() => { dltPandetails(userId); }}>
                  Delete
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>

      {/* Edit Bank Details */}
      <div className="modal" id="editBank" tabIndex="-1" aria-labelledby="editwallrtlmodalTitle" style={{ display: "none" }} aria-hidden="true"      >
        <div className="modal-dialog  alert_modal modal-dialog-centered" role="document"        >
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="editwallrtlmodalTitle">
                Bank Details
              </h5>
              <button className="btn-close" type="button" data-bs-dismiss="modal" aria-label="Close"></button>
              <hr />
            </div>
            <div className="modal-body">
              <form>
                <div className="form-group  mb-3 position-relative ">
                  <label className="small mb-1"> Account Number </label>
                  <input type="number" name="bankNumber" maxLength="30" className="form-control" id="first-name" value={accNumber} onChange={(e) => handleBankImput(e)} />
                </div>
                <div className="form-group  mb-3 position-relative ">
                  <label className="small mb-1"> IFSC Code </label>
                  <input type="text" name="bankCode" maxLength="30" className="form-control" id="first-name" value={ifsc} onChange={(e) => handleBankImput(e)} />
                </div>
                <div className="form-group  mb-3 position-relative ">
                  <label className="small mb-1"> Branch </label>
                  <input type="text" name="branch" maxLength="30" className="form-control" id="first-name" value={branch} onChange={(e) => handleBankImput(e)} />
                </div>
                <div className="form-group  mb-3 position-relative ">
                  <label className="small mb-1"> Bank Name </label>
                  <input type="text" name="bakName" maxLength="30" className="form-control" id="first-name" value={bankName} onChange={(e) => handleBankImput(e)} />
                </div>
                <div className="form-group  mb-3 position-relative ">
                  <div className="vs_team" style={{ alignItems: 'center' }}>
                    <a href={ApiConfig?.appUrl + '/uploads/' + bankImage} target='_blank' rel="noreferrer" >
                      <img src={bankImage2 ? bankImage2 : `${ApiConfig?.appUrl + '/uploads/' + bankImage}`} width="50" height="50" alt="" /></a>

                    <label className="small mb-1"></label>
                    <input type="file" name="bankImage" maxLength="30" className="form-control" id="first-name" onChange={(e) => handleBankImput(e)} />
                  </div>
                </div>
                <div className="form-group  mb-3 position-relative ">
                  <label className="small mb-1"> Status </label>
                  <input type="text" name="bankStatus" maxLength="30" className="form-control" id="first-name" disabled value={bankStatus === 1 ? 'Active' : bankStatus === 0 ? 'Inactive' : ''} />
                </div>
                <div className="form-group  position-relative mt-4">
                  {bankStatus ? <button className="btn btn-primary   btn-block w-100" type="button" onClick={() => { editbnkdtl(accNumber, ifsc, branch, bankName, bankImage); }}                  >
                    Edit Details

                  </button> :
                    <button className="btn btn-primary   btn-block w-100" type="button" onClick={() => { AddBankacc(accNumber, ifsc, branch, bankName, bankImage); }}                    >
                      Add Bank
                    </button>}

                </div>
                <button className="btn btn-Danger btn-block w-100" type="button" onClick={() => { dltbankdetails(userId); }}>
                  Delete
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default UsersList;
