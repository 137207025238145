import React, { useEffect, useState } from 'react'
import Moment from 'react-moment';
import { ApiConfig } from '../../api/apiConfig/ApiConfig';
import AuthService from '../../api/services/HomeService';
import { alertErrorMessage, alertSuccessMessage } from '../../Components/CustomAlertMessage';
import LoaderHelper from '../../Components/Loading/LoaderHelper';
import Header from '../Header'
import Sidebar from '../Sidebar'
import ReactPaginate from 'react-paginate';

const CategoryList = () => {
    const [categoryList, setcategoryList] = useState([]);

    const [catogryName, setcatogryName] = useState('');
    const [description, setdescription] = useState('');
    const [seqNumber, setseqNumber] = useState('');
    const [image, setimage] = useState();
    const [userId, setuserId] = useState('');


    useEffect(() => {
        handlecatogryList()
    }, []);

    const handlecatogryList = async () => {
        LoaderHelper.loaderStatus(true);
        await AuthService.getCategorylist().then((result) => {
            if (result?.success) {
                LoaderHelper.loaderStatus(false);
                try {
                    setcategoryList(result?.data?.reverse())
                    // alertSuccessMessage('List Fetched Successfully!!');
                } catch {
                    alertErrorMessage(result.message);
                }
            } else {
                LoaderHelper.loaderStatus(false);
                alertErrorMessage(result.message);
                console.log('Something went wrong');
            }
        })
    };

    const deletecatogary = async (id) => {
        await AuthService.deleteCategory(id).then((result) => {
            if (result?.success) {
                try {
                    handlecatogryList()
                } catch {

                }
            } else {
                alertErrorMessage(result?.message)
            }
        })
    };

    const categorystatus = async (id, status) => {
        await AuthService.Setstatus(id, status).then((result) => {
            if (result?.success) {
                try {
                    handlecatogryList()
                } catch {
                }
            } else {

                console.log('Something went wrong');
            }
        })
    };
    const EditCategory = async (userId, catogryName, description, seqNumber) => {

        await AuthService.setCatogery(userId, catogryName, description, seqNumber).then((result) => {
            if (result?.success) {
                try {
                    alertSuccessMessage('Category Edited Successfully')
                    handlecatogryList()
                } catch {
                }
            } else {

                console.log('Something went wrong');
            }
        })
    };


    const handleEditDetails = (data) => {
        setuserId(data?._id)
        setcatogryName(data?.categoryName)
        setdescription(data?.Description)
        setseqNumber(data?.sequence)
        setimage(data?.imagepath)
    }

    /* custom pagination */

    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(5);


    const handlePageChange = ({ selected }) => {
        setCurrentPage(selected + 1);
    };

    const pageCount = Math.ceil(categoryList.length / itemsPerPage);
    const currentItems = categoryList.slice(
        (currentPage - 1) * itemsPerPage,
        currentPage * itemsPerPage
    );
    // custom paginatio ends

    return (

        <>
            <div id="layoutSidenav_content">
                <main>
                    <header class="page-header page-header-dark bg-gradient-primary-to-secondary pb-10">
                        <div class="container-xl px-4">
                            <div class="page-header-content pt-4">
                                <div class="row align-items-center justify-content-between">
                                    <div class="col-auto mt-4">
                                        <h1 class="page-header-title">
                                            <div class="page-header-icon"><i class="ri-list-check-2"></i></div>
                                            Category List
                                        </h1>
                                        {/* <!-- <div class="page-header-subtitle">Example dashboard overview and content summary</div> --> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </header>
                    {/* <!-- Main page content-->
            <!-- Example Colored Cards for Dashboard Demo--> */}
                    <div class="container-xl px-4 mt-n10">

                        <div class="card mb-4">

                            <div class="card-body">

                                <table id="datatablesSimple">
                                    <thead>
                                        <tr>
                                            <th>#</th>
                                            <th>CreatedAt</th>
                                            <th>Category Name</th>
                                            <th>Description</th>
                                            <th>Sequence No.</th>
                                            <th>Status</th>
                                            <th>Actions</th>
                                        </tr>
                                    </thead>
                                    <tfoot>
                                        <tr>
                                            <th>#</th>
                                            <th>createdAt</th>
                                            <th>categoryName</th>
                                            <th>Description</th>
                                            <th>Sequence No.</th>
                                            <th>status</th>
                                            <th>Actions</th>
                                        </tr>
                                    </tfoot>
                                    <tbody>
                                        {currentItems ? currentItems?.map((data, index) => {
                                            let objIndex;
                                            objIndex = categoryList.findIndex(check => check?._id == data._id);
                                            return (
                                                <tr>
                                                    <td>{objIndex + 1}</td>
                                                    <td><Moment date={data?.createdAt} format="YYYY/MM/DD HH:mm" /></td>
                                                    <td>{data?.categoryName}</td>
                                                    {/* <td class="text-center"> <img src={`${ApiConfig?.appUrl + 'uploads/' + data?.imagepath}`} hight="100px" width="40px" alt="" /> </td> */}
                                                    <td>{data?.Description}</td>
                                                    <td>{data?.sequence}</td>
                                                    {data?.status === 'Active' ? <td><button type="btn" class="btn badge bg-success text-white rounded-pill" o onClick={() => { categorystatus(data?._id, 'Inactive') }}>{data?.status}</button></td> : <td><button type="btn" class="btn badge bg-danger text-white rounded-pill" onClick={() => { categorystatus(data?._id, 'Active') }}  >{data?.status}</button></td>}
                                                    <td>
                                                        <div class="table_action_group">
                                                            <button type="btn" class="btn badge bg-success text-white rounded-pill" data-bs-toggle="modal" data-bs-target="#editCategory" onClick={() => { handleEditDetails(data) }}>Edit</button>
                                                            <button type="btn" class="btn badge bg-danger text-white rounded-pill" onClick={() => { deletecatogary(data?._id) }}>Delete</button>
                                                        </div>
                                                    </td>
                                                </tr>
                                            )
                                        }) : ''}

                                    </tbody>
                                </table>
                                {/* custom pagination */}
                                <ReactPaginate
                                    pageCount={pageCount}
                                    pageRangeDisplayed={3}
                                    marginPagesDisplayed={2}
                                    onPageChange={handlePageChange}
                                    containerClassName={'customPagination'}
                                    activeClassName={'active'}
                                />
                            </div>
                        </div>
                    </div>
                </main>
            </div>
            <div class="modal" id="editCategory" tabIndex="-1" aria-labelledby="editwallrtlmodalTitle" style={{ display: 'none' }} aria-hidden="true">
                <div class="modal-dialog  alert_modal modal-dialog-centered" role="document">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="editwallrtlmodalTitle">Edit User Details</h5>
                            <button class="btn-close" type="button" data-bs-dismiss="modal" aria-label="Close"></button>
                            <hr />
                        </div>
                        <div class="modal-body">

                            <form>
                                <div class="form-group  mb-3 position-relative ">
                                    <label for="category-name">Category Name</label>
                                    <input type="text" name="category_name" maxLength="25" class="form-control" placeholder="Category Name" id="category-name"
                                        onChange={(e) => { setcatogryName(e.target.value) }}
                                        value={catogryName}
                                    />
                                </div>
                                <div class="form-group  mb-3 position-relative ">
                                    <label for="description">Description </label>
                                    <input type="text" name="description" maxLength="150" class="form-control" placeholder="Description" id="description" onChange={(e) => { setdescription(e.target.value) }}
                                        value={description} />
                                </div>
                                <div class="form-group  mb-3 position-relative ">
                                    <label for="sequence">Sequence Number </label>
                                    <input type="text" name="sequence" maxLength="5" class="form-control" placeholder="Sequence" id="sequence" onChange={(e) => { setseqNumber(e.target.value) }}
                                        value={seqNumber} />
                                </div>
                                <div class="form-group  position-relative mt-4">
                                    <button data-bs-dismiss="modal" class="btn btn-primary   btn-block w-100" type="button" onClick={() => { EditCategory(userId, catogryName, description, seqNumber, image) }} >Submit Details</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default CategoryList
