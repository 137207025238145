import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import AuthService from '../../api/services/HomeService';
import { alertErrorMessage } from '../../Components/CustomAlertMessage';
import LoaderHelper from '../../Components/Loading/LoaderHelper';
import Moment from 'react-moment';

const UserDetails = () => {
    let { userId } = useParams();
    const [panDetails, setPanDetails] = useState({});
    const [aadharDetails, setAadharDetails] = useState({});
    const [dlDetails, setDlDetails] = useState({});
    const [bankDetails, setBankDetails] = useState({});
    const [upiDetails, setUpiDetails] = useState({});
    const [voterDetails, setVoterDetails] = useState({});
    const [userprofile, setUserprofile] = useState({});

    const getUserDetails = async (category) => {
        LoaderHelper.loaderStatus(true);
        await AuthService.UserDetails(category, userId).then((result) => {
            LoaderHelper.loaderStatus(false);
            if (result?.success) {
                try {
                    if (category === 'PAN') {
                        setPanDetails(result?.data)
                    }
                    else if (category === 'Adahr') {
                        setAadharDetails(result?.data)
                    }
                    else if (category === 'DL') {
                        setDlDetails(result?.data)
                    }
                    else if (category === 'BANK') {
                        setBankDetails(result?.data)
                    }
                    else if (category === 'UPI') {
                        setUpiDetails(result?.data)
                    }
                    else if (category === 'voter') {
                        setVoterDetails(result?.data)
                    }
                } catch {
                    alertErrorMessage(result.message);
                }
            } else {
                alertErrorMessage(result.message);
            }
        });
    };

    const getUserProfile = async () => {
        LoaderHelper.loaderStatus(true);
        await AuthService.ViewUserProfile(userId).then((result) => {
            LoaderHelper.loaderStatus(false);
            if (result?.success) {
                try {
                    setUserprofile(result?.data)
                } catch {
                    alertErrorMessage(result.message);
                }
            } else {
                alertErrorMessage(result.message);
            }
        });
    };

    useEffect(() => {
        getUserDetails('PAN');
        getUserProfile();
    }, []);

    return (
        <>
            <div id="layoutSidenav_content" >
                <main>
                    <header className="page-header page-header-dark bg-gradient-primary-to-secondary pb-10">
                        <div className="container-xl px-4">
                            <div className="page-header-content pt-4">
                                <div className="row align-items-center justify-content-between">
                                    <div className="col-auto mt-4">
                                        <h1 className="page-header-title">
                                            <a href='/UsersList' className="page-header-icon cursor-pointer" >
                                                <i class="ri-arrow-left-line "></i>
                                            </a>
                                            User Details
                                        </h1>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </header>

                    <div class="container-xl px-4 mt-n10" >
                        <div class="card mb-4">
                            <div class="card-body d-flex justify-content-start flex-column pt-3 ">
                                <div class="d-flex align-items-center justify-content-start mb-1">
                                    <div class=""><h4 class="mb-0 "><strong>User Profile</strong></h4></div>
                                </div>
                                <span >User ID: {userprofile?._id}</span>
                                <span >Name: {userprofile?.full_name}</span>
                                <span >Phone: {userprofile?.mobile_number}</span>
                            </div>
                            <div className='card-header' >
                                <ul class="nav nav-pills mb-0" id="pills-tab" role="tablist">
                                    <li class="nav-item" role="presentation">
                                        <button class="nav-link active" id="pills-Profile-tab" data-bs-toggle="pill" data-bs-target="#pills-Profile" type="button" role="tab" aria-controls="pills-Profile" aria-selected="true" onClick={() => { getUserDetails('PAN') }}>Profile</button>
                                    </li>
                                    <li class="nav-item" role="presentation">
                                        <button class="nav-link " id="pills-PAN-tab" data-bs-toggle="pill" data-bs-target="#pills-PAN" type="button" role="tab" aria-controls="pills-PAN" aria-selected="true" onClick={() => { getUserDetails('PAN') }}>PAN</button>
                                    </li>
                                    <li class="nav-item" role="presentation">
                                        <button class="nav-link" id="pills-Aadhar-tab" data-bs-toggle="pill" data-bs-target="#pills-Aadhar" type="button" role="tab" aria-controls="pills-Aadhar" aria-selected="false" onClick={() => { getUserDetails('Adahr') }} >Aadhar</button>
                                    </li>
                                    <li class="nav-item" role="presentation">
                                        <button class="nav-link" id="pills-DL-tab" data-bs-toggle="pill" data-bs-target="#pills-DL" type="button" role="tab" aria-controls="pills-DL" aria-selected="false" onClick={() => { getUserDetails('DL') }}>DL</button>
                                    </li>
                                    <li class="nav-item" role="presentation">
                                        <button class="nav-link" id="pills-Bank-tab" data-bs-toggle="pill" data-bs-target="#pills-Bank" type="button" role="tab" aria-controls="pills-Bank" aria-selected="false" onClick={() => { getUserDetails('BANK') }}>Bank</button>
                                    </li>
                                    <li class="nav-item" role="presentation">
                                        <button class="nav-link" id="pills-UPI-tab" data-bs-toggle="pill" data-bs-target="#pills-UPI" type="button" role="tab" aria-controls="pills-UPI" aria-selected="false" onClick={() => { getUserDetails('UPI') }}>UPI</button>
                                    </li>
                                    <li class="nav-item" role="presentation">
                                        <button class="nav-link" id="pills-Voter-tab" data-bs-toggle="pill" data-bs-target="#pills-Voter" type="button" role="tab" aria-controls="pills-Voter" aria-selected="false" onClick={() => { getUserDetails('voter') }}>Voter ID</button>
                                    </li>
                                </ul>
                            </div>
                            <div class="card-body tab-content" id="pills-tabContent">

                                {/* Profile Tab */}
                                <div class="tab-pane fade show active" id="pills-Profile" role="tabpanel" aria-labelledby="pills-Profile-tab">
                                    <div className='row'>
                                        <div class="card-body d-flex justify-content-start flex-column pt-3 col-4">
                                            <div class="d-flex align-items-center justify-content-start mb-1">
                                                <div class=""><h4 class="mb-0 "><strong>Profile Details</strong></h4></div>
                                            </div>
                                            <span >User ID: {userprofile?._id}</span>
                                            <span >Name: {userprofile?.full_name}</span>
                                            <span >Phone: {userprofile?.mobile_number}</span>
                                            <span >Email: {userprofile?.email}</span>
                                            <span >Registration Date: <Moment date={userprofile?.createdAt} format="YYYY/MM/DD HH:mm" /></span>
                                        </div>
                                        <div class="card-body d-flex justify-content-start flex-column pt-3 col-4">
                                            <div class="d-flex align-items-center justify-content-start mb-1">
                                                <div class=""><h4 class="mb-0 "><strong>Verification Status</strong></h4></div>
                                            </div>
                                            <span className=''>Aadhar Status: <span className={userprofile?.adhar_verified === 1 ? 'text-success' : 'text-warning'}>{userprofile?.adhar_verified === 1 ? 'Approved' : userprofile?.adhar_verified === 2 ? 'Pending' : userprofile?.adhar_verified === 3 ? 'Reject' : 'Not Submitted'}</span></span>

                                            <span >DL Status:  <span className={userprofile?.dl_verified === 1 ? 'text-success' : 'text-warning'}>{userprofile?.dl_verified === 1 ? 'Approved' : userprofile?.dl_verified === 2 ? 'Pending' : userprofile?.dl_verified === 3 ? 'Reject' : 'Not Submitted'}</span></span>
                                            <span >PAN Status:  <span className={userprofile?.pan_verified === 1 ? 'text-success' : 'text-warning'}>{userprofile?.pan_verified === 1 ? 'Approved' : userprofile?.pan_verified === 2 ? 'Pending' : userprofile?.pan_verified === 3 ? 'Reject' : 'Not Submitted'}</span></span>
                                            <span >Voter Id Status: <span className={userprofile?.voter_verified === 1 ? 'text-success' : 'text-warning'}>{userprofile?.voter_verified === 1 ? 'Approved' : userprofile?.voter_verified === 2 ? 'Pending' : userprofile?.voter_verified === 3 ? 'Reject' : 'Not Submitted'}</span></span>
                                            <span >Bank Status:  <span className={userprofile?.bank_verified === 1 ? 'text-success' : 'text-warning'}>{userprofile?.bank_verified === 1 ? 'Approved' : userprofile?.bank_verified === 2 ? 'Pending' : userprofile?.bank_verified === 3 ? 'Reject' : 'Not Submitted'}</span></span>
                                            <span >UPI Status: <span className={userprofile?.upi_verified === 1 ? 'text-success' : 'text-warning'}> {userprofile?.upi_verified === 1 ? 'Approved' : userprofile?.upi_verified === 2 ? 'Pending' : userprofile?.upi_verified === 3 ? 'Reject' : 'Not Submitted'}</span></span>
                                        </div>
                                        <div class="card-body d-flex justify-content-start flex-column pt-3 col-4">
                                            <div class="d-flex align-items-center justify-content-start mb-1">
                                                <div class=""><h4 class="mb-0 "><strong>Balance</strong></h4></div>
                                            </div>
                                            <span >Total Balance: {userprofile?.total_balance}</span>
                                            <span >Total Deposit: {userprofile?.totaldeposit}</span>
                                            <span >Total Withdrawal: {userprofile?.total_withdrawl}</span>
                                            <span >Cash Bonus: {userprofile?.cash_bonus}</span>
                                            <span >Winning: {userprofile?.winning_amount}</span>
                                        </div>
                                    </div>
                                    <div className='row'>

                                    </div>
                                </div>

                                {/* PAN Tab */}
                                <div class="tab-pane fade " id="pills-PAN" role="tabpanel" aria-labelledby="pills-PAN-tab">
                                    <table id=" " className='dataTable-table' >
                                        <thead>
                                            <tr role="row">
                                                <th class="sorting_asc" tabIndex="0" aria-controls="example1" rowspan="1" colspan="1"  >PAN Name</th>
                                                <th class="sorting_asc" tabIndex="0" aria-controls="example1" rowspan="1" colspan="1"  >PAN Number</th>
                                                <th class="sorting_asc" tabIndex="0" aria-controls="example1" rowspan="1" colspan="1"  >PAN Verfied</th>
                                                <th class="sorting_asc" tabIndex="0" aria-controls="example1" rowspan="1" colspan="1"  >PAN Type</th>
                                                <th class="sorting_asc" tabIndex="0" aria-controls="example1" rowspan="1" colspan="1"  >Match Score</th>
                                            </tr>
                                        </thead>
                                        <tbody>

                                            <tr role="row" class="odd">
                                                <td class="">{panDetails?.NameOnPan}</td>
                                                <td class="">{panDetails?.pan_number}</td>
                                                <td class="">{panDetails?.pan_verified === 1 ? 'Approved' : panDetails?.pan_verified === 2 ? 'Pending' : panDetails?.pan_verified === 3 ? 'Reject' : 'Not Submitted'}</td>
                                                <td class="">{panDetails?.pan_type}</td>
                                                <td class="">{panDetails?.name_match_score}</td>

                                            </tr>

                                        </tbody>
                                    </table>
                                </div>

                                {/* Aadhar Tab */}
                                <div class="tab-pane fade" id="pills-Aadhar" role="tabpanel" aria-labelledby="pills-Aadhar-tab">
                                    <table id=" " className='dataTable-table' >
                                        <thead>
                                            <tr role="row">
                                                <th class="sorting_asc" tabIndex="0" aria-controls="example1" rowspan="1" colspan="1"  >Aadhar Name</th>
                                                <th class="sorting_asc" tabIndex="0" aria-controls="example1" rowspan="1" colspan="1"  >Aadhar Number</th>
                                                <th class="sorting_asc" tabIndex="0" aria-controls="example1" rowspan="1" colspan="1"  >Aadhar Verified</th>
                                            </tr>
                                        </thead>
                                        <tbody>

                                            <tr role="row" class="odd">
                                                <td class="">{aadharDetails?.adahr_name}</td>
                                                <td class="">{aadharDetails?.adhar_no}</td>
                                                <td class="">{aadharDetails?.adhar_verified === 1 ? 'Approved' : aadharDetails?.adhar_verified === 2 ? 'Pending' : aadharDetails?.adhar_verified === 3 ? 'Reject' : 'Not Submitted'}</td>

                                            </tr>
                                        </tbody>
                                    </table>

                                </div>


                                {/* DL Tab */}
                                <div class="tab-pane fade" id="pills-DL" role="tabpanel" aria-labelledby="pills-DL-tab">
                                    <table id=" " className='dataTable-table' >
                                        <thead>
                                            <tr role="row">
                                                <th class="sorting_asc" tabIndex="0" aria-controls="example1" rowspan="1" colspan="1"  >DL Name</th>
                                                <th class="sorting_asc" tabIndex="0" aria-controls="example1" rowspan="1" colspan="1"  >DL Number</th>
                                                <th class="sorting_asc" tabIndex="0" aria-controls="example1" rowspan="1" colspan="1"  >DL State</th>
                                                <th class="sorting_asc" tabIndex="0" aria-controls="example1" rowspan="1" colspan="1"  >DL Status</th>
                                                <th class="sorting_asc" tabIndex="0" aria-controls="example1" rowspan="1" colspan="1"  >DOB</th>

                                            </tr>

                                        </thead>
                                        <tbody>

                                            <tr role="row" class="odd">
                                                <td class="">{dlDetails?.user_full_name}</td>
                                                <td class="">{dlDetails?.dl_number}</td>
                                                <td class="">{dlDetails?.state}</td>
                                                <td class="">{dlDetails?.dl_verified === 1 ? 'Approved' : dlDetails?.dl_verified === 2 ? 'Pending' : dlDetails?.dl_verified === 3 ? 'Reject' : 'Not Submitted'}</td>
                                                <td class="">{dlDetails?.user_dob}</td>


                                            </tr>
                                        </tbody>
                                    </table>

                                </div>

                                {/* Bank Tab */}
                                <div class="tab-pane fade" id="pills-Bank" role="tabpanel" aria-labelledby="pills-Bank-tab">
                                    <table id=" " className='dataTable-table' >
                                        <thead>
                                            <tr role="row">
                                                <th class="sorting_asc" tabIndex="0" aria-controls="example1" rowspan="1" colspan="1"  >Bank Name</th>
                                                <th class="sorting_asc" tabIndex="0" aria-controls="example1" rowspan="1" colspan="1"  >Account Number</th>
                                                <th class="sorting_asc" tabIndex="0" aria-controls="example1" rowspan="1" colspan="1"  >Account Holder Name</th>
                                                <th class="sorting_asc" tabIndex="0" aria-controls="example1" rowspan="1" colspan="1"  >Branch</th>
                                                <th class="sorting_asc" tabIndex="0" aria-controls="example1" rowspan="1" colspan="1"  >IFSC Code</th>
                                                <th class="sorting_asc" tabIndex="0" aria-controls="example1" rowspan="1" colspan="1"  >Bank Status</th>

                                            </tr>

                                        </thead>
                                        <tbody>

                                            <tr role="row" class="odd">
                                                <td class="">{bankDetails?.bank_name}</td>
                                                <td class="">{bankDetails?.account_number}</td>
                                                <td class="">{bankDetails?.benificiary_name}</td>
                                                <td class="">{bankDetails?.branch_name}</td>
                                                <td class="">{bankDetails?.ifsc}</td>
                                                <td class="">{bankDetails?.bank_verified === 1 ? 'Approved' : bankDetails?.bank_verified === 2 ? 'Pending' : bankDetails?.bank_verified === 3 ? 'Reject' : 'Not Submitted'}</td>


                                            </tr>
                                        </tbody>
                                    </table>

                                </div>

                                {/* UPI Tab */}
                                <div class="tab-pane fade" id="pills-UPI" role="tabpanel" aria-labelledby="pills-UPI-tab">
                                    <table id=" " className='dataTable-table' >
                                        <thead>
                                            <tr role="row">
                                                <th class="sorting_asc" tabIndex="0" aria-controls="example1" rowspan="1" colspan="1"  >Benificiery Name</th>
                                                <th class="sorting_asc" tabIndex="0" aria-controls="example1" rowspan="1" colspan="1"  >UPI Number</th>
                                                <th class="sorting_asc" tabIndex="0" aria-controls="example1" rowspan="1" colspan="1"  >UPI Status</th>

                                            </tr>

                                        </thead>
                                        <tbody>

                                            <tr role="row" class="odd">
                                                <td class="">{upiDetails?.benificiary_name}</td>
                                                <td class="">{upiDetails?.upi_number}</td>
                                                <td class="">{upiDetails?.upi_verified === 1 ? 'Approved' : upiDetails?.upi_verified === 2 ? 'Pending' : upiDetails?.upi_verified === 3 ? 'Reject' : 'Not Submitted'}</td>


                                            </tr>
                                        </tbody>
                                    </table>

                                </div>

                                {/* Voter Tab */}
                                <div class="tab-pane fade" id="pills-Voter" role="tabpanel" aria-labelledby="pills-Voter-tab">
                                    <table id=" " className='dataTable-table' >
                                        <thead>
                                            <tr role="row">
                                                <th class="sorting_asc" tabIndex="0" aria-controls="example1" rowspan="1" colspan="1"  >Voter Id</th>
                                                <th class="sorting_asc" tabIndex="0" aria-controls="example1" rowspan="1" colspan="1"  >Voter Id Status</th>

                                            </tr>

                                        </thead>
                                        <tbody>

                                            <tr role="row" class="odd">
                                                <td class="">{voterDetails?.voter_id}</td>
                                                <td class="">{voterDetails?.voter_id === 1 ? 'Approved' : voterDetails?.voter_id === 2 ? 'Pending' : voterDetails?.voter_id === 3 ? 'Reject' : 'Not Submitted'}</td>


                                            </tr>
                                        </tbody>
                                    </table>

                                </div>
                            </div>
                        </div>
                    </div>

                </main >
            </div >
        </>
    )
}

export default UserDetails
