import React, { createContext, useEffect, useState } from 'react'

export const context = createContext();

const ContextProvider = ({ children }) => {
    const [contestDeatils, setcontestDeatils] = useState();




    useEffect(() => {
        const btnScrollToTop = document.querySelector("#btnScrollToTop");
        if (btnScrollToTop) {
            btnScrollToTop.addEventListener("click", e => {
                window.scrollTo({
                    top: 0,
                    left: 0,
                    behavior: "smooth"
                });
            });
            window.addEventListener('scroll', e => {
                window.scrollY > 100 ? btnScrollToTop.style.display = "block" : btnScrollToTop.style.display = "none";
            });
        }
    }, []);
    return (
        <>
            <button id='btnScrollToTop' type='button'><i className="ri-arrow-up-line"></i></button>
            <context.Provider value={{ contestDeatils, setcontestDeatils }}> {children}</context.Provider>
        </>
    )
}

export default ContextProvider;
