import React, { useState } from "react"
import Login from "../UI/Login/index";
import ForgotPassword from "../UI/ForgotPassword/index";
import Dashboard from "../UI/Dashboard/index";
import UsersList from "../UI/Users/UsersList";
import AddNewUser from "../UI/Users/AddNewUser";
import UserRefInfo from "../UI/Users/UserRefInfo";
import SubAdminList from "../UI/SubAdmin/SubAdminList";
import AddSubAdmin from "../UI/SubAdmin/AddSubAdmin";
import BankRequest from '../UI/KYCrequest/BankRequest'
import CategoryList from '../UI/CategoryManager/CategoryList'
import AddCategory from '../UI/CategoryManager/AddCategory'
import ContestList from '../UI/ContestManager/ContestList'
import AddContest from '../UI/ContestManager/AddContest'
import SeriesContest from '../UI/CricketModules/SeriesContest'
import ScheduleContest from '../UI/CricketModules/ScheduleContest'
import CrickerPoints from '../UI/CricketModules/CrickerPoints'
import ManagePlayers from '../UI/CricketModules/ManagePlayers'
import TeamsManager from '../UI/CricketModules/TeamsManager'
import ContestDetails from '../UI/CricketModules/ContestDetails'
import PreSquadMathces from '../UI/CricketModules/PreSqaudMatches'
import PaymentList from '../UI/PaymentMethod/PaymentList'
import AddNewPayment from '../UI/PaymentMethod/AddNewPayment'
import TDS from '../UI/TDS/index'
import Transaction from '../UI/Transaction/index'
import WalletManager from '../UI/WalletManager/index'
import Earnings from '../UI/Earnings/index'
import List from '../UI/PaymentOffers/List'
import AddNew from '../UI/PaymentOffers/AddNew'
import BannerList from '../UI/BannerManager/BannerList'
import AddBanner from '../UI/BannerManager/AddBanner'
import WithdrawPending from '../UI/WithdrawPending/index'
import WithdrawConfirmed from '../UI/WithdrawConfirmed/index'
import EmailNotification from '../UI/Notification/EmailNotification'
import SmsNotification from '../UI/Notification/SmsNotification'
import PushNotification from '../UI/Notification/PushNotification'
import ContentList from '../UI/ContentManager/ContentList'
import AddContent from '../UI/ContentManager/AddContent'
import Settings from '../UI/Settings/index'
import EmailTemplate from '../UI/EmailTemplate/index'
import Profile from '../UI/Profile/index'
import ChangePassword from '../UI/ChangePassword/index'
import Header from "../UI/Header";
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Sidebar from "../UI/Sidebar";
import { ToastContainer } from "react-toastify";
import AadharRequest from "../UI/KYCrequest/AadharRequest";
import UPIRequest from "../UI/KYCrequest/UPIRequest";
import ApprovedRequest from "../UI/PanCardRequest/ApprovedRequest";
import PendingRequest from "../UI/PanCardRequest/PendingRequest";
import RejectdRequest from "../UI/PanCardRequest/RejectdRequest";
import DlRequest from "../UI/KYCrequest/DlRequest";
import VoterIdRequest from "../UI/KYCrequest/VoterIdRequest";
import UserDetails from "../UI/Users/UserDetails";


const Routing = () => {
    const token = sessionStorage.getItem('token')
    const permissions = sessionStorage.getItem('permissions');
    const userTypes = sessionStorage.getItem('userType');
    const [permission, setpermissionss] = useState(permissions ? [...new Set(permissions.split(','))] : []);
    const [userType, setuserType] = useState(userTypes ? +userTypes[0] : '');

    return (
        <>
            <Router>
                <ToastContainer position="top-right" autoClose={5000} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss={false} draggable pauseOnHover limit={1} theme="light" />
                {token ? <>
                    <Header />
                    <div id="layoutSidenav">
                        <Sidebar />
                        <Routes>
                            {<Route exact path="/Dashboard" element={<Dashboard />} />}

                            {(userType === 1) &&
                                <> <Route exact path="/AddSubAdmin" element={<AddSubAdmin />} />
                                    <Route exact path="/SubAdminList" element={<SubAdminList />} /></>}

                            {(permission.includes('Users') || userType === 1) && <>
                                <Route exact path="/UsersList" element={<UsersList />} />
                                <Route exact path="/UserDetails/:userId" element={<UserDetails />} />
                                <Route exact path="/AddNewUser" element={<AddNewUser />} />
                                <Route exact path="/UserRefInfo" element={<UserRefInfo />} /></>}

                            {(permission.includes('KYCRequest') || userType === 1) && <>
                                <Route exact path="/BankRequest" element={<BankRequest />} />
                                <Route exact path="/AadharRequest" element={<AadharRequest />} />
                                <Route exact path="/UPIRequest" element={<UPIRequest />} />
                                <Route exact path="/voteridRequest" element={<VoterIdRequest />} />
                                <Route exact path="/DlRequest" element={<DlRequest />} />
                            </>}

                            {(permission.includes('KYCRequest') || userType === 1) && <>
                                <Route exact path="/approved_pancard" element={<ApprovedRequest />} />
                                <Route exact path="/pending_pancard" element={<PendingRequest />} />
                                <Route exact path="/rejected_pancard" element={<RejectdRequest />} /></>}

                            {(permission.includes('CategoryManager') || userType === 1) && <>
                                <Route exact path="/CategoryList" element={<CategoryList />} />
                                <Route exact path="/AddCategory" element={<AddCategory />} /></>}

                            {(permission.includes('ContestManager') || userType === 1) && <>
                                <Route exact path="/ContestList" element={<ContestList />} />
                                <Route exact path="/AddContest" element={<AddContest />} /></>}

                            {(permission.includes('CricketModules') || userType === 1) && <>
                                <Route exact path="/SeriesContest" element={<SeriesContest />} />
                                <Route exact path="/ScheduleContest" element={<ScheduleContest />} />
                                <Route exact path="/ManagePlayers" element={<ManagePlayers />} />
                                <Route exact path="/TeamsManager" element={<TeamsManager />} />
                                <Route exact path="/PreSquadMathces" element={<PreSquadMathces />} />
                                <Route exact path="/ContestDetails" element={<ContestDetails />} />
                                <Route exact path="/CrickerPoints" element={<CrickerPoints />} /> </>}

                            {(permission.includes('PaymentMethod') || userType === 1) && <>
                                <Route exact path="/PaymentList" element={<PaymentList />} />
                                <Route exact path="/AddNewPayment" element={<AddNewPayment />} /></>}

                            {(permission.includes('TDS') || userType === 1) &&
                                <Route exact path="/TDS" element={<TDS />} />}

                            {(permission.includes('Transaction') || userType === 1) &&
                                <Route exact path="/Transaction" element={<Transaction />} />}

                            {(permission.includes('WalletManager') || userType === 1) &&
                                <Route exact path="/WalletManager" element={<WalletManager />} />}

                            {(permission.includes('Earning') || userType === 1) &&
                                <Route exact path="/Earnings" element={<Earnings />} />}

                            {(permission.includes('PaymentOffers') || userType === 1) && <>
                                <Route exact path="/List" element={<List />} />
                                <Route exact path="/AddNew" element={<AddNew />} /></>}

                            {(permission.includes('BannerManager') || userType === 1) && <>
                                <Route exact path="/BannerList" element={<BannerList />} />
                                <Route exact path="/AddBanner" element={<AddBanner />} /></>}

                            {(permission.includes('WithdrawPending') || userType === 1) && <>
                                <Route exact path="/WithdrawPending" element={<WithdrawPending />} />
                            </>}
                            {(permission.includes('WithdrawConfirmed') || userType === 1) &&
                                <Route exact path="/WithdrawConfirmed" element={<WithdrawConfirmed />} />}

                            {(permission.includes('Notification') || userType === 1) && <>
                                <Route exact path="/EmailNotification" element={<EmailNotification />} />
                                <Route exact path="/SmsNotification" element={<SmsNotification />} />
                                <Route exact path="/PushNotification" element={<PushNotification />} /></>}

                            {(permission.includes('ContentManager') || userType === 1) && <>
                                <Route exact path="/ContentList" element={<ContentList />} />
                                <Route exact path="/AddContent" element={<AddContent />} /></>}

                            {(permission.includes('Settings') || userType === 1) &&
                                <Route exact path="/Settings" element={<Settings />} />}

                            {(permission.includes('EmailTemplates') || userType === 1) &&
                                <Route exact path="/EmailTemplate" element={<EmailTemplate />} />}

                            {(userType === 1) &&
                                <Route exact path="/Profile" element={<Profile />} />}

                            {(userType === 1) &&
                                <Route exact path="/ChangePassword" element={<ChangePassword />} />}


                            <Route exact path="/*" element={<Dashboard />} />
                        </Routes>
                    </div>
                </> :
                    <>
                        <Routes>
                            <Route exact path="/" element={<Login />} />
                            <Route exact path="/*" element={<Login />} />
                            <Route exact path="/ForgotPassword" element={<ForgotPassword />} />
                        </Routes>
                    </>}



            </Router>
        </>
    )
}
export default Routing