import React, { useEffect, useState } from 'react'
import ReactPaginate from 'react-paginate';
import AuthService from '../../api/services/HomeService';
import { alertErrorMessage, alertSuccessMessage } from '../../Components/CustomAlertMessage';
import LoaderHelper from '../../Components/Loading/LoaderHelper';
import Header from '../Header'
import Sidebar from '../Sidebar'

const ContentList = () => {
    const [contentLists, setcontentLists] = useState([]);
    const [title, settitle] = useState();
    const [content, setcontent] = useState();
    const [userId, setuserId] = useState();

    useEffect(() => {
        Contentlist()
    }, []);

    const Contentlist = async () => {
        LoaderHelper.loaderStatus(true);
        await AuthService.Findcontest().then((result) => {
            if (result?.success) {
                LoaderHelper.loaderStatus(false);
                try {
                    setcontentLists(result?.data?.reverse())
                } catch {
                    alertErrorMessage(result.message);
                }
            } else {
                LoaderHelper.loaderStatus(false);
                alertErrorMessage(result.message);
            }
        })
    };
    const handleEditDetails = (data) => {
        setuserId(data?._id)
        settitle(data?.Title)
        setcontent(data?.content)
    };

    const EditContent = async (id,title,content) => {
        await AuthService.ContentEdit(id,title,content).then((result) => {
            if (result?.success) {
                try {
                    setcontent('')
                    settitle('')
                    Contentlist()
                    alertSuccessMessage('Content Edited Successfully')
                } catch {
                    alertErrorMessage();
                }
            } else {
                alertErrorMessage(result?.message);
            }
        })
    };
   /* custom pagination */

   const [currentPage, setCurrentPage] = useState(1);
   const [itemsPerPage, setItemsPerPage] = useState(5);


   const handlePageChange = ({ selected }) => {
       setCurrentPage(selected + 1);
   };

   const pageCount = Math.ceil(contentLists.length / itemsPerPage);
   const currentItems = contentLists.slice(
       (currentPage - 1) * itemsPerPage,
       currentPage * itemsPerPage
   );
   // custom paginatio ends

    return (
        <>

                <div id="layoutSidenav_content">
                    <main>
                        <header class="page-header page-header-dark bg-gradient-primary-to-secondary pb-10">
                            <div class="container-xl px-4">
                                <div class="page-header-content pt-4">
                                    <div class="row align-items-center justify-content-between">
                                        <div class="col-auto mt-4">
                                            <h1 class="page-header-title">
                                                <div class="page-header-icon"><i class="ri-article-line"></i></div>
                                                Content List
                                            </h1>
                                            {/* <!-- <div class="page-header-subtitle">Example dashboard overview and content summary</div> --> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </header>
                        {/* <!-- Main page content--> */}
                        {/* <!-- Example Colored Cards for Dashboard Demo--> */}
                        <div class="container-xl px-4 mt-n10">

                            <div class="card mb-4">

                                <div class="card-body">

                                    <table id="datatablesSimple">
                                        <thead>
                                            <tr>
                                                <th>#</th>
                                                <th> Title</th>
                                                <th>Description</th>
                                                <th>Actions</th>
                                            </tr>
                                        </thead>
                                        <tfoot>
                                            <tr>
                                                <th>#</th>
                                                <th> Title</th>
                                                <th>Description</th>
                                                <th>Actions</th>
                                            </tr>
                                        </tfoot>
                                        <tbody>
                                            {currentItems ? currentItems?.map((data,index ) => {
                                                 let objIndex;
                                                 objIndex = contentLists.findIndex(check => check?._id == data._id);
                                                return(
                                                <tr>
                                                    <td>{objIndex + 1}</td>
                                                    <td>{data?.Title}</td>
                                                    <td>{data?.content}</td>
                                                    <td>
                                                        <div class="table_action_group">
                                                            <button type="btn" class="btn badge bg-success text-white rounded-pill" data-bs-toggle="modal" data-bs-target="#editNotification" onClick={() => { handleEditDetails(data) }}>Edit</button>
                                                            {/* <button type="btn" class="btn badge bg-primary text-white rounded-pill">View</button> */}
                                                        </div>
                                                    </td>
                                                </tr>    )
                                            }) : ''}

                                        </tbody>
                                    </table>
                                          {/* custom pagination */}
                                          <ReactPaginate
                                        pageCount={pageCount}
                                        pageRangeDisplayed={3}
                                        marginPagesDisplayed={2}
                                        onPageChange={handlePageChange}
                                        containerClassName={'customPagination'}
                                        activeClassName={'active'}
                                    />
                                </div>
                            </div>
                        </div>




                    </main>
                </div>

            <div class="modal" id="editNotification" tabIndex="-1" aria-labelledby="editwallrtlmodalTitle" style={{ display: 'none' }} aria-hidden="true">
                <div class="modal-dialog  alert_modal modal-dialog-centered" role="document">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="editwallrtlmodalTitle">Edit User Details</h5>
                            <button class="btn-close" type="button" data-bs-dismiss="modal" aria-label="Close"></button>
                            <hr />
                        </div>
                        <div class="modal-body">
                            <form method="post" accept-charset="utf-8" novalidate="novalidate" id="notificationForm" action="">

                                <div class="row">
                                    <div class="col-md-12">
                                        <div class="card-body">
                                            <div class="form-group">
                                                <div class="input Number">
                                                    <div class="input text required"><label for="title">Title <span class="required">*</span></label><input type="text" name="title" class="form-control" placeholder="Notification Title" required="required" maxLength="100" id="title" value={title} onChange={(e) => { settitle(e.target.value) }} /></div>
                                                </div>
                                            </div>
                                            <div class="form-group">
                                                <div class="input text">
                                                    <div class="form-group">
                                                        <label>Content <span class="required">*</span></label>
                                                        <div class="input textarea required">
                                                            <textarea name="notification" class="form-control" rows="3" required="required" maxLength="255" id="notification" value={content} onChange={(e) => { setcontent(e.target.value) }}></textarea>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="card-footer">
                                    <button type="button" data-bs-dismiss="modal" class="btn btn-primary submit" onClick={()=>{EditContent(userId,title,content)}}>Submit</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ContentList
