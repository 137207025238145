import React from 'react'
import Header from '../Header'
import Sidebar from '../Sidebar'

const AddNew = () => {
    return (
        <>

            <div id="layoutSidenav_content">
                <main>
                    <header class="page-header page-header-dark bg-gradient-primary-to-secondary pb-10">
                        <div class="container-xl px-4">
                            <div class="page-header-content pt-4">
                                <div class="row align-items-center justify-content-between">
                                    <div class="col-auto mt-4">
                                        <h1 class="page-header-title">
                                            <div class="page-header-icon"><i class="ri-bank-card-line"></i></div>
                                            Add Payment Offer
                                        </h1>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </header>
                    <div class="container-xl px-4 mt-n10">
                        <div class="card mb-4">
                            <div class="card-header">
                                <h3 class="card-title mb-0">Enter Details</h3> </div>
                            <form enctype="multipart/form-data" method="post" accept-charset="utf-8" novalidate="novalidate" id="offerForm" action="/admin/payment-offers/add">

                                <div class="card-body ">
                                    <div class="row">
                                        <div class=" col-xs-6 col-sm-6 col-md-4 form-group">
                                            <div class="input text required">
                                                <label for="coupon-code">Coupon Code <span class="required">*</span></label>
                                                <input type="text" name="coupon_code" class="form-control" placeholder="Coupon Code" maxLength="15" required="required" id="coupon-code" />
                                            </div> <small>Provide 15-Digit coupon code.</small> </div>
                                        <div class=" col-xs-6 col-sm-6 col-md-4 form-group">
                                            <div class="input text required">
                                                <label for="min-amount">Minimum Amount (INR) <span class="required">*</span></label>
                                                <input type="text" name="min_amount" class="form-control" placeholder="Minimum Amount" maxLength="10" required="required" id="min-amount" value="0" />
                                            </div> <small>Minimum amount to be available for the offer.</small> </div>
                                        <div class=" col-xs-6 col-sm-6 col-md-4 form-group">
                                            <div class="input text required">
                                                <label for="max-cashback-amount">Maximum Cashback Amount (INR) <span class="required">*</span></label>
                                                <input type="text" name="max_cashback_amount" class="form-control" placeholder="Maximum Cashback Amount" maxLength="10" required="required" id="max-cashback-amount" value="0" />
                                            </div>
                                        </div>
                                        <div class=" col-xs-6 col-sm-6 col-md-4  form-group">
                                            <div class="input text required">
                                                <label for="max-cashback-percent">Cashback Percentage (%) <span class="required">*</span></label>
                                                <input type="text" name="max_cashback_percent" class="form-control" placeholder="Cashback Percentage" maxLength="5" required="required" id="max-cashback-percent" value="0" />
                                            </div>
                                        </div>
                                        <div class=" col-xs-6 col-sm-6 col-md-4  form-group">
                                            <div class="input text required">
                                                <label for="usage-limit">Usage Limit <span class="required">*</span></label>
                                                <input type="text" name="usage_limit" class="form-control usage_limit" placeholder="Usage Limit" maxLength="10" required="required" id="usage-limit" value="Unlimited" readonly="readonly" />
                                            </div>
                                            <div class="position-relative form-check">
                                                <input name="check" id="Check_use_limit" type="checkbox" class="form-check-input" checked="" />
                                                <label for="Check_use_limit" class="form-check-label">Unlimited</label>
                                            </div>
                                        </div>
                                        <div class=" col-xs-6 col-sm-6 col-md-4  form-group">
                                            <div class="input text required">
                                                <label for="per-user-limit">Limit per User <span class="required">*</span></label>
                                                <input type="text" name="per_user_limit" class="form-control" placeholder="Limit per User" maxLength="10" required="required" id="per-user-limit" />
                                            </div>
                                        </div>
                                        <div class=" col-xs-6 col-sm-6 col-md-4 form-group">
                                            <div class="input text required">
                                                <label for="expiry-date">Expiry Date <span class="required">*</span></label>
                                                <input type="text" name="expiry_date" class="form-control expiry_date" placeholder="Expiry Date" readonly="readonly" required="required" id="expiry-date" />
                                            </div>
                                        </div>
                                        <div class=" col-xs-6 col-sm-6 col-md-4 form-group">
                                            <div class="input select required">
                                                <label for="status">Status <span class="required">*</span></label>
                                                <select name="status" class="form-control form-select" required="required" id="status">
                                                    <option value="">Select Status</option>
                                                    <option value="1" selected="selected">Active</option>
                                                    <option value="0">Inactive</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div class=" col-xs-6 col-sm-6 col-md-4 form-group">
                                            <div class="input select">
                                                <label for="wallets">Wallet <span class="required">*</span></label>
                                                <select name="wallets" class="form-control  form-select" id="wallets">
                                                    <option value="">Select Walets</option>
                                                    <option value="1">Cash</option>
                                                    <option value="0" selected="selected">Winning</option>
                                                    <option value="2">Bonus</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="card-footer">
                                    <button type="submit" class="btn btn-primary submit px-5">Submit</button>
                                </div>

                            </form>



                        </div>
                    </div>
                </main>
            </div>
        </>
    )
}

export default AddNew
