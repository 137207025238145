import React, { useEffect, useState } from "react";
import AuthService from '../../api/services/HomeService';
import { Link, useNavigate } from "react-router-dom";
import { alertErrorMessage, alertSuccessMessage } from '../../Components/CustomAlertMessage';
import LoaderHelper from "../../Components/Loading/LoaderHelper";
import { validation } from "../../utils/Validation";

const Login = () => {

    const navigate = useNavigate();
    const [email, setemail] = useState('');
    const [password, setpassword] = useState('');

    const handleLogin = async (email, password) => {
        const validate = await validation.login({ email, password })
        if (!validate) {
            return
        };
        LoaderHelper.loaderStatus(true);
        await AuthService.login(email, password).then(async result => {
            if (result.success) {
                LoaderHelper.loaderStatus(false);
                alertSuccessMessage(result.message)
                sessionStorage.setItem("refreshToken", result.data.refreshToken);
                sessionStorage.setItem("token", result.data.token);
                sessionStorage.setItem("permissions", result.data.permissions);
                sessionStorage.setItem("userType", result.data.userType);
                sessionStorage.setItem("Email", result.data.Email);
                navigate("/Dashboard");
                window.location.reload()
            } else {
                LoaderHelper.loaderStatus(false);
                alertErrorMessage(result.message);
            }
        });
    }

    useEffect(() => {
        const enterToLogin = event => {
            if (event.key === 'Enter') {
                event.preventDefault();
                handleLogin(email, password);
            }
        };
        document.addEventListener('keydown', enterToLogin);
        return () => {
            document.removeEventListener('keydown', enterToLogin);
        };
    });
    return (
        <div id="layoutAuthentication">
            <div id="layoutAuthentication_content">
                <main class="login-card" >
                    <div class="container-xl px-4">
                        <div className="d-flex justify-content-end">
                            <a class="btn btn-primary btn-block w-20" href="https://fs11admin.fantasyscore11.com/downloads">Download APK</a>
                        </div>
                        <div class="row justify-content-center">
                            <div class="col-xl-5 col-lg-6 col-md-8 col-sm-11">
                                <div class="card-body p-5 pb-4 text-center">
                                    <img src="/img/logo.png" class="img-fluid" style={{ maxWidth: '70%' }} />
                                </div>
                                <div class="card">
                                    <div class="card-body p-5">
                                        <h2 class=" fs-1 text-center"><strong>Sign in to account</strong></h2>
                                        <p class="text-center"><small>Enter your email &amp; password to login</small></p>
                                        <form class="mt-4" >
                                            <div class="mb-3">
                                                <label class="text-gray-600 small" for="emailExample">Email address</label>
                                                <input class="form-control form-control-solid" type="text" placeholder="" aria-label="Email Address" aria-describedby="emailExample" value={email} onChange={(e) => { setemail(e.target.value) }} />
                                            </div>
                                            <div class="mb-3">
                                                <label class="text-gray-600 small" for="passwordExample">Password</label>
                                                <input class="form-control form-control-solid" type="password" placeholder="" aria-label="Password" aria-describedby="passwordExample" value={password} onChange={(e) => { setpassword(e.target.value) }} />
                                            </div>
                                            <div class="d-flex align-items-center justify-content-between mb-4 mt-3">
                                                <div class="form-check  mb-0">
                                                    {/* <input class="form-check-input" id="checkRememberPassword" type="checkbox" value="" /> */}
                                                    {/* <label class="form-check-label  mb-0" for="checkRememberPassword">Remember password</label> */}
                                                </div>
                                                <Link class="small" to="/ForgotPassword">Forgot your password?</Link>
                                            </div>
                                            <button class="btn btn-primary btn-block w-100" type="button" onClick={() => handleLogin(email, password)}>  Login
                                            </button>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
            </div>
        </div>
    )
}

export default Login
