import { z } from "zod";
import { alertWarningMessage } from "../Components/CustomAlertMessage";

export const validation = {
    login: async (data) => {
        const loginSchema = z.object({
            email: z.string().email({ message: "Invalid Email address" }),
            password: z.string().regex(new RegExp('^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$'), { message: "Password must contain at least 8 characters including  one Uppercase, one lowercase, one special character and one number" }),
        });
        try {
            loginSchema.parse(data);
            return true
        } catch (error) {
            alertWarningMessage(error?.errors[0]?.message)
            return false
        }
    },

    subadmin: async (data, profilePic) => {
        const subadminSchema = z.object({
            fName: z.string().min(1, { message: "Firstname is required" }),
            lName: z.string().min(1, { message: "Lastname is required" }),
            email: z.string().email({ message: "Invalid Email address" }),
            number: z.string().min(1, { message: "Phone number is required" }),
            dob: z.string().min(1, { message: "DOB is required" }),
            permission: z.string().array({ message: "Permission is required" }).min(1, { message: "Permission is required" }),
            gender: z.string().min(1, { message: "Gender is required" }),
            password: z.string().regex(new RegExp('^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$'), { message: "Password must contain at least 8 characters including  one Uppercase, one lowercase, one special character and one number" }),
        });
        try {
            subadminSchema.parse(data);
            if (!profilePic) {
                alertWarningMessage('Image is required')
                return false
            }
            return true
        } catch (error) {
            alertWarningMessage(error?.errors[0]?.message)
            return false
        }
    }

}