import React, { useEffect, useState } from 'react';
import LoaderHelper from '../../Components/Loading/LoaderHelper';
import AuthService from '../../api/services/HomeService';
import { alertErrorMessage, alertSuccessMessage } from '../../Components/CustomAlertMessage';
import Moment from 'react-moment';
import { ApiConfig } from '../../api/apiConfig/ApiConfig';

const AadharRequest = () => {
    const [list, setlist] = useState();
    const [list2, setlist2] = useState();
    const [kycStatus, setKycStatus] = useState('1');

    useEffect(() => {
        DlStatus(kycStatus)
    }, []);

    const DlStatus = async (kycStatus) => {
        LoaderHelper.loaderStatus(true);
        await AuthService.DlStatus(kycStatus).then((result) => {
            if (result?.success) {
                LoaderHelper.loaderStatus(false);
                try {
                    setlist(result?.data)
                    setlist2(result?.data)
                } catch {
                    alertErrorMessage(result.message);
                }
            } else {
                LoaderHelper.loaderStatus(false);
                alertErrorMessage(result.message);
            }
        });
    };

    const VerifyAadhar = async (id, status, userid) => {
        await AuthService.VerifyAadhar(id, status, userid).then((result) => {
            if (result?.success) {
                try {
                    alertSuccessMessage("DL Status Updated Successfully!!");
                    DlStatus(kycStatus)
                } catch {
                    alertErrorMessage(result.message);
                }
            } else {
                // alertErrorMessage(result.message);
            }
        });
    };

    const delete_Dl = async (id, status, userid) => {
        await AuthService.delete_Dl(id, status, userid).then((result) => {
            if (result?.success) {
                try {
                    alertSuccessMessage(result?.message);
                    DlStatus(kycStatus)
                } catch {
                    alertErrorMessage(result.message);
                }
            } else {
                alertErrorMessage(result.message);
            }
        });
    };

    const filter = (e) => {
        let filterd;
        if (e.target.checked) {
            filterd = list2?.filter((data) => {
                return data?.adhar_verified == 3
            })
            setlist(filterd)
        } else {
            setlist(list2)
        }
    };

    return (
        <>

            <div id="layoutSidenav_content">
                <main>
                    <header class="page-header page-header-dark bg-gradient-primary-to-secondary pb-10">
                        <div class="container-xl px-4">
                            <div class="page-header-content pt-4">
                                <div class="row align-items-center justify-content-between">
                                    <div class="col-auto mt-4">
                                        <h1 class="page-header-title">
                                            <div class="page-header-icon"><i class="ri-group-line"></i></div>
                                            Driving Licence Request
                                        </h1>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </header>
                    <div class="container-xl px-4 mt-n10">

                        <div class="card mb-4">

                            <div class="card-body">
                                <div class=" filter_bar mb-4 mb-md-5">
                                    <form method="get" accept-charset="utf-8" novalidate="novalidate" class="form-inline search_form" action="/admin/users/referraldata">
                                        <div class="row gx-2 align-items-center">

                                            <div class="form-group mb-0 col-sm-6 col-md-3">
                                                <div class=" form-check ms-2  " >
                                                    <input class="form-check-input" type="checkbox" id="unverified" onChange={filter} />
                                                    <label class="form-check-label" for="unverified">
                                                        Show Unverified Users
                                                    </label>
                                                </div>
                                            </div>

                                        </div>
                                    </form>
                                    <select onChange={(e) => {
                                        setKycStatus(e.target.value);
                                        DlStatus(e.target.value);
                                    }} className='form-control'>
                                        <option value="1">Approved Request</option>
                                        <option value="2">Pending Request</option>
                                    </select>
                                </div>


                                <table id="datatablesSimple">

                                    <thead>
                                        <tr>
                                            <th>#</th>
                                            <th>Name</th>
                                            <th>Phone Number</th>
                                            <th>DL Number</th>
                                            <th>DL verified</th>
                                            <th>DL Name</th>
                                            <th>Status</th>
                                        </tr>
                                    </thead>
                                    <tfoot>
                                        <tr>
                                            <th>#</th>
                                            <th>Name</th>
                                            <th>Phone Number</th>
                                            <th>DL Number</th>
                                            <th>DL verified</th>
                                            <th>DL Name</th>
                                            <th>Status</th>
                                        </tr>
                                    </tfoot>
                                    <tbody>
                                        {list ? list?.map((data, index) => {
                                            return (
                                                <tr>
                                                    <td>{index + 1}</td>
                                                    <td>{data?.data?.full_name}</td>
                                                    <td>{data?.data?.mobile_number}</td>
                                                    <td>{data?.dl_number}</td>
                                                    <td>{data?.dl_verified}</td>
                                                    <td>{data?.user_full_name}</td>




                                                    {/* <td><a href={'http://13.127.44.238:4002/' + data?.adhar_front_image} target='_blank' rel="noreferrer"> <img alt='frontImage' src={'http://13.127.44.238:4002/' + data?.adhar_front_image} height='30' /></a></td>

                                                    <td><a href={'http://13.127.44.238:4002/' + data?.adhar_front_image} target='_blank' rel="noreferrer"> <img alt='backImage' src={'http://13.127.44.238:4002/' + data?.adhar_back_image} height='30' /></a></td> */}

                                                    <td>
                                                        <>
                                                            <button type="btn" class="btn badge bg-success text-white rounded-pill"
                                                                onClick={() => { VerifyAadhar(data?._id, '1', data?.user_id) }}>Approve</button>

                                                            <button type="button" class="mt-2 mx-2   btn badge bg-danger text-white rounded-pill"
                                                                onClick={() => delete_Dl(data?._id, '0', data?.user_id)}> Delete</button>
                                                        </>
                                                    </td>
                                                </tr>
                                            )
                                        }) : ''}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>

                </main>
            </div>

        </>
    )
}

export default AadharRequest
